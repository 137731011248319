import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components';
import { appConfig } from '@/providers/config';

import styles from './GetStarted.module.scss';

export function GetStarted(): ReactElement {
  return (
    <div className={styles.wrapper}>
      <h1>{appConfig.name}: All-in-One AI Chatbot</h1>
      <div className={styles['wrapper-description']}>
        <p>
          {appConfig.name} offers an intuitive user interface for accessing
          large language models, including GPT-4o and Mistral 7B, all at an
          affordable price with a single membership.
        </p>
        <p>
          To start using {appConfig.name} on the web, mobile, and desktop,
          simply click or tap the &apos;Get Started&apos; button.
        </p>
      </div>
      <div className={styles['button-container']}>
        <Button asChild variant={'primary'} size={'large'}>
          <Link to="/">Get Started</Link>
        </Button>
      </div>
    </div>
  );
}
