import { type ReactElement, type ReactNode } from 'react';

import styles from './LandingLayout.module.scss';

export function LandingWhiteLayout({
  leftChild,
  rightChild,
  display,
}: {
  leftChild: ReactNode;
  rightChild: ReactNode;
  display?: string;
}): ReactElement {
  return (
    <div className={styles.layout}>
      <div
        className={
          display === 'block' ? styles.left__block : styles.left__section
        }
      >
        {leftChild}
      </div>
      <div className={styles.right__section}>{rightChild}</div>
    </div>
  );
}
