import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { setLanguage } from '@/features/settings';
import { en } from '@/locales/en';

import { store } from './store';

export const resources = {
  en,
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function initI18next() {
  const language = store.getState().settings.language;

  return await i18n.use(initReactI18next).init({
    resources,
    defaultNS: false,
    lng: language ?? 'en',
    interpolation: {
      escapeValue: false,
    },
  });
}

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);

  store.dispatch(setLanguage(lng));
});
