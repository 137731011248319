import {
  type CustomEventName,
  type EventNameString,
  logEvent as logFirebaseEvent,
} from 'firebase/analytics';

import { appConfig } from '@/providers/config';
import { analytics } from '@/providers/firebase';

import type { EventName } from '../types/events';

export function logEvent(
  eventName: CustomEventName<EventName> | EventNameString,
  params?: Record<string, unknown>,
): void {
  if (appConfig.firebaseEventEnabled) {
    logFirebaseEvent<string>(analytics, eventName, params);
  }
}
