import type { ReactElement } from 'react';

import Check from '@/assets/check-modal-icon.svg?react';
import {
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogDescription,
  AlertDialogTitle,
  Button,
} from '@/components';

export function AccountDeleted({
  onClick,
}: {
  onClick?: () => void;
}): ReactElement {
  return (
    <>
      <AlertDialogTitle>
        <Check /> Deleted Account
      </AlertDialogTitle>

      <AlertDialogDescription>
        Your account has been deleted.
      </AlertDialogDescription>

      <AlertDialogActions>
        <AlertDialogAction asChild>
          <Button
            onClick={onClick}
            size="large"
            type="submit"
            variant="secondary"
          >
            Ok
          </Button>
        </AlertDialogAction>
      </AlertDialogActions>
    </>
  );
}
