import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import {
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogTitle,
  Button,
} from '@/components';
import { logEvent } from '@/features/analytics';

export function CancelSubscription({
  onSubmit,
}: {
  onSubmit?: () => void;
}): ReactElement {
  return (
    <>
      <AlertDialogTitle>Cancel Subscription</AlertDialogTitle>

      <AlertDialogDescription>
        You have an active subscription. Please cancel your subscription before
        deleting your account. You can cancel on the My Plan page.
      </AlertDialogDescription>

      <AlertDialogActions>
        <AlertDialogCancel asChild>
          <Button
            size="large"
            type="button"
            variant="secondary"
            onClick={() => {
              logEvent('btn_delete_cancel');
            }}
          >
            Cancel
          </Button>
        </AlertDialogCancel>

        <AlertDialogAction asChild>
          <Link to={'/account/plan'}>
            <Button
              size="large"
              type="submit"
              variant="primary"
              onClick={() => {
                logEvent('btn_delete_myplan');
              }}
            >
              My Plan
            </Button>
          </Link>
        </AlertDialogAction>
      </AlertDialogActions>
    </>
  );
}
