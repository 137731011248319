import { zodResolver } from '@hookform/resolvers/zod';
import { type ReactElement, useEffect } from 'react';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  Button,
  Form,
  InlineLink,
  PasswordField,
  TextField,
} from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { appConfig } from '@/providers/config';
import { auth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { AuthFormSeparator } from '../../components/AuthFormSeparator/AuthFormSeparator';
import { FormTitle } from '../../components/FormTitle/FormTitle';
import { SocialLogin } from '../../components/SocialLogin/SocialLogin';
import { createFormError } from '../../utils/create-form-error';
import styles from './LoginForm.module.scss';

interface LoginFormType {
  email: string;
  password: string;
}

// TODO: Refactor this to EmailLogin.
export function LoginForm(): ReactElement {
  const [signInWithEmailAndPassword, , loginLoading, loginError] =
    useSignInWithEmailAndPassword(auth);
  const { logBigQueryEvent } = useLogBigQueryEvent();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(0, "Email can't be empty.")
          .email('Email is invalid'),
        password: z
          .string()
          .min(0, "Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      }),
    ),
  });

  const handleLogin: SubmitHandler<LoginFormType> = async ({
    email,
    password,
  }) => {
    logBigQueryEvent('btn_login_continue_email');
    logEvent('btn_login_continue_email');

    await signInWithEmailAndPassword(email, password);

    // Force sign-out if email is not verified.
    if (auth.currentUser?.emailVerified === false) {
      await auth.signOut();

      setError('email', {
        type: 'custom',
        message: 'Please verify your email address.',
      });
    }
  };

  useEffect(() => {
    if (loginError === undefined) {
      return;
    }

    const error = createFormError(loginError.code);

    if (error === null) {
      toast.error(loginError.message);
      return;
    }

    if (error.target === 'email' || error.target === 'password') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [loginError, setError]);

  return (
    <section aria-label="Login form" className={styles['login-form']}>
      <FormTitle>
        Log in to <b>{appConfig.name}</b>
      </FormTitle>

      <Form
        className={styles.form}
        onSubmit={handlePromiseEvent(handleSubmit(handleLogin))}
      >
        <TextField
          type="email"
          placeholder="Email"
          errorMessage={errors.email?.message}
          {...register('email')}
        />

        <PasswordField
          placeholder="Password"
          errorMessage={errors.password?.message}
          {...register('password')}
        />

        <div className={styles['forgot-password']}>
          <InlineLink href="/forgot-password">Forgot Password</InlineLink>
        </div>

        <Button
          size="large"
          variant="primary"
          loading={loginLoading}
          type="submit"
        >
          Continue
        </Button>
      </Form>

      <AuthFormSeparator />

      <SocialLogin source="login" />

      <div className={styles['sign-up-info']}>
        <span>Don&apos;t have an account?</span>{' '}
        <InlineLink href="/register">Sign up</InlineLink>
      </div>
    </section>
  );
}
