import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type RootState } from '@/providers/store';

interface SettingsStore {
  language: string;
}

const initialState: SettingsStore = {
  language: 'en',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguage(
      state,
      { payload: language }: PayloadAction<SettingsStore['language']>,
    ) {
      state.language = language;
    },
  },
});

export const { setLanguage } = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;

export const selectSettings = (state: RootState): SettingsStore =>
  state.settings;
