import type { ReactElement } from 'react';

import AppleProviderLogo from '@/assets/provider-apple.svg?react';
import GoogleProviderLogo from '@/assets/provider-google.svg?react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Form,
  Text,
} from '@/components';
import type { SocialProvider, SocialProviderId } from '@/features/auth';

import styles from './LoginProvider.module.scss';

export function LoginProvider({
  id,
  provider,
  onClickDisconnect,
  onClickConnect,
}: {
  id: SocialProviderId;
  provider: SocialProvider | null;
  onClickDisconnect: () => void;
  onClickConnect: () => void;
}): ReactElement {
  const providerData: Record<
    SocialProviderId,
    { name: string; logo: SvgComponent }
  > = {
    'apple.com': {
      logo: AppleProviderLogo,
      name: 'Apple',
    },
    'google.com': {
      logo: GoogleProviderLogo,
      name: 'Google',
    },
  };

  const { logo: LogoComponent, name } = providerData[id];

  return (
    <div className={styles['login-provider']}>
      <LogoComponent className={styles['login-provider__logo']} />

      <div className={styles['login-provider__content']}>
        <Text
          as="p"
          weight="semi-bold"
          size={{
            initial: '1',
            md: '2',
          }}
          className={styles['login-provider__name']}
        >
          {name}
        </Text>

        <Text
          as="p"
          size={{ initial: '0', md: '1' }}
          className={styles['login-provider__text']}
        >
          {provider?.email ?? `Connect with ${name}`}
        </Text>
      </div>

      {provider === null && (
        <Button
          variant="secondary"
          size="small"
          onClick={onClickConnect}
          className={styles['login-provider__action-button']}
        >
          Connect
        </Button>
      )}

      {provider !== null && (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              variant="destructive-outline"
              size="small"
              className={styles['login-provider__action-button']}
            >
              Disconnect
            </Button>
          </AlertDialogTrigger>

          <AlertDialogContent>
            <Form onSubmit={onClickDisconnect}>
              <AlertDialogTitle>
                Are you sure to disconnect this provider?
              </AlertDialogTitle>

              <AlertDialogDescription>
                Are you sure you want to disconnect {name} provider?
              </AlertDialogDescription>

              <AlertDialogActions>
                <AlertDialogCancel asChild>
                  <Button size="large" type="button" variant="secondary">
                    Cancel
                  </Button>
                </AlertDialogCancel>

                <AlertDialogAction asChild>
                  <Button size="large" type="submit" variant="destructive">
                    Yes, disconnect
                  </Button>
                </AlertDialogAction>
              </AlertDialogActions>
            </Form>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  );
}
