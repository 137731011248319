import { deleteObject, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { store } from '@/providers/store';

import { useSelectedModel } from '../contexts/selected-model';
import {
  DocumentType,
  type MessageDocumentDraftType,
  type MessageDocumentType,
  documentsModel,
} from '../types/message';
import { models } from '../types/models';

export type FileUploadState = 'uploading' | 'success' | 'error' | null;

export type SelectedFileType =
  | MessageDocumentType
  | MessageDocumentDraftType
  | null;

export type SelectedFilesType = Array<
  MessageDocumentType | MessageDocumentDraftType | null
>;

export function useFileUpload(): {
  fileUploadState: FileUploadState;
  selectedFile: SelectedFileType | null;
  selectedFiles: SelectedFilesType;

  uploadFile: (file: File) => Promise<void>;
  removeFile: (fileName?: string) => void;
  resetSelectedFile: () => void;
} {
  const [fileUploadState, setFileUploadState] = useState<FileUploadState>(null);
  const [selectedFile, setSelectedFile] = useState<SelectedFileType>(null);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFilesType>([]);

  const fileDeletedState = useRef(false);
  const { selectedModel } = useSelectedModel();

  const getDocumentTypeFromMimeType = (mimeType: string): DocumentType => {
    const match = documentsModel.find((model) => model.mimeType === mimeType);
    return match != null ? match.type : DocumentType.Pdf;
  };

  const uploadFile = async (file: File): Promise<void> => {
    const { auth } = store.getState();
    const user = auth.user;

    let mimeType = file.type;
    let fileType = getDocumentTypeFromMimeType(mimeType);

    if (file.name.endsWith('.xlsx')) {
      fileType = DocumentType.Xlsx;
      mimeType = 'xlsx/csv';
    }

    const preview =
      (mimeType.includes('image/') || mimeType.includes('video/')) &&
      documentsModel.some(
        (model) => model.mimeType === mimeType && model.preview !== false,
      )
        ? window.URL.createObjectURL(file)
        : undefined;

    if (user === null) {
      return;
    }
    if (
      (models[selectedModel].modelRedirectSupport ?? false) &&
      selectedFiles.length > 0
    ) {
      toast.error('Maximum of 1 documents can be added.');
      return;
    } else if (selectedFiles.length > 1) {
      toast.error('Maximum of 2 documents can be added.');
      return;
    }

    fileDeletedState.current = false;

    const newFile = {
      name: file.name,
      size: file.size,
      type: fileType,
      mimeType,
      ...(preview !== undefined && { preview }),
      fileUploadState: 'uploading',
    };

    setSelectedFiles((prevFiles) => [...prevFiles, newFile]);

    if (!documentsModel.some((model) => model.mimeType === mimeType)) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file !== null && file.name === newFile.name
            ? { ...file, fileUploadState: 'error' }
            : file,
        ),
      );
      return;
    }

    setSelectedFile(newFile);
    setFileUploadState('uploading');
    const redirectModel = models[selectedModel].modelRedirectSupport;
    const updateModel =
      redirectModel ?? false
        ? mimeType.includes('image')
          ? 'gpt4o'
          : 'superbot'
        : selectedModel;

    const userId = user.uid;
    const currentTimeInMilliseconds = Date.now();
    const fileId = uuidv4();
    const filePath =
      selectedModel === 'document'
        ? `document/${userId}/${currentTimeInMilliseconds}/document-input-${fileId}.pdf`
        : `${updateModel}/${userId}/${currentTimeInMilliseconds}/${file.name}`;

    const storage = getStorage();
    const storageRef = ref(storage, filePath);

    try {
      const result = await uploadBytes(storageRef, file);
      const fileUrl = result.metadata.fullPath;

      if (fileDeletedState.current) {
        void deleteFileFromStorage(fileUrl);
        return;
      }

      const uploadedFile = {
        ...newFile,
        url: fileUrl,
        fileUploadState: 'success',
      };

      if (selectedModel === 'document') {
        setSelectedFile(uploadedFile);
      } else {
        setSelectedFile(null);
        setSelectedFiles((prevFiles) =>
          prevFiles.map((file) =>
            file !== null && file.name === newFile.name ? uploadedFile : file,
          ),
        );
        if (selectedFiles.length > 2) {
          toast.error('Maximum of 2 documents can be added.');
        }
      }

      setFileUploadState('success');
    } catch (error) {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file !== null && file.name === newFile.name
            ? { ...file, fileUploadState: 'error' }
            : file,
        ),
      );

      setFileUploadState('error');

      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const deleteFileFromStorage = async (url: string): Promise<void> => {
    const storage = getStorage();
    const storageRef = ref(storage, url);

    try {
      await deleteObject(storageRef);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const removeFile = (fileName?: string): void => {
    if (selectedFile !== null && 'url' in selectedFile) {
      void deleteFileFromStorage(selectedFile.url);
    }

    if (
      fileName !== undefined &&
      models[selectedModel].filesUploadSupport === true
    ) {
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((file) => {
          if (file !== null && typeof file === 'object') {
            return file.name !== fileName;
          }
          return true;
        }),
      );
    } else {
      setSelectedFiles([]);
    }
    setSelectedFile(null);

    fileDeletedState.current = true;
  };

  const resetSelectedFile = (): void => {
    setFileUploadState(null);
    setSelectedFile(null);
    setSelectedFiles([]);
    fileDeletedState.current = false;
  };

  return {
    fileUploadState,
    uploadFile,
    removeFile,
    selectedFile,
    selectedFiles,
    resetSelectedFile,
  };
}
