import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import {
  type ChangeEventHandler,
  type ComponentProps,
  type FocusEventHandler,
  type ForwardedRef,
  type KeyboardEventHandler,
  type ReactElement,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import { mergeRefs } from 'react-merge-refs';

import { Button, Icon } from '@/components';

import { DocumentItem } from '../../../components/DocumentList/DocumentItem/DocumentItem';
import { useSelectedModel } from '../../../contexts/selected-model';
import type {
  FileUploadState,
  SelectedFileType,
  SelectedFilesType,
} from '../../../services/file-upload';
import { models } from '../../../types/models';
import { AttachButton } from './AttachButton/AttachButton';
import { DictationButton } from './DictationButton/DictationButton';
import styles from './MessageField.module.scss';

type DictationButtonProps = ComponentProps<typeof DictationButton>;

function MessageFieldComponent(
  {
    name,
    onChange,
    onBlur,
    value,
    loading = false,
    disabled,
    fileUploadDisabled = false,
    onReceiveTranscript,
    onListeningEnd,
    onListeningStart,
    selectedFile,
    selectedFiles,
    onSelectFile,
    onClickRemoveSelectedDocument,
    fileUploadState,
    canAddDocumentFile,
    onRequestPaywall,
  }: {
    name?: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    value?: string;
    loading: boolean;
    disabled?: boolean;
    fileUploadDisabled?: boolean;
    onListeningStart: DictationButtonProps['onListeningStart'];
    onListeningEnd: DictationButtonProps['onListeningEnd'];
    onReceiveTranscript: DictationButtonProps['onReceiveTranscript'];
    selectedFile: SelectedFileType;
    selectedFiles?: SelectedFilesType;
    onSelectFile: (file: File) => void;
    onClickRemoveSelectedDocument: (name?: string) => void;
    fileUploadState: FileUploadState;
    canAddDocumentFile: boolean;
    onRequestPaywall: () => void;
  },
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
): ReactElement {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const { selectedModel } = useSelectedModel();

  const calculateInputHeight = (): void => {
    const inputElement = inputRef.current;

    if (inputElement === null) {
      return;
    }

    const computedInputStyle = window.getComputedStyle(inputElement);
    const maxHeightString = computedInputStyle.getPropertyValue('max-height'); // Value comes in as "200px".
    const maxHeight = parseInt(maxHeightString); // Parse "200px" (string) to 200 (number).

    inputElement.setAttribute('style', `height: auto; overflow-y: hidden;`);
    inputElement.setAttribute(
      'style',
      `height: ${inputElement.scrollHeight}px; ${
        inputElement.scrollHeight > maxHeight
          ? `overflow-y: auto;`
          : 'overflow-y: hidden;'
      }`,
    );
  };

  // Trigger input height calculation on resize.
  useEffect(() => {
    window.addEventListener('resize', calculateInputHeight);

    return () => {
      window.removeEventListener('resize', calculateInputHeight);
    };
  }, []);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    onChange?.(event);
    calculateInputHeight();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !isMobile) {
      event.preventDefault();

      // Submit form on enter.
      // Triggers onClick to calculate input height.
      buttonRef.current?.click();
    }
  };

  const handleSubmit = (): void => {
    setTimeout(() => {
      calculateInputHeight();
    }, 0);
  };

  return (
    <div className={styles['message-field-wrapper']}>
      {selectedFiles !== undefined && selectedFiles.length > 0 && (
        <div className={styles['message-documents']}>
          {models[selectedModel].filesUploadSupport === true
            ? selectedFiles.map((item: SelectedFileType, index: number) => {
                if (item !== null) {
                  return (
                    <DocumentItem
                      key={index}
                      name={item.name}
                      mimeType={item.mimeType}
                      size={item.size}
                      type={item.type}
                      preview={item.preview}
                      onClickRemove={() => {
                        onClickRemoveSelectedDocument(item?.name);
                      }}
                      loading={item.fileUploadState === 'uploading'}
                      error={item.fileUploadState === 'error'}
                    />
                  );
                }
                return <></>;
              })
            : selectedFile !== null && (
                <DocumentItem
                  name={selectedFile.name}
                  type={selectedFile.type}
                  size={selectedFile.size}
                  onClickRemove={() => {
                    onClickRemoveSelectedDocument();
                  }}
                  loading={fileUploadState === 'uploading'}
                  error={fileUploadState === 'error'}
                />
              )}
        </div>
      )}

      <div className={styles['message-field']}>
        <div className={styles['message-field__actions']}>
          {(models[selectedModel].fileUploadSupport === true ||
            models[selectedModel].filesUploadSupport === true) && (
            <AttachButton
              multipleFiles={models[selectedModel].filesUploadSupport === true}
              onSelectFile={onSelectFile}
              canAddDocumentFile={canAddDocumentFile}
              onRequestPaywall={onRequestPaywall}
              fileUploadDisabled={fileUploadDisabled}
            />
          )}

          <DictationButton
            onListeningEnd={onListeningEnd}
            onListeningStart={onListeningStart}
            onReceiveTranscript={onReceiveTranscript}
          />
        </div>

        <textarea
          id="message-field-input"
          ref={mergeRefs([forwardedRef, inputRef])}
          name={name}
          rows={1}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onInput={calculateInputHeight}
          onKeyDown={handleKeyDown}
          className={cn(styles['message-field__input'], {
            [styles['message-field__input--with-attach-button']]:
              models[selectedModel]?.fileUploadSupport === true ||
              models[selectedModel]?.filesUploadSupport === true,
          })}
          placeholder="Send a message"
        />

        <div className={styles['message-field__outline']}></div>

        <Button
          size="small"
          ref={buttonRef}
          variant="primary"
          loading={loading}
          disabled={disabled}
          type="submit"
          onClick={handleSubmit}
          className={styles['message-field__send-button']}
        >
          <Icon icon={faPaperPlaneTop} />
        </Button>
      </div>
    </div>
  );
}

export const MessageField = forwardRef(MessageFieldComponent);
