import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import * as Dialog from '@radix-ui/react-dialog';
import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon, Logo } from '@/components';
import { AdvancedTools } from '@/features/chat';
import { Conversations } from '@/features/conversations';
import { type DrawerMode, useDrawerDialog } from '@/features/layouts';

import { HeaderButton } from '../../Header/Header';
import styles from './ChatDrawerContent.module.scss';

export function ChatDrawerContent({
  mode,
}: {
  mode: DrawerMode;
}): ReactElement {
  const drawer = useDrawerDialog();

  const handleClickNewChat = (): void => {
    drawer?.closeWithoutAutoFocus();

    // Run at the end of the current event loop iteration.
    setTimeout(() => {
      const messageFieldInput = document.querySelector('#message-field-input');

      if (messageFieldInput !== null) {
        // Focus chat input.
        (messageFieldInput as HTMLInputElement).focus();
      }
    }, 100); // Number of milliseconds to wait to render the text field.
  };

  return (
    <nav className={styles['drawer-content']}>
      {mode === 'dialog' && (
        <div className={styles['drawer-content__header']}>
          <Logo size="small" />

          <Dialog.Close asChild>
            <HeaderButton variant="filled">
              <Icon icon={faTimes} />
            </HeaderButton>
          </Dialog.Close>
        </div>
      )}

      <Button
        size="large"
        variant="white-outline"
        className={styles['new-chat']}
        onClick={handleClickNewChat}
        asChild
      >
        <Link to="/?new-chat=true">
          <Icon icon={faPlus} />
          New Chat
        </Link>
      </Button>

      <Conversations />
      <AdvancedTools />
    </nav>
  );
}
