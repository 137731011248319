import {
  ref as databaseRef,
  update as databaseUpdate,
  serverTimestamp,
} from 'firebase/database';

import { database } from '@/providers/firebase';
import { store } from '@/providers/store';

import type { ConversationModel } from '../types/conversation';

export async function updateConversationTitle({
  title,
  conversationId,
}: {
  title: string;
  conversationId: string;
}): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  const conversationPath = `${user.uid}/chats/${conversationId}`;

  const timestamp = serverTimestamp();

  await databaseUpdate(databaseRef(database), {
    [`${conversationPath}/title`]: title,
    [`${conversationPath}/updatedAt`]: timestamp,
  });
}

export async function updateConversationModel({
  model,
  conversationId,
}: {
  model: ConversationModel;
  conversationId: string;
}): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  const conversationPath = `${user.uid}/chats/${conversationId}`;

  await databaseUpdate(databaseRef(database), {
    [`${conversationPath}/engine`]: model,
  });
}

export async function updateConversationHistoryId({
  historyId,
  conversationId,
}: {
  historyId: string;
  conversationId: string;
}): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  const conversationPath = `${user.uid}/chats/${conversationId}`;

  await databaseUpdate(databaseRef(database), {
    [`${conversationPath}/captionHistoryId`]: historyId,
  });
}
