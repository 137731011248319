import { type ReactElement } from 'react';

import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionHeader,
  AccountSectionSpacer,
  AccountSectionTitle,
  usePremium,
} from '@/features/account';

import { BillingHistory } from './BillingHistory/BillingHistory';
import { PaymentMethod } from './PaymentMethod/PaymentMethod';

export function BillingDetails(): ReactElement {
  const { data: isPremium } = usePremium();
  return (
    <>
      <AccountSectionTitle>Billing Details</AccountSectionTitle>
      <AccountSectionDescription>
        Payment and billing information
      </AccountSectionDescription>
      <AccountSectionSpacer />
      {isPremium === true && (
        <AccountSection>
          <AccountSectionHeader>
            <AccountSectionTitle>Payment Method</AccountSectionTitle>
          </AccountSectionHeader>

          <AccountSectionContent>
            <PaymentMethod />
          </AccountSectionContent>
        </AccountSection>
      )}
      <AccountSectionSpacer />
      <AccountSection>
        <AccountSectionHeader>
          <AccountSectionTitle>Billing History</AccountSectionTitle>
        </AccountSectionHeader>

        <AccountSectionContent>
          <BillingHistory />
        </AccountSectionContent>
      </AccountSection>
    </>
  );
}
