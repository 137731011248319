import { z } from 'zod';

import { BillingCycleSchema } from './billing-cycle';
import { CurrencyCodeSchema } from './currency-code';

export const ApiPaymentProductSchema = z.object({
  id: z.string(),
  productId: z.string(),
  description: z.string(),
  type: z.string(),
  name: z.string(),
  billingCycle: BillingCycleSchema,
  unitPrice: z.object({
    amount: z.string(),
    currencyCode: CurrencyCodeSchema,
  }),
  status: z.string(),
});

export type ApiPaymentProductType = z.infer<typeof ApiPaymentProductSchema>;
