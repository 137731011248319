import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faGrid2Plus } from '@fortawesome/pro-solid-svg-icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@/components';
import type {
  ConversationModel,
  UpcomingConversationModel,
} from '@/features/conversations';
import { useDrawerDialog } from '@/features/layouts';

import { NewBadge } from '../../components/NewBadge/NewBadge';
import { models } from '../../types/models';
import { contents } from '../Chat/ModelSelect/contents';
import styles from './AdvancedTools.module.scss';

const advancedToolModels: Array<
  | Extract<ConversationModel, 'image-generator' | 'document' | 'webSearch'>
  | Extract<UpcomingConversationModel, 'link-and-ask'>
> = ['image-generator', 'document', 'webSearch', 'link-and-ask'];

export function AdvancedTools(): ReactElement {
  const drawer = useDrawerDialog();

  const handleClickAdvancedToolItem = (): void => {
    drawer?.closeWithoutAutoFocus();

    // Run at the end of the current event loop iteration.
    setTimeout(() => {
      const messageFieldInput = document.querySelector('#message-field-input');

      if (messageFieldInput !== null) {
        // Focus chat input.
        (messageFieldInput as HTMLInputElement).focus();
        // Clear chat input value.
        (messageFieldInput as HTMLInputElement).value = '';
      }
    }, 100); // Number of milliseconds to wait to render the text field.
  };

  return (
    <Collapsible.Root className={styles['advanced-tools']} defaultOpen>
      <Collapsible.Trigger asChild>
        <button className={styles['advanced-tools-trigger']}>
          <span className={styles['advanced-tools-trigger__icon']}>
            <Icon icon={faGrid2Plus} />
          </span>

          <span className={styles['advanced-tools-trigger__text']}>
            Advanced Tools
          </span>

          <span className={styles['advanced-tools-trigger__arrow']}>
            <Icon icon={faChevronUp} />
          </span>
        </button>
      </Collapsible.Trigger>

      <Collapsible.Content className={styles['advanced-tools-content']}>
        {advancedToolModels.map((model) => {
          const { title, subtitle } = contents[model];
          const { icon: IconComponent } = models[model];

          return (
            <Link
              key={model}
              to={`/?model=${model}&new-chat=true`}
              className={styles['advanced-tool-button']}
              onClick={handleClickAdvancedToolItem}
            >
              <div className={styles['advanced-tool']}>
                <IconComponent />

                <div>
                  <div className={styles['advanced-tool-button__title']}>
                    {title}
                  </div>

                  <div className={styles['advanced-tool-button__subtitle']}>
                    {subtitle}
                  </div>
                </div>
              </div>
              {(models[model].isNew ?? false) && <NewBadge />}
            </Link>
          );
        })}
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
