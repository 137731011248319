import {
  faAlipay,
  faApplePay,
  faIdeal,
} from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/pro-duotone-svg-icons';
import { type ReactElement, useMemo } from 'react';

import { Icon } from '@/components';

import GooglePay from '../../assets/payment-methods/google-pay.svg?react';
import Paypal from '../../assets/payment-methods/paypal.svg?react';
import type { PaymentDetailType } from '../../types/transaction';
import styles from './PaymentMethodSection.module.scss';

function getPaymentIcon(method: PaymentDetailType): JSX.Element {
  switch (method.type) {
    case 'card':
      return <Icon icon={faCreditCard} size="2xl" />;
    case 'apple_pay':
      return <Icon icon={faApplePay} size="2xl" />;
    case 'alipay':
      return <Icon color="#1677FF" icon={faAlipay} size="2xl" />;
    case 'google_pay':
      return <GooglePay className={styles.icon} />;
    case 'ideal':
      return <Icon color="#D50172" icon={faIdeal} size="2xl" />;
    case 'paypal':
      return <Paypal className={styles.icon} />;
    default:
      return <Icon icon={faCreditCard} size="2xl" />;
  }
}

function getPaymentReference(method: PaymentDetailType): string {
  switch (method.type) {
    case 'card':
      return `**** **** **** ${method.card?.last4} (${method.card
        ?.expiryMonth}/${method.card?.expiryYear.toString().slice(-2)})`;
    default:
      return '';
  }
}

export function PaymentMethodSection({
  method,
}: {
  method: PaymentDetailType;
}): ReactElement {
  const icon = useMemo(() => getPaymentIcon(method), [method]);
  const reference = useMemo(() => getPaymentReference(method), [method]);

  return (
    <div className={styles['payment-method']}>
      <span>{icon}</span>
      {reference}
    </div>
  );
}
