import type { ReactNode } from 'react';

import Business from '@/assets/business.png';
import Career from '@/assets/career.png';
import Education from '@/assets/education.png';

import styles from './SliderCard.module.scss';

interface CommentType {
  title: string;
  content: string;
  image: string;
}

export function SliderCard(): ReactNode {
  const comments: CommentType[] = [
    {
      image: Education,
      title: 'Education',
      content:
        "Whether you're studying for exams, conducting academic research, our AI can break down complex topics, and offer creative approaches to learning.",
    },
    {
      image: Career,

      title: 'Career',
      content:
        'Ask AI is here to assist. With intelligent career insights, resume feedback, and interview simulation capabilities, our app acts as your personal career coach.',
    },
    {
      image: Business,

      title: 'Business',
      content:
        'Our AI app empowers businesses by automating routine tasks, providing data-driven insights, and optimizing decision-making processes.',
    },
  ];
  return (
    <div className={styles['slider-container']}>
      <h2>
        In every topic <br /> AI helps you!
      </h2>
      <div className={styles.slider}>
        {comments.map((item, index) => (
          <div key={index} className={styles['slider-card']}>
            <div className={styles.stars}>
              <img src={item.image} alt="card" />
            </div>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.content}>{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
