import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement, useState } from 'react';

import PoweredByOpenAi from '@/assets/powered-by-openai.svg?react';
import { Button, Icon, InlineLink, Logo } from '@/components';
import usePathType from '@/hooks/path-type';
import { LandingLayout } from '@/layouts';

import { LandingCommentsAnimation } from '../../components/LandingComments/LandingComments';
import { LandingGpt4oLoginForm } from '../../components/LandingGpt4oLoginForm/LandingGpt4oLoginForm';
import { AppleLogin } from '../../components/SocialLogin/AppleLogin/AppleLogin';
import { GoogleLogin } from '../../components/SocialLogin/GoogleLogin/GoogleLogin';
import styles from './LandingGpt4oLogin.module.scss';

interface StepType {
  title: string;
  description?: string;
  content: ReactElement;
}

export function LandingGpt4oLogin(): ReactElement {
  const path = usePathType();
  const handleLoginWithEmail = (): void => {
    setStep(steps[1]);
  };

  const steps: StepType[] = [
    {
      title: 'Continue to your account',
      content: (
        <div className={styles['login-methods']}>
          <p className={styles['login-description']}>
            Continue with one of these:
          </p>
          <div className={styles['login-buttons']}>
            <GoogleLogin
              className={styles['button-google']}
              iconClassName={styles['button-google-icon']}
              variant={'secondary'}
              source={'login'}
            />
            <AppleLogin
              className={styles['button-apple']}
              iconClassName={styles['button-apple-icon']}
              variant={'secondary'}
              source={'login'}
            />
            <Button
              className={styles['button-email']}
              size="large"
              variant={'secondary'}
              type="button"
              onClick={handleLoginWithEmail}
            >
              <Icon className={styles['button-email-icon']} icon={faEnvelope} />
              Continue with E-mail
            </Button>
          </div>

          <div className={styles['login-info']}>
            <span>Don&apos;t have an account?</span>{' '}
            <InlineLink href={`/${path}/register`}>Sign up</InlineLink>
          </div>
        </div>
      ),
    },
    {
      title: 'Continue to your account',
      content: <LandingGpt4oLoginForm />,
    },
  ];
  const [step, setStep] = useState<StepType>(steps[0]);

  return (
    <LandingLayout
      leftChild={<LandingCommentsAnimation />}
      rightChild={
        <div className={styles.container}>
          <Logo className={styles.logo} size={'medium'} />
          <div className={styles.content}>
            <h1 className={styles.title}>{step.title}</h1>
            {step.description !== undefined && (
              <p className={styles.description}>{step.description}</p>
            )}
            {step.content}
          </div>
          <span className={styles['powered-by']}>
            <PoweredByOpenAi />
          </span>
        </div>
      }
    />
  );
}
