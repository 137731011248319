import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement, useState } from 'react';

import ArrowRight from '@/assets/chevron-right.svg?react';
import { Button, Icon, InlineLink, Logo } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import usePathType from '@/hooks/path-type';
import { LandingWhiteLayout } from '@/layouts/LandingWhiteLayout/LandingWhiteLayout';

import { LandingWhiteCommentsAnimation } from '../../components/LandingWhiteComments/LandingWhiteComments';
import { LandingWhiteSignUpForm } from '../../components/LandingWhiteSignUpForm/LandingWhiteSignUpForm';
import { AppleLogin } from '../../components/SocialLogin/AppleLogin/AppleLogin';
import { GoogleLogin } from '../../components/SocialLogin/GoogleLogin/GoogleLogin';
import styles from './LandingSignUp.module.scss';

export function LandingWhiteSignUp(): ReactElement {
  const [isEmail, setIsEmail] = useState(false);
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const path = usePathType();

  const handleLoginWithEmail = (): void => {
    setIsEmail(true);
  };

  const handleLoginButtonClick = (): void => {
    logBigQueryEvent('btn_login', {
      eventValue: {
        source: 'register_page_content',
      },
    });

    logEvent('btn_login', {
      source: 'register_page_content',
    });
  };

  return (
    <LandingWhiteLayout
      leftChild={<LandingWhiteCommentsAnimation />}
      rightChild={
        <div className={styles.container}>
          <Logo className={styles.logo} size={'large'} />
          <div className={styles.content}>
            <h1 className={styles.title}>
              Join Millions of
              <br /> Happy Users
            </h1>
            <div className={styles['login-methods']}>
              <p className={styles['login-description']}>
                Create an account to start using it instantly{' '}
              </p>
              <div className={styles['login-buttons']}>
                <GoogleLogin
                  className={styles['button-google']}
                  iconClassName={styles['button-google-icon']}
                  variant={'secondary'}
                  source={'login'}
                />
                <AppleLogin
                  className={styles['button-apple']}
                  iconClassName={styles['button-apple-icon']}
                  variant={'secondary'}
                  source={'login'}
                />

                <div className={styles.line}>
                  <hr className={styles.left__hr} />
                  <span className={styles.or__text}>OR</span>
                  <hr className={styles.right__hr} />
                </div>

                {isEmail ? (
                  <LandingWhiteSignUpForm />
                ) : (
                  <Button
                    className={styles['button-email']}
                    size="large"
                    variant={'secondary'}
                    type="button"
                    onClick={handleLoginWithEmail}
                  >
                    <Icon
                      className={styles['button-email-icon']}
                      icon={faEnvelope}
                    />
                    E-mail
                    <ArrowRight className={styles.arrow} />
                  </Button>
                )}
              </div>
              <div className={styles['login-info']}>
                <span>Already you have an account?</span>{' '}
                <InlineLink
                  href={`/${path}/login`}
                  onClick={handleLoginButtonClick}
                >
                  Log in
                </InlineLink>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
