import { faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';
import type { ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './ImageItem.module.scss';

export function ImageItem({
  loading = false,
  error = false,
  preview,
  photoUrl,
  mimeType,
  onClickRemove,
}: {
  loading: boolean;
  error: boolean;
  preview?: string;
  photoUrl?: string;
  mimeType?: string;
  onClickRemove?: () => void;
}): ReactElement {
  return (
    <div className={styles['image-item']}>
      {loading && (
        <div className={`${loading ? styles['image-item__overlay'] : ''}`}>
          <div className={styles['image-item__icon']}>
            <Icon icon={faSpinnerThird} fixedWidth spin={loading} />
          </div>
        </div>
      )}
      <div className={styles['image-item__photo']}>
        {mimeType?.includes('video') ?? false ? (
          <video
            controls={preview === undefined && true}
            src={preview ?? photoUrl}
            className={
              styles[
                `document-image-${preview !== undefined ? 'preview' : 'url'}`
              ]
            }
          >
            <track kind="captions" />
          </video>
        ) : (
          <img
            src={preview ?? photoUrl}
            alt="Document"
            className={
              styles[
                `document-image-${preview !== undefined ? 'preview' : 'url'}`
              ]
            }
          />
        )}

        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      </div>
      {onClickRemove !== undefined && (
        <button className={styles['image-item__remove-button']} type="button">
          <Icon icon={faTimes} fixedWidth onClick={onClickRemove} />
        </button>
      )}
    </div>
  );
}
