import type { ReactElement } from 'react';

import { AccountNavItem } from './AccountNavItem/AccountNavItem';
import styles from './AccountNavItems.module.scss';

export function AccountNavItems(): ReactElement {
  return (
    <div className={styles['account-nav-items']}>
      <AccountNavItem to="/account/settings" title="Account Settings" />
      <AccountNavItem
        to="/account/login-connections"
        title="Login Connections"
      />
      <AccountNavItem to="/account/plan" title="My Plan" />
    </div>
  );
}
