import type { CheckoutOpenOptions } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePaddle } from '../contexts/paddle';

export function useOpenCheckout(options?: CheckoutOpenOptions): {
  loading: boolean;
} {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const { paddleInstance } = usePaddle();
  const transaction = searchParams.get('transaction');

  useEffect(() => {
    if (transaction === null || paddleInstance === null) {
      return;
    }
    setLoading(false);

    const defaultOptions: CheckoutOpenOptions = {
      settings: {
        displayMode: 'inline',
        theme: 'light',
        locale: 'en',
        frameTarget: 'checkout-container',
        frameInitialHeight: 450,
        allowLogout: false,
        frameStyle:
          'width: 100%; min-width: 312px; background-color: transparent; border: none;',
      },
      transactionId: transaction,
    };

    const mergedOptions = Object.assign(defaultOptions, options);

    paddleInstance.Checkout.open(mergedOptions);
  }, [transaction, paddleInstance, options]);

  return {
    loading,
  };
}
