import {
  type UserCredential,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { type ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { auth as firebaseAuth } from '@/providers/firebase';

import styles from './PasswordlessLogin.module.scss';
import { RequestEmail } from './RequestEmail/RequestEmail';
import { ResendLink } from './ResendLink/ResendLink';

type AuthError =
  | 'auth/expired-action-code'
  | 'auth/invalid-email'
  | 'auth/invalid-action-code';

interface LoginError {
  code: AuthError;
}

export function PasswordlessLogin(): ReactNode {
  const [loginError, setLoginError] = useState<AuthError>();

  const email = window.localStorage.getItem('emailForSignIn');

  const [emailForSignIn, setEmailForSignIn] = useState<string | null>(email);

  const handleSignIn = async (
    email: string,
  ): Promise<UserCredential | undefined> => {
    return await signInWithEmailLink(firebaseAuth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('emailForSignIn');
        return result;
      })
      .catch((error: LoginError) => {
        // eslint-disable-next-line no-console
        console.error('error:', error.code);
        setLoginError(error.code);
        return undefined;
      });
  };

  if (
    loginError === 'auth/expired-action-code' ||
    loginError === 'auth/invalid-action-code'
  ) {
    return <ResendLink email={emailForSignIn} />;
  }

  if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    if (email === null) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again.
      return (
        <>
          {loginError === 'auth/invalid-email' && (
            <p className={styles.error}>
              Please enter your registered email address.
            </p>
          )}
          <RequestEmail
            onEmailSent={async (email) => {
              setEmailForSignIn(email);
              await handleSignIn(email);
            }}
          />
        </>
      );
    } else {
      void handleSignIn(email);
      return null;
    }
  }

  return (
    <>
      <div className={styles.error}>Invalid verification link.</div>
      <Link className={styles.back} to="/login">
        Back to Login
      </Link>
    </>
  );
}
