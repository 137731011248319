import * as Tabs from '@radix-ui/react-tabs';
import cn from 'clsx';
import { type ComponentProps, type ReactElement } from 'react';

import { Heading, Text } from '@/components';
import { usePremium } from '@/features/account';
import type { ConversationModel } from '@/features/conversations';

import { ModelBadge } from '../../../components/ModelBadge/ModelBadge';
import { useSelectedModel } from '../../../contexts/selected-model';
import { models } from '../../../types/models';
import { ChatExamples } from './ChatExamples/ChatExamples';
import { ChooseFile } from './ChooseFile/ChooseFile';
import styles from './ModelSelect.module.scss';
import { ModelSelectItem } from './ModelSelectItem/ModelSelectItem';
import { contents } from './contents';

export function ModelSelect({
  onClickExample,
  onChangeModel,
  onSelectFile,
  onRequestPaywall,
}: {
  onClickExample: ComponentProps<typeof ChatExamples>['onClickExample'];
  onChangeModel: () => void;
  onSelectFile: (file: File) => void;
  onRequestPaywall: () => void;
}): ReactElement {
  const { selectedModel } = useSelectedModel();
  const { data: isUserPremium } = usePremium();

  const isStandaloneModel = models[selectedModel].standalone;

  const getBadgeType = ({
    upcoming,
    premium,
  }: {
    upcoming?: boolean;
    premium: boolean;
  }): 'upcoming' | 'pro' | null => {
    if (isStandaloneModel) {
      return null;
    }

    if (upcoming === true) {
      return 'upcoming';
    }

    if (premium) {
      return 'pro';
    }

    return null;
  };

  return (
    <Tabs.Root value={selectedModel} asChild>
      <section className={styles['model-select']}>
        <Tabs.List asChild>
          <fieldset
            className={cn(styles['model-select-list'], {
              [styles['model-select-list--standalone']]: isStandaloneModel,
            })}
          >
            {isStandaloneModel && (
              <Tabs.Trigger asChild value={selectedModel}>
                <ModelSelectItem
                  id={selectedModel}
                  title={contents[selectedModel].title}
                  subtitle={contents[selectedModel].subtitle}
                  icon={models[selectedModel].icon}
                  premium={models[selectedModel].premium}
                  upcoming={models[selectedModel].upcoming}
                  onChangeModel={onChangeModel}
                  standalone
                />
              </Tabs.Trigger>
            )}

            {!isStandaloneModel &&
              Object.entries(models)
                .filter(([, { hidden, standalone }]) => !hidden && !standalone)
                .map(([id, { icon, premium }]) => (
                  <Tabs.Trigger asChild key={id} value={id}>
                    <ModelSelectItem
                      key={id}
                      id={id as ConversationModel}
                      title={contents[id as ConversationModel].title}
                      subtitle={contents[id as ConversationModel].subtitle}
                      icon={icon}
                      premium={premium}
                      onChangeModel={onChangeModel}
                    />
                  </Tabs.Trigger>
                ))}
          </fieldset>
        </Tabs.List>

        {Object.entries(contents).map(
          ([id, { title, description, examples }]) => {
            const badgeType = getBadgeType({
              upcoming: models[id as ConversationModel].upcoming,
              premium: models[id as ConversationModel].premium,
            });

            return (
              <Tabs.Content key={id} value={id} asChild>
                <div className={styles['model-content']}>
                  <div className={styles['model-content__header']}>
                    <Heading
                      as="h1"
                      size={{ initial: '3' }}
                      weight="semi-bold"
                      className={styles['model-content__title']}
                    >
                      {title}
                    </Heading>

                    {badgeType !== null && isUserPremium === false && (
                      <ModelBadge type={badgeType} />
                    )}
                  </div>

                  <Text
                    as="p"
                    size={{ initial: '2', sm: '1' }}
                    weight="regular"
                    className={styles['model-content__description']}
                  >
                    {description}
                  </Text>

                  {examples !== undefined && (
                    <>
                      <Text
                        as="p"
                        size={{ initial: '2', sm: '1' }}
                        weight="regular"
                        className={styles['model-content__description']}
                      >
                        You can start a conversation from the message box below
                        or try the following examples:
                      </Text>

                      <ChatExamples
                        examples={examples}
                        onClickExample={onClickExample}
                      />
                    </>
                  )}

                  {models[id as ConversationModel]?.fileUploadSupport ===
                    true && (
                    <ChooseFile
                      onSelectFile={onSelectFile}
                      onRequestPaywall={onRequestPaywall}
                    />
                  )}
                </div>
              </Tabs.Content>
            );
          },
        )}
      </section>
    </Tabs.Root>
  );
}
