import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';

import styles from './CommentCard.module.scss';

interface CommentType {
  title: string;
  content: string;
}
export function CommentCard(): ReactNode {
  const comments: CommentType[] = [
    {
      title: 'Very user friendly',
      content:
        "This one is the most easy-to-use AI app I've used so far. Love it for any question I have.",
    },
    {
      title: 'Very Cool',
      content:
        'It is amazing app for fun and useful generating content, creating great ideas! This AI chatbot saves me in sad evenings',
    },
    {
      title: 'Crazy Good!!! \nThis is the future.',
      content:
        'So crazy how complicated and intelligent this is, but also the excellent feedback you get to inspire or help you. I’m a writer, so it’s incredible to get our of writer’s block in any form possible.',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <h4 className={styles.title}>Trusted by millions</h4>
        <p className={styles.description}>
          Become a member of our <br /> global community of
        </p>
        <h4 className={styles.subtitle}>30 million people</h4>
        <div className={styles.card}>
          {comments.map((item, index) => (
            <div
              key={index}
              className={styles['landing-comments-animation__card']}
            >
              <div className={styles.stars}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <FontAwesomeIcon color="#ffc568" key={star} icon={faStar} />
                ))}
              </div>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.content}>{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
