import * as ScrollArea from '@radix-ui/react-scroll-area';
import { differenceInDays } from 'date-fns';
import { type ReactElement } from 'react';

import { type ConversationListType } from '../../../types/conversation';
import { ConversationDateGroup } from '../ConversationDateGroup/ConversationDateGroup';
import styles from './ConversationList.module.scss';

type RelativeDate =
  | 'today'
  | 'yesterday'
  | 'previous-7-days'
  | 'previous-30-days'
  | 'previous-60-days'
  | 'older';

function getRelativeDateCategory(timestamp: number): RelativeDate {
  const currentDate = new Date();
  const date = new Date(timestamp);

  const daysDifference = differenceInDays(currentDate, date);

  if (daysDifference === 0) {
    return 'today';
  } else if (daysDifference === 1) {
    return 'yesterday';
  } else if (daysDifference <= 7) {
    return 'previous-7-days';
  } else if (daysDifference <= 30) {
    return 'previous-30-days';
  } else if (daysDifference <= 60) {
    return 'previous-60-days';
  } else {
    return 'older';
  }
}

const getConversationDateGroups = (
  conversations: ConversationListType,
): Partial<Record<RelativeDate, ConversationListType>> => {
  const groupedConversations: Partial<
    Record<RelativeDate, ConversationListType>
  > = {
    today: [],
    yesterday: [],
    'previous-7-days': [],
    'previous-30-days': [],
    'previous-60-days': [],
    older: [],
  };

  conversations.forEach((conversation) => {
    const relativeDate = getRelativeDateCategory(conversation.updatedAt);

    const conversationGroup = groupedConversations[relativeDate];

    if (conversationGroup !== undefined) {
      conversationGroup.push(conversation);
    }
  });

  return groupedConversations;
};

export function ConversationList({
  conversations,
}: {
  conversations: ConversationListType;
}): ReactElement {
  const dateGroups: Record<RelativeDate, string> = {
    today: 'Today',
    yesterday: 'Yesterday',
    'previous-7-days': 'Previous 7 days',
    'previous-30-days': 'Previous 30 days',
    'previous-60-days': 'Previous 60 days',
    older: 'Older',
  };

  const groupedConversations = getConversationDateGroups(conversations);

  return (
    <ScrollArea.Root className={styles['scroll-area-root']}>
      <ScrollArea.Viewport className={styles['scroll-area-viewport']} asChild>
        <div className={styles['conversation-list']}>
          {(
            Object.entries(groupedConversations) as Array<
              [RelativeDate, ConversationListType]
            >
          ).map(([relativeDateGroup, groupConversations], index) => (
            <ConversationDateGroup
              key={index}
              title={dateGroups[relativeDateGroup]}
              conversations={groupConversations}
            />
          ))}
        </div>
      </ScrollArea.Viewport>

      <ScrollArea.Scrollbar
        className={styles['scroll-area-scrollbar']}
        orientation="vertical"
      >
        <ScrollArea.Thumb className={styles['scroll-area-thumb']} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}
