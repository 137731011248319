import {
  type ComponentProps,
  type ForwardedRef,
  type MouseEventHandler,
  type ReactElement,
  forwardRef,
  useRef,
} from 'react';
import { mergeRefs } from 'react-merge-refs';

import { TextField } from '../TextField/TextField';
import { TogglePassword } from './TogglePasswordButton/TogglePasswordButton';

function PasswordFieldComponent(
  props: Omit<ComponentProps<typeof TextField>, 'type'>,
  forwardedRef: ForwardedRef<HTMLInputElement>,
): ReactElement {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const typeRef = useRef<'text' | 'password'>('password');

  const handleAdornmentClick: MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    const toggleElement = event.currentTarget;

    if (typeRef.current === 'text') {
      typeRef.current = 'password';
    } else {
      typeRef.current = 'text';
    }

    textFieldRef.current?.setAttribute('type', typeRef.current);
    textFieldRef.current?.focus();
    toggleElement.setAttribute('data-type', typeRef.current);
  };

  return (
    <TextField
      backgroundColor={props?.backgroundColor}
      type={typeRef.current}
      {...props}
      ref={mergeRefs([forwardedRef, textFieldRef])}
      endAdornment={<TogglePassword onClick={handleAdornmentClick} />}
    />
  );
}

export const PasswordField = forwardRef(PasswordFieldComponent);
