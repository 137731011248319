import { z } from 'zod';

import { BillingCycleSchema } from './billing-cycle';
import { CurrencyCodeSchema } from './currency-code';
import { ApiPaymentProductSchema } from './product';

const SubscriptionStatusSchema = z.enum([
  'active',
  'canceled',
  'past_due',
  'paused',
  'trialing',
]);

export type SubscriptionStatus = z.infer<typeof SubscriptionStatusSchema>;

const SubscriptionSchema = z.object({
  id: z.string(),
  status: SubscriptionStatusSchema,
  currencyCode: CurrencyCodeSchema,
  createdAt: z.string(),
  startedAt: z.string(),
  firstBilledAt: z.string(),
  nextBilledAt: z.string().nullable(),
  canceledAt: z.string().nullable(),
  currentBillingPeriod: z.object({
    startsAt: z.string(),
    endsAt: z.string(),
  }),
  billingCycle: BillingCycleSchema,
  scheduledChange: z
    .object({
      action: z.string(),
      effectiveAt: z.string(),
      resumeAt: z.string().nullable(),
    })
    .nullable(),
  items: z.array(
    z.object({
      status: z.string(),
      recurring: z.boolean(),
      previouslyBilledAt: z.string(),
      nextBilledAt: z.string(),
      price: ApiPaymentProductSchema,
    }),
  ),
});

export type SubscriptionType = z.infer<typeof SubscriptionSchema>;
