import cn from 'clsx';
import type { ReactElement, ReactNode } from 'react';

import styles from './AccountSectionContent.module.scss';

export function AccountSectionContent({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement {
  return (
    <div className={cn(styles['account-section-content'], className)}>
      {children}
    </div>
  );
}
