import { faRotate, faStop } from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import debounce from 'lodash.debounce';
import { type ReactElement, useLayoutEffect, useRef } from 'react';
import { useTransition } from 'react-transition-state';

import { Button, Icon } from '@/components';

import styles from './ChatActions.module.scss';

const animationTimeout = 300; // ms

export function ChatActions({
  showStopGeneratingButton,
  showRegenerateButton,
  onClickRegenerateResponse,
  onClickStopGenerating,
  hasError,
}: {
  showStopGeneratingButton: boolean;
  showRegenerateButton: boolean;
  onClickRegenerateResponse: () => void;
  onClickStopGenerating: () => void;
  hasError: boolean;
}): ReactElement {
  const debouncedRegenerateRef = useRef<ReturnType<typeof debounce> | null>(
    null,
  );
  const debouncedStopRef = useRef<ReturnType<typeof debounce> | null>(null);

  const [
    { status: regenerateButtonStatus, isMounted: isRegenerateButtonMounted },
    toggleRegenerateButton,
  ] = useTransition({
    timeout: animationTimeout,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  const [
    { status: stopButtonStatus, isMounted: isStopButtonMounted },
    toggleStopButton,
  ] = useTransition({
    timeout: animationTimeout,
    mountOnEnter: true,
    unmountOnExit: true,
  });

  useLayoutEffect(() => {
    toggleRegenerateButton(showRegenerateButton);
  }, [showRegenerateButton]);

  useLayoutEffect(() => {
    toggleStopButton(showStopGeneratingButton);
  }, [showStopGeneratingButton]);

  return (
    <div
      className={cn(styles['chat-actions'], {
        [styles['chat-actions--error']]: hasError,
      })}
    >
      {isRegenerateButtonMounted && (
        <Button
          type="button"
          variant="white-outline"
          size="small"
          className={cn(styles['chat-actions__button'], {
            [styles['chat-actions__button--open']]:
              regenerateButtonStatus === 'entered' ||
              regenerateButtonStatus === 'entering',
          })}
          onClick={() => {
            if (debouncedRegenerateRef.current !== null) {
              debouncedRegenerateRef.current.cancel();
            }

            debouncedRegenerateRef.current = debounce(
              onClickRegenerateResponse,
              animationTimeout,
            );

            debouncedRegenerateRef.current();
          }}
        >
          <Icon icon={faRotate} />
          Regenerate Response
        </Button>
      )}

      {isStopButtonMounted && (
        <Button
          type="button"
          variant="white-outline"
          size="small"
          className={cn(styles['chat-actions__button'], {
            [styles['chat-actions__button--open']]:
              stopButtonStatus === 'entered' || stopButtonStatus === 'entering',
          })}
          onClick={() => {
            if (debouncedStopRef.current !== null) {
              debouncedStopRef.current.cancel();
            }

            debouncedStopRef.current = debounce(
              onClickStopGenerating,
              animationTimeout,
            );

            debouncedStopRef.current();
          }}
        >
          <Icon icon={faStop} />
          Stop Generating
        </Button>
      )}
    </div>
  );
}
