import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Text } from '@/components';
import { appConfig } from '@/providers/config';

import styles from './RegisterLegalText.module.scss';

export function RegisterLegalText(): ReactElement {
  return (
    <Text
      as="p"
      size={{
        initial: '0',
      }}
      weight="regular"
      className={styles['legal-text']}
    >
      By creating an account, you agree to our
      <br />
      <Link
        to={`${appConfig.landing}/terms`}
        target="_blank"
        className={styles['legal-text__link']}
      >
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link
        to={`${appConfig.landing}/privacy`}
        target="_blank"
        className={styles['legal-text__link']}
      >
        Privacy & Cookie Statement
      </Link>
      .
    </Text>
  );
}
