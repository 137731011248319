import { type ReactElement } from 'react';

import styles from './AuthFormSeparator.module.scss';

export function AuthFormSeparator(): ReactElement {
  return (
    <div className={styles['auth-form-separator']}>
      <span className={styles['auth-form-separator__line']} />
      <p className={styles['auth-form-separator__content']}>or</p>
      <span className={styles['auth-form-separator__line']} />
    </div>
  );
}
