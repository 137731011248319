import cn from 'clsx';
import { type ReactElement } from 'react';

import styles from './MediaContent.module.scss';

export function MediaContent({
  media,
  className,
  mediaClassName,
}: {
  media: string;
  className?: string;
  mediaClassName?: string;
}): ReactElement {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const sourceUrl = import.meta.env.VITE_CDN_URL + '/' + media;
  return (
    <div className={cn(styles.container, className)}>
      <video
        className={cn(styles.video, mediaClassName)}
        key={media}
        muted
        autoPlay
        loop
        playsInline
      >
        <source src={sourceUrl} type="video/mp4" />
      </video>
    </div>
  );
}
