import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Table } from '@radix-ui/themes';
import cn from 'clsx';
import Cookies from 'js-cookie';
import { type ReactElement } from 'react';

import LineSvg from '@/assets/Line.svg';
import AdvancedToolsLogoSource from '@/assets/advanced-tools-logo.svg?react';
import GeminiLogoSource from '@/assets/gemini-logo.svg?react';
import Gpt4LogoSource from '@/assets/gpt4-logo-2.svg?react';
import MetaLogo from '@/assets/meta-logo.svg?react';
import NoLimitsLogoSource from '@/assets/no-limits-logo.svg?react';
import WhatsAppLogoSource from '@/assets/whatsApp-logo.svg?react';
import { Icon, InlineLink } from '@/components';
import { useMediaQuery } from '@/hooks/media-query';
import { appConfig } from '@/providers/config';

import styles from './PaywallFeatures.module.scss';

interface Feature {
  icon: ReactElement;
  title: string;
  description: string;
  isFree: boolean;
  badge?: string;
}

const features: Feature[] = [
  {
    icon: <MetaLogo />,
    title: 'Access to ChatGPT',
    description: 'Chat with ChatGPT',
    isFree: true,
  },
  {
    icon: <Gpt4LogoSource />,
    title: 'Unlimited Access to GPT-4o',
    description: 'More accurate and detailed answers',
    isFree: false,
  },
  {
    icon: <GeminiLogoSource />,
    title: 'Access to Google Gemini',
    description: "Google's latest AI model",
    isFree: false,
  },
  {
    icon: <AdvancedToolsLogoSource />,
    title: 'Unlock Advanced Tools',
    description: 'Access advanced AI tools such as Image Generator and Ask PDF',
    isFree: false,
  },
  {
    icon: <NoLimitsLogoSource />,
    title: 'No Limits',
    description: 'Unlimited dialogues with all AI models',
    isFree: false,
  },
  {
    icon: <WhatsAppLogoSource />,
    title: 'Chat in WhatsApp',
    description: `Use ${appConfig.name} within WhatsApp`,
    isFree: false,
    badge: 'Coming Soon',
  },
];

export function PaywallFeaturesWhite({
  compact,
}: {
  compact?: boolean;
}): ReactElement {
  const isMobile = useMediaQuery('(max-width:1024px)');
  const headerLabels = ['', 'Free', '', isMobile ? 'PRO' : 'Premium'];

  const displayedFeatures = isMobile ? features.slice(0, -1) : features;

  const onSkip = (): void => {
    Cookies.set('landing_skip', 'true', { expires: 1 });
  };

  return (
    <>
      <div className={styles.skip}>
        <h4 className={styles['paywall-title']}>Unlock Unlimited Access</h4>
        <div className={styles['skip-text']}>
          <InlineLink href="/" onClick={onSkip}>
            Skip
          </InlineLink>
        </div>
      </div>
      <Table.Root
        size="1"
        className={cn(styles['paywall-features'], {
          [styles.compact]: compact,
        })}
      >
        <div className={styles['line-container']}>
          <img src={LineSvg} alt="Line" className={styles['line-svg']} />
        </div>
        <Table.Header>
          <Table.Row>
            {headerLabels.map((label, index) => {
              return (
                <Table.ColumnHeaderCell
                  key={index}
                  justify="center"
                  className={styles['feature-header-cell-container']}
                >
                  <div className={styles['feature-header-cell']}>
                    <h4
                      className={cn(
                        styles['feature-header-label'],
                        styles[`feature-header-label-${index}`],
                      )}
                    >
                      {label}
                    </h4>{' '}
                    <div className={styles['feature-header-bottom-line']} />
                  </div>
                </Table.ColumnHeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {displayedFeatures.map(
            ({ title, description, icon, isFree, badge }, index) => {
              return (
                <Table.Row
                  className={styles['feature-row']}
                  key={index}
                  align="center"
                >
                  <Table.RowHeaderCell className={styles['feature-container']}>
                    <div className={styles['feature-content-area']}>
                      <div className={styles.icon}>{icon}</div>

                      <div className={styles['feature-content']}>
                        <div
                          className={styles['feature-content-title-container']}
                        >
                          <h6 className={styles['feature-title']}>{title}</h6>
                        </div>
                        <p className={styles['feature-subtitle']}>
                          {description}
                        </p>
                      </div>
                    </div>
                  </Table.RowHeaderCell>

                  <Table.Cell
                    p={'0'}
                    justify="center"
                    style={{
                      backgroundColor: isMobile
                        ? 'transparent'
                        : 'rgba(0, 0, 0, 0.02)',
                    }}
                    className={styles['feature-cell']}
                  >
                    <Icon
                      icon={isFree ? faCheckCircle : faCircleXmark}
                      className={cn(styles['feature-check-icon'], {
                        [styles['feature-check-icon--not-included-free']]:
                          !isFree,
                      })}
                    />
                  </Table.Cell>

                  <Table.Cell p={'0'} style={{ width: '2px' }}></Table.Cell>

                  <Table.Cell
                    p={'0'}
                    justify="center"
                    style={{
                      backgroundColor: isMobile
                        ? 'transparent'
                        : 'rgba(5, 126, 111, 0.06)',
                    }}
                    className={styles['feature-cell']}
                  >
                    <Icon
                      icon={faCheckCircle}
                      className={styles['feature-check-icon']}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table.Root>
    </>
  );
}
