import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { InlineLink } from '@/components';
import { LandingWhiteLayout } from '@/layouts/LandingWhiteLayout/LandingWhiteLayout';

import { PaywallFeaturesWhite } from '../../../../features/payment/containers/PaywallFeaturesWhite/PaywallFeaturesWhite';
import { CommentCard } from '../../components/PaywallPlansWhite/CommentCard/CommentCard';
import { PaywallPlansWhite } from '../../components/PaywallPlansWhite/PaywallPlansWhite';
import { Phone } from '../../components/PaywallPlansWhite/Phone/Phone';
import { SliderCard } from '../../components/PaywallPlansWhite/SliderCard/SliderCard';
import { usePremium } from '../../services/pro';
import styles from './LandingPlan.module.scss';

export function LandingPlanWhite(): ReactNode {
  const { data: isPremium, loading: premiumLoading } = usePremium();
  const onSkip = (): void => {
    Cookies.set('landing_skip', 'true', { expires: 1 });
  };

  if (premiumLoading) {
    return null;
  } else if (isPremium === true) {
    return <Navigate to="/" replace />;
  }

  return (
    <LandingWhiteLayout
      display="block"
      leftChild={
        <div className={styles.container}>
          <PaywallFeaturesWhite />
        </div>
      }
      rightChild={
        <div className={styles['right-container']}>
          <div className={styles.skip}>
            <InlineLink href="/" onClick={onSkip}>
              Skip
            </InlineLink>
          </div>
          <PaywallPlansWhite />

          <div className={styles.mobile}>
            <CommentCard />
            <Phone />
            <SliderCard />
          </div>
        </div>
      }
    />
  );
}
