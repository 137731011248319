import { type ReactElement, memo } from 'react';
import { useLocation } from 'react-router-dom';

import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { logOut, selectAuth } from '@/features/auth';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { auth as firebaseAuth } from '@/providers/firebase';

import { NavLink } from './NavLink/NavLink';
import styles from './NavMenu.module.scss';

function NavMenuComponent(): ReactElement | undefined {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const location = useLocation();

  const loggedIn = auth.user !== null;

  const handleLoginButtonClick = (): void => {
    logBigQueryEvent('btn_login', {
      eventValue: {
        source: 'app',
      },
    });

    logEvent('btn_login', {
      source: 'app',
    });
  };

  const RenderButton = (): ReactElement => {
    if (location.pathname === '/login') {
      return (
        <NavLink href={'/register'} onClick={handleLoginButtonClick}>
          Sign Up
        </NavLink>
      );
    }

    if (loggedIn) {
      return (
        <NavLink
          href={'/'}
          onClick={async () => {
            await firebaseAuth.signOut();
            dispatch(logOut());
          }}
        >
          Logout
        </NavLink>
      );
    } else {
      return (
        <NavLink href={'/login'} onClick={handleLoginButtonClick}>
          Log in
        </NavLink>
      );
    }
  };

  return (
    <div className={styles['nav-menu']}>
      <nav className={styles.nav}>
        <div className={styles.nav__actions}>{<RenderButton />}</div>
      </nav>
    </div>
  );
}

export const NavMenu = memo(NavMenuComponent);
