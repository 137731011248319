import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { ForgotPasswordForm } from '../containers/ForgotPasswordForm/ForgotPasswordForm';

export function ForgotPasswordRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Forgot Password - {appConfig.name}</title>
      </Helmet>

      <ForgotPasswordForm />
    </>
  );
}
