import { FeaturesReady, useFeatureValue } from '@growthbook/growthbook-react';
import type { ComponentType, ReactElement } from 'react';

interface LandingWrapperProps {
  VariantA: ComponentType;
  VariantB: ComponentType;
}

export function LandingWrapper({
  VariantA,
  VariantB,
}: LandingWrapperProps): ReactElement {
  const value = useFeatureValue('landing-flow', 'default-theme');

  const Component = value === 'white-theme' ? VariantB : VariantA;

  return (
    <FeaturesReady timeout={1000} fallback={<></>}>
      <Component />
    </FeaturesReady>
  );
}
