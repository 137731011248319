import { type ReactElement } from 'react';

import GeminiSuperbot from '@/assets/GeminiSuperbot.png';
import { Button, DialogContent } from '@/components';

import styles from './GeminiSuperbotPopup.module.scss';

export function GeminiSuperbotPopup({
  onClick,
}: {
  onClick: () => void;
}): ReactElement {
  return (
    <DialogContent theme="dark" className={styles['gemini-dialog']}>
      <img
        src={GeminiSuperbot}
        alt=""
        className={styles['gemini-dialog__background']}
      />

      <h1 className={styles['gemini-dialog__title']}>
        Meet <strong>Gemini Superbot!</strong>
      </h1>

      <p className={styles['gemini-dialog__description']}>
        Explore various possibilities with Gemini today!
      </p>

      <Button
        type="button"
        size="large"
        variant="secondary"
        className={styles['gemini-dialog__button']}
        onClick={() => {
          onClick();
        }}
      >
        Try it now
      </Button>
    </DialogContent>
  );
}
