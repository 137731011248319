import { type ReactElement, useEffect } from 'react';

import { Loader, useChangePaymentQuery, usePaddle } from '@/features/payment';

export function PaymentMethod(): ReactElement {
  const { paddleInstance } = usePaddle();
  const { data: payment } = useChangePaymentQuery('change-payment');

  const loading = paddleInstance === null || payment === undefined;

  useEffect(() => {
    if (paddleInstance === null || payment === undefined) {
      return;
    }

    paddleInstance.Checkout.open({
      settings: {
        displayMode: 'inline',
        theme: 'light',
        locale: 'en',
        frameTarget: 'payment-method-container',
        frameInitialHeight: 450,
        allowLogout: false,
        frameStyle:
          'width: 100%; min-width: 312px; background-color: transparent; border: none;',
      },
      transactionId: payment.id,
    });
  }, [paddleInstance, payment]);

  return (
    <div className="payment-method-container">
      {loading ? <Loader /> : null}
    </div>
  );
}
