import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import type { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon, Text } from '@/components';

import styles from './AccountHomeItem.module.scss';

export function AccountHomeItem({
  to,
  title,
}: {
  to: string;
  title: string;
}): ReactElement {
  return (
    <NavLink to={to} className={styles['account-home-item']}>
      <Text
        as="span"
        size={{ initial: '2' }}
        weight="medium"
        className={styles['account-home-item__title']}
      >
        {title}
      </Text>

      <Icon icon={faChevronRight} />
    </NavLink>
  );
}
