import {
  type DOMAttributes,
  type FormEventHandler,
  type ReactElement,
  type ReactNode,
  type RefObject,
} from 'react';

export function Form({
  children,
  onSubmit,
  className,
  formRef,
}: {
  children: ReactNode;
  onSubmit: NonNullable<DOMAttributes<HTMLFormElement>['onSubmit']>;
  className?: string;
  formRef?: RefObject<HTMLFormElement>;
}): ReactElement {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <form ref={formRef} className={className} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}
