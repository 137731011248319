import { zodResolver } from '@hookform/resolvers/zod';
import { type ReactElement, useEffect, useState } from 'react';
import {
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
} from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  Button,
  Form,
  InlineLink,
  PasswordField,
  TextField,
} from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { auth as firebaseAuth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { createFormError } from '../..';
import { AuthFormSeparator } from '../../components/AuthFormSeparator/AuthFormSeparator';
import { FormTitle } from '../../components/FormTitle/FormTitle';
import { SocialLogin } from '../../components/SocialLogin/SocialLogin';
import styles from './AltSignUpForm.module.scss';

interface SignUpFormType {
  email: string;
  password: string;
}

export function AltSignUpForm(): ReactElement {
  const [createUserWithEmailAndPassword, , signUpLoading, signUpError] =
    useCreateUserWithEmailAndPassword(firebaseAuth);
  const [
    sendEmailVerification,
    sendEmailVerificationLoading,
    sendEmailVerificationError,
  ] = useSendEmailVerification(firebaseAuth);

  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [step, setStep] = useState<number>(0);

  const [params] = useSearchParams();
  const initialEmail = params.get('email');

  const { logBigQueryEvent } = useLogBigQueryEvent();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SignUpFormType>({
    defaultValues: {
      email: initialEmail ?? '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(0, "Email can't be empty.")
          .email('Email is invalid'),
        password: z
          .string()
          .min(0, "Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      }),
    ),
  });

  const handleSignUp: SubmitHandler<SignUpFormType> = async ({
    email,
    password,
  }) => {
    const credentials = await createUserWithEmailAndPassword(email, password);

    if (credentials === undefined) {
      return;
    }

    logBigQueryEvent('sign_up', {
      type: 'email',
    });
    logEvent('sign_up', {
      type: 'email',
    });

    logBigQueryEvent('btn_register_sign_up', {
      eventValue: {
        source: 'register_page_content',
      },
    });

    if ('dataLayer' in window) {
      window.dataLayer.push({
        email,
        external_id: credentials.user.uid,
      });
    }

    logEvent('btn_register_sign_up');

    if (!credentials.user.emailVerified) {
      const success = await sendEmailVerification();

      setEmailVerificationSent(success);
    }
  };

  useEffect(() => {
    if (signUpError === undefined) {
      return;
    }

    logBigQueryEvent('register_error', {
      eventValue: {
        info: signUpError.message,
      },
    });
    logEvent('register_error', {
      info: signUpError.message,
    });

    const error = createFormError(signUpError.code);

    if (error === null) {
      toast.error(signUpError.message);
      return;
    }

    if (error.target === 'email' || error.target === 'password') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [signUpError, setError, logBigQueryEvent]);

  const handleLoginButtonClick = (): void => {
    logBigQueryEvent('btn_login', {
      eventValue: {
        source: 'register_page_content',
      },
    });

    logEvent('btn_login', {
      source: 'register_page_content',
    });
  };

  return (
    <div className={styles['sign-up-form']}>
      <FormTitle>
        <span className={styles['sign-up-form__title']}>
          Create Your Account
        </span>
      </FormTitle>

      {emailVerificationSent && (
        <p>Check your email for instructions to verify your email.</p>
      )}

      {!emailVerificationSent && (
        <>
          <SocialLogin source="register" />

          <AuthFormSeparator />
        </>
      )}

      {!emailVerificationSent && (
        <Form
          className={styles.form}
          onSubmit={handlePromiseEvent(handleSubmit(handleSignUp))}
        >
          <TextField
            type="email"
            placeholder="Email Address"
            errorMessage={errors.email?.message}
            {...register('email')}
          />

          {step > 0 && (
            <PasswordField
              placeholder="Password"
              errorMessage={errors.password?.message}
              {...register('password')}
            />
          )}

          {sendEmailVerificationError !== undefined && (
            <p className={styles.error}>{sendEmailVerificationError.message}</p>
          )}

          <Button
            size="large"
            variant="primary"
            loading={signUpLoading || sendEmailVerificationLoading}
            type={step === 0 ? 'button' : 'submit'}
            onClick={() => {
              setStep((prev) => prev + 1);
            }}
          >
            Sign Up
          </Button>
        </Form>
      )}

      {emailVerificationSent && (
        <>
          <AuthFormSeparator />

          <SocialLogin source="register" />
        </>
      )}

      <div className={styles['login-info']}>
        <span>Do you have an account?</span>{' '}
        <InlineLink href="/login" onClick={handleLoginButtonClick}>
          Log in
        </InlineLink>
      </div>
    </div>
  );
}
