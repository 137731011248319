import { type ReactElement } from 'react';

import { AppleLogin } from './AppleLogin/AppleLogin';
import { GoogleLogin } from './GoogleLogin/GoogleLogin';
import styles from './SocialLogin.module.scss';

export function SocialLogin({
  source,
}: {
  source: 'register' | 'login';
}): ReactElement {
  return (
    <div className={styles['social-login']}>
      <GoogleLogin source={source} />
      <AppleLogin source={source} />
    </div>
  );
}
