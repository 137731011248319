import { useAutoAnimate } from '@formkit/auto-animate/react';
import { type ComponentProps, type ReactElement } from 'react';

import { type MessageListType } from '../../../types/message';
import { MessageItem } from './MessageItem/MessageItem';

export function MessageList({
  messages,
  onClickRetry,
  onClickOption,
}: {
  messages: MessageListType;
  onClickRetry: ComponentProps<typeof MessageItem>['onClickRetry'];
  onClickOption: (option: string) => void;
}): ReactElement {
  const [animationParent] = useAutoAnimate();
  return (
    <div ref={animationParent}>
      {messages.map(({ id, role, content, error, images, documents }) => (
        <MessageItem
          key={id}
          id={id}
          role={role}
          content={content}
          error={error}
          images={images}
          documents={documents}
          onClickRetry={onClickRetry}
          onClickOption={onClickOption}
        />
      ))}
    </div>
  );
}
