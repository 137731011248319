import { type ReactElement, useMemo } from 'react';

import { type SubscriptionType, getInterval } from '@/features/payment';

import styles from './MyPlanDetail.module.scss';

export function MyPlanDetail({
  subscription,
}: {
  subscription: SubscriptionType;
}): ReactElement {
  const status = useMemo(() => {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    if (subscription?.scheduledChange?.action === 'cancel') {
      return `Ends on ${dateFormatter.format(
        new Date(subscription.scheduledChange.effectiveAt),
      )}`;
    } else if (
      subscription.status === 'active' ||
      subscription.status === 'past_due'
    ) {
      return `Renews on ${dateFormatter.format(
        new Date(subscription.nextBilledAt ?? ''),
      )}`;
    } else {
      return `Canceled on ${dateFormatter.format(
        new Date(subscription.canceledAt ?? ''),
      )}`;
    }
  }, [subscription]);

  const billingCycle = useMemo(() => {
    const interval = getInterval(subscription.billingCycle);
    const priceFormatter = Intl.NumberFormat('en', {
      style: 'currency',
      currency: subscription.items[0].price.unitPrice.currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return {
      price: priceFormatter.format(
        parseFloat(subscription.items[0].price.unitPrice.amount) / 100,
      ),
      interval,
    };
  }, [subscription]);

  return (
    <div className={styles['plan-detail']}>
      <div className={styles.title}>Plan Detail</div>
      <div className={styles.status}>{status}</div>
      <div className={styles.cycle}>
        <span className={styles.price}>{billingCycle.price}</span> /{' '}
        <span className={styles.interval}>{billingCycle.interval}</span>
      </div>
    </div>
  );
}
