import type { ReactElement } from 'react';

import styles from './Table.module.scss';

export function Table({ children }: { children: ReactElement }): ReactElement {
  return (
    <div className={styles['table-wrapper']}>
      <table className={styles.table}>{children}</table>
    </div>
  );
}
