import { zodResolver } from '@hookform/resolvers/zod';
import type { AuthError } from 'firebase/auth';
import { type ReactElement, useEffect, useState } from 'react';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import ArrowLeft from '@/assets/arrow-left.svg?react';
import Arrow from '@/assets/arrow.svg?react';
import EmailIcon from '@/assets/check-email.svg?react';
import PasswordIcon from '@/assets/forgot-password.svg?react';
import { Button, Form, InlineLink, TextField } from '@/components';
import usePathType from '@/hooks/path-type';
import { auth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { createFormError } from '../../utils/create-form-error';
import styles from './LandingForgotPasswordForm.module.scss';

interface ForgotPasswordFormType {
  email: string;
}

export function LandingWhiteForgotPasswordForm(): ReactElement {
  const [emailSent, setEmailSent] = useState(false);
  const [sendPasswordResetEmail, sendPasswordLoading, sendPasswordError] =
    useSendPasswordResetEmail(auth);
  const path = usePathType();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<ForgotPasswordFormType>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(0, "Email can't be empty.")
          .email('Email is invalid'),
      }),
    ),
  });

  const email = watch('email', '');

  useEffect(() => {
    if (sendPasswordError === undefined) {
      return;
    }

    const error = createFormError((sendPasswordError as AuthError)?.code);

    if (error === null) {
      toast.error(sendPasswordError.message);
      return;
    }

    if (error.target === 'email') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [sendPasswordError, setError]);

  const handleForgotPassword: SubmitHandler<ForgotPasswordFormType> = async ({
    email,
  }) => {
    const success = await sendPasswordResetEmail(email);
    setEmailSent(success);
  };

  return (
    <div className={styles['forgot-password-form']}>
      {emailSent && (
        <div className={styles.content}>
          <EmailIcon className={styles.logo} />
          <div className={styles.content}>
            <h1 className={styles.title}>Check Email</h1>
            <p className={styles.text}>
              We sent a password reset link to {email}
            </p>
          </div>
        </div>
      )}

      {!emailSent && (
        <>
          <PasswordIcon className={styles.logo} />

          <h1 className={styles.title}>Forgot Password</h1>
          <p className={styles.text}>
            No worries, we’ll send you reset instructions.
          </p>

          <Form
            className={styles.form}
            onSubmit={handlePromiseEvent(handleSubmit(handleForgotPassword))}
          >
            <div>
              <p className={styles.description}>Email</p>
              <TextField
                variant="-white"
                type="email"
                backgroundColor="#fff"
                placeholder="Enter your email"
                value={email}
                errorMessage={errors.email?.message}
                {...register('email')}
              />
            </div>

            <Button
              size="large"
              variant="primary"
              className={styles.button}
              loading={sendPasswordLoading}
              type="submit"
            >
              <b>Reset password</b>
              <Arrow className={styles.arrow} />
            </Button>
          </Form>
        </>
      )}

      <div className={styles['login-info']}>
        <ArrowLeft className={styles.arrow__left} />
        <InlineLink href={`/${path}/login`}>Back to Login</InlineLink>
      </div>
    </div>
  );
}
