import { z } from 'zod';

import { api } from '@/providers/api';

const TokenCountSchema = z.object({
  content: z.string(),
  model: z.number(),
  id: z.string().optional().nullable(),
});

type TokenCount = z.infer<typeof TokenCountSchema>;

const TokenCountResponseSchema = z.object({
  data: z.object({
    id: z.number().nullable(),
    token: z.number(),
  }),
});

type TokenCountResponse = z.infer<typeof TokenCountResponseSchema>;

const tokenCountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    tokenCount: builder.mutation<number, TokenCount>({
      query: ({ content, model, id }) => ({
        url: '/token-count',
        method: 'POST',
        body: {
          content,
          model,
          id,
        },
      }),
      transformResponse: (data: TokenCountResponse['data']) => data.token,
    }),
  }),
});

export const { useTokenCountMutation } = tokenCountApi;
