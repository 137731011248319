import type { ReactElement, ReactNode } from 'react';

import styles from './AccountSectionFooterNote.module.scss';

export function AccountSectionFooterNote({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className={styles['account-section-footer-note']}>{children}</div>
  );
}
