import cn from 'clsx';
import { type ReactElement, Suspense, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { usePremium } from '@/features/account';
import { selectAuth } from '@/features/auth';
import { useExperiment } from '@/features/experiment';
import { AuthHeader, PublicFooter, PublicHeader } from '@/features/layouts';
import { useAppSelector } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import usePathType from '@/hooks/path-type';
import { auth as firebaseAuth } from '@/providers/firebase';

import styles from './AuthLayout.module.scss';

interface NavigationState {
  from?: string;
}

type LocationState = NavigationState | undefined;

export function AuthLayout({
  fullscreen = false,
}: {
  fullscreen?: boolean;
}): ReactElement {
  const auth = useAppSelector(selectAuth);
  const { syncUserState } = useAuth();
  const location = useLocation();
  const { data: isPremium, loading: premiumLoading } = usePremium();
  const { experiment } = useExperiment();
  const path = usePathType();

  useEffect(() => {
    if (localStorage.getItem('path') != null && path.includes('landing')) {
      localStorage.removeItem('path');
    }
    if (path.includes('landing')) {
      localStorage.setItem('path', path);
    } else if (localStorage.getItem('path') === null) {
      localStorage.setItem('path', 'landing');
    }
  }, [path]);

  const loggedIn = auth.user !== null;

  const navigationState = location.state as LocationState;
  const alternateFlow = experiment === 'alternative-flow';

  useEffect(() => {
    if (!loggedIn) return;

    void firebaseAuth.currentUser?.reload().then(() => {
      syncUserState(firebaseAuth.currentUser);
    });
  }, [loggedIn, syncUserState]);

  if (premiumLoading) {
    return <></>;
  }

  if (
    alternateFlow &&
    loggedIn &&
    (isPremium === false || isPremium === null)
  ) {
    return <Navigate to="/choose-plan" />;
  } else if (loggedIn) {
    return <Navigate to={navigationState?.from ?? `/`} replace />;
  }

  if (fullscreen) {
    return (
      <Suspense>
        <Outlet />
      </Suspense>
    );
  }

  return (
    <div
      className={cn(
        styles['auth-layout'],
        experiment === 'alternative-flow' ? styles.alternate : null,
      )}
    >
      {experiment === 'current-flow' ? <AuthHeader /> : <PublicHeader />}

      <main className={styles.main}>
        <div className={styles.main__container}>
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </main>
      {experiment === 'alternative-flow' && <PublicFooter />}
    </div>
  );
}
