import { type ReactElement } from 'react';

import PoweredByOpenAi from '@/assets/powered-by-openai.svg?react';
import { Logo } from '@/components';
import usePathType from '@/hooks/path-type';
import { LandingLayout } from '@/layouts';

import { MediaContent } from '../../../../components/MediaContent/MediaContent';
import { LandingCommentsAnimation } from '../../components/LandingComments/LandingComments';
import { LandingForgotPasswordForm } from '../../components/LandingForgotPasswordForm/LandingForgotPasswordForm';
import styles from './LandingForgotPassword.module.scss';

export function LandingForgotPassword(): ReactElement {
  const path = usePathType();
  return (
    <LandingLayout
      leftChild={
        path === 'landing' ? (
          <MediaContent media={'89f5649c-b935-494c-b033-74d3fba47160.mp4'} />
        ) : (
          <LandingCommentsAnimation />
        )
      }
      rightChild={
        <div className={styles.container}>
          <Logo className={styles.logo} size={'large'} />
          <div className={styles.content}>
            <h1 className={styles.title}>Forgot Password</h1>
            <p className={styles.description}>
              Enter the email address you used when you joined and we&apos;ll
              send you a link to reset your password.
            </p>
            <LandingForgotPasswordForm />
          </div>
          <span className={styles['powered-by']}>
            <PoweredByOpenAi />
          </span>
        </div>
      }
    />
  );
}
