import { Slot } from '@radix-ui/react-slot';
import cn from 'clsx';
import {
  type ForwardedRef,
  type ReactElement,
  type ReactNode,
  forwardRef,
} from 'react';

import styles from './HeaderButton.module.scss';

function HeaderButtonComponent(
  {
    className,
    variant,
    asChild = false,
    children,
    ...props
  }: {
    asChild?: boolean;
    children: ReactNode;
    variant: 'transparent' | 'filled';
    className?: string;
  },
  ref: ForwardedRef<HTMLButtonElement>,
): ReactElement {
  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      ref={ref}
      type="button"
      className={cn(
        styles['header-button'],
        styles[`header-button--variant-${variant}`],
        className,
      )}
      {...props}
    >
      {children}
    </Comp>
  );
}

export const HeaderButton = forwardRef(HeaderButtonComponent);
