/**
 *
 * @param date - the date to compare with the current time
 * @returns the difference in hours between the current time and the date passed as an argument
 */
export const getTimeDifference = (date: string): number => {
  const createdAt = new Date(date);
  const currentTime = new Date();
  const differenceInHours =
    Math.abs(Number(currentTime) - Number(createdAt)) / 36e5;
  return differenceInHours;
};
