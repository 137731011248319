import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import styles from './InlineLink.module.scss';

export function InlineLink({
  href,
  children,
  onClick,
}: {
  href: string;
  children: string;
  onClick?: () => void;
}): ReactElement {
  return (
    <Link to={href} className={styles['inline-link']} onClick={onClick}>
      {children}
    </Link>
  );
}
