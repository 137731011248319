import { type ReactElement } from 'react';

import { Dialog, DialogTrigger } from '@/components';
import { PaywallDialog } from '@/features/account';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';

import { ActionButton } from '../ActionButton/ActionButton';

export function UpgradeToPro(): ReactElement {
  const { logBigQueryEvent } = useLogBigQueryEvent();

  return (
    <Dialog
      onOpenChange={(open) => {
        if (open) {
          logBigQueryEvent('lnd_paywall');
          logEvent('lnd_paywall', {
            paywallType: 'in_app',
          });
        }
      }}
    >
      <DialogTrigger asChild>
        <ActionButton>Upgrade to PRO</ActionButton>
      </DialogTrigger>

      <PaywallDialog />
    </Dialog>
  );
}
