import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { type ReactElement, useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AlertDialogContent, Button, Icon } from '@/components';
import {
  logEvent,
  useEventStatus,
  useLogBigQueryEvent,
} from '@/features/analytics';

import { CheckAnimation } from '../../../components/CheckAnimation/CheckAnimation';
import { LoadingAnimation } from '../../../components/LoadingAnimation/LoadingAnimation';
import type { Pricing } from '../../../hooks/callback-handler';
import { PricingTable } from '../PricingTable/PricingTable';
import styles from './ProcessDialog.module.scss';

export function ProcessDialog({
  pricing,
  complete = false,
}: {
  pricing: Pricing;
  complete?: boolean;
}): ReactElement {
  const [searchParams] = useSearchParams();
  const [, setPremiumSuccesfullyGrantedEventSent] = useEventStatus(
    'premiumSuccesfullyGranted',
  );
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const navigate = useNavigate();

  const logPremiumGranted = useCallback((): void => {
    if (complete) {
      void setPremiumSuccesfullyGrantedEventSent(true);

      logBigQueryEvent('premium_succesfully_granted');
      logEvent('premium_succesfully_granted');
    }
  }, [complete, logBigQueryEvent, setPremiumSuccesfullyGrantedEventSent]);

  const continueHandler = useCallback((): void => {
    if (!complete) {
      return;
    }

    logPremiumGranted();

    if ('clarity' in window && typeof window.clarity === 'function') {
      window.clarity('set', 'paymentComplete', 'true');
    }
    const model = localStorage.getItem('model');

    searchParams.set('success', 'true');
    searchParams.set('amount', String(pricing.rawTotalAmount));
    searchParams.set('currency', pricing.currency);
    if (pricing.plan !== null) {
      searchParams.set('plan', pricing.plan);
    }
    if (model !== null) {
      searchParams.set('model', model);
      localStorage.removeItem('model');
      logBigQueryEvent('paywall_type', { modelTypeDisplayed: model });
      logEvent('paywall_type', { modelTypeDisplayed: model });
    }

    navigate(`/?${searchParams.toString()}`, { replace: true });
  }, [
    complete,
    navigate,
    pricing,
    searchParams,
    logPremiumGranted,
    logBigQueryEvent,
  ]);

  useEffect(() => {
    if (!complete) {
      return;
    }

    const timer = setTimeout(() => {
      continueHandler();
    }, 1000);

    return (): void => {
      clearTimeout(timer);
    };
  }, [complete, continueHandler]);

  return (
    <AlertDialogContent>
      <div className={styles.container}>
        <div className={styles.animation}>
          {complete ? <CheckAnimation /> : <LoadingAnimation />}
        </div>

        {complete ? (
          <>
            <h2 className={styles['message-title']}>Payment received!</h2>
            <p>Payment successful. Thank you for your order.</p>
          </>
        ) : (
          <>
            <h2 className={styles['message-title']}>Receiving payment...</h2>
            <p>
              Your payment has been submitted for processing. Please wait for
              the result.
            </p>
          </>
        )}

        <PricingTable pricing={pricing} />
        <Button
          className={styles['action-button']}
          variant={'primary'}
          size={'small'}
          disabled={!complete}
          onClick={continueHandler}
        >
          {!complete && <Icon icon={faSpinnerThird} spin size="xl" />}
          Continue
        </Button>
      </div>
    </AlertDialogContent>
  );
}
