import type { User as FirebaseUser } from 'firebase/auth';
import { type ReactElement, useMemo } from 'react';

import {
  type SocialProvider,
  type SocialProviderId,
  linkAppleAccount,
  linkGoogleAccount,
  mapFirebaseUserToUser,
  selectAuth,
  setUser,
  unlinkAccount,
} from '@/features/auth';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { appConfig } from '@/providers/config';

import { AccountPageContainer } from '../../components/AccountPageContainer/AccountPageContainer';
import { AccountPageHeader } from '../../components/AccountPageHeader/AccountPageHeader';
import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionTitle,
} from '../../components/AccountSection/AccountSection';
import { LoginProvider } from './LoginProvider/LoginProvider';

type SocialProviders = Array<{
  id: SocialProviderId;
  provider: SocialProvider | null;
}>;

export function LoginConnections(): ReactElement | null {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const providers: SocialProviders = useMemo(() => {
    const activeProviders: Record<SocialProviderId, SocialProviders> = {
      'apple.com': [],
      'google.com': [],
    };

    if (auth.user === null) {
      return [];
    }

    auth.user.providers.forEach((provider) => {
      const providerId = provider.id;

      // Skip password provider
      if (providerId === 'password') {
        return;
      }

      activeProviders[providerId].push({
        id: providerId,
        provider: provider as SocialProvider,
      });
    });

    const allProviders: SocialProviders = [];

    Object.keys(activeProviders).forEach((key) => {
      const socialProviderId = key as SocialProviderId;

      if (activeProviders[socialProviderId].length === 0) {
        allProviders.push({
          id: socialProviderId,
          provider: null,
        });
      } else {
        allProviders.push(...activeProviders[socialProviderId]);
      }
    });

    return allProviders;
  }, [auth.user]);

  const handleConnect = async (providerId: SocialProviderId): Promise<void> => {
    const linkFns: Record<
      SocialProviderId,
      () => Promise<FirebaseUser | null>
    > = {
      'apple.com': linkAppleAccount,
      'google.com': linkGoogleAccount,
    };

    const updatedUser = await linkFns[providerId]();

    if (updatedUser !== null) {
      const mappedUser = mapFirebaseUserToUser(updatedUser);
      dispatch(setUser(mappedUser));
    }
  };

  const handleDisconnect = async (
    providerId: SocialProviderId,
  ): Promise<void> => {
    const updatedUser = await unlinkAccount(providerId);

    if (updatedUser !== null) {
      const mappedUser = mapFirebaseUserToUser(updatedUser);
      dispatch(setUser(mappedUser));
    }
  };

  return (
    <AccountPageContainer>
      <AccountPageHeader>Login Connections</AccountPageHeader>

      <AccountSection>
        <AccountSectionHeader>
          <AccountSectionHeaderContainer>
            <AccountSectionTitle>Connections</AccountSectionTitle>
            <AccountSectionDescription>
              {`Connect ${appConfig.name} with your existing tools.`}
            </AccountSectionDescription>
          </AccountSectionHeaderContainer>
        </AccountSectionHeader>

        <AccountSectionContent>
          {providers.map(({ id, provider }, index) => (
            <LoginProvider
              key={index}
              id={id}
              provider={provider}
              onClickConnect={() => {
                // eslint-disable-next-line no-console
                handleConnect(id).catch(console.error);
              }}
              onClickDisconnect={() => {
                // eslint-disable-next-line no-console
                handleDisconnect(id).catch(console.error);
              }}
            />
          ))}
        </AccountSectionContent>
      </AccountSection>
    </AccountPageContainer>
  );
}
