import { type ReactElement } from 'react';

import welcomeToProDialogBackgroundImageAiApp from '@/assets/welcome-to-pro-dialog-background-aiapp.png';
import welcomeToProDialogBackgroundImageNova from '@/assets/welcome-to-pro-dialog-background-nova.png';
import welcomeToProDialogBackgroundImage from '@/assets/welcome-to-pro-dialog-background.png';
import { Button, DialogContent } from '@/components';

import styles from './ProPlanWelcomeDialog.module.scss';

const welcomeImage = {
  chatbotapp: welcomeToProDialogBackgroundImage,
  novaapp: welcomeToProDialogBackgroundImageNova,
  aiapp: welcomeToProDialogBackgroundImageAiApp,
};

export function ProPlanWelcomeDialog({
  onClickAskMeAnything,
}: {
  onClickAskMeAnything: () => void;
}): ReactElement {
  return (
    <DialogContent theme="dark" className={styles['pro-plan-welcome-dialog']}>
      <img
        src={welcomeImage[import.meta.env.VITE_APP_NAME]}
        alt=""
        className={styles['pro-plan-welcome-dialog__background']}
      />

      <h1 className={styles['pro-plan-welcome-dialog__title']}>
        Welcome to <strong>PRO</strong>
      </h1>

      <p className={styles['pro-plan-welcome-dialog__description']}>
        You can use all our features without limits.
      </p>

      <Button
        type="button"
        size="large"
        variant="secondary"
        className={styles['pro-plan-welcome-dialog__button']}
        onClick={() => {
          onClickAskMeAnything();
        }}
      >
        Ask me anything!
      </Button>
    </DialogContent>
  );
}
