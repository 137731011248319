import {
  ref as databaseRef,
  update as databaseUpdate,
  serverTimestamp,
} from 'firebase/database';

import { database } from '@/providers/firebase';
import { store } from '@/providers/store';

import {
  type DatabaseDraftMessageItemType,
  type ImageState,
} from '../types/message';

export async function updateMessage({
  conversationId,
  messageId,
  message,
}: {
  conversationId: string;
  messageId: string;
  message: Pick<
    DatabaseDraftMessageItemType,
    'content' | 'error' | 'images' | 'role' | 'documents'
  > & {
    createdAt: number | null;
  };
}): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  const { content, error, images, documents, role, createdAt } = message;

  const messagePath = `${user.uid}/messages/${conversationId}/${messageId}`;

  const timestamp = serverTimestamp();

  // Setting a path value to null removes it from database.
  await databaseUpdate(databaseRef(database), {
    [`${messagePath}/role`]: role ?? undefined,
    [`${messagePath}/content`]: content ?? null,
    [`${messagePath}/images`]: images ?? null,
    [`${messagePath}/documents`]: documents ?? null,
    [`${messagePath}/error`]: error ?? null,
    [`${messagePath}/createdAt`]: createdAt ?? timestamp,
    [`${messagePath}/updatedAt`]: timestamp,
  });
}

export async function updateMessageImages({
  conversationId,
  messageId,
  message,
}: {
  conversationId: string;
  messageId: string;
  message: Pick<DatabaseDraftMessageItemType, 'images'>;
}): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  const { images } = message;

  const messagePath = `${user.uid}/messages/${conversationId}/${messageId}`;

  const timestamp = serverTimestamp();

  // Setting a path value to null removes it from database.
  await databaseUpdate(databaseRef(database), {
    [`${messagePath}/images`]: images ?? null,
    [`${messagePath}/updatedAt`]: timestamp,
  });
}

export async function updateMessageImageState({
  conversationId,
  messageId,
  imageIndex,
  state,
}: {
  conversationId: string;
  messageId: string;
  imageIndex: number;
  state: ImageState;
}): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  const messagePath = `${user.uid}/messages/${conversationId}/${messageId}`;

  const timestamp = serverTimestamp();

  await databaseUpdate(databaseRef(database), {
    [`${messagePath}/images/${imageIndex}/state`]: state,
    [`${messagePath}/updatedAt`]: timestamp,
  });
}
