import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'clsx';
import type { ReactElement } from 'react';

import Available from '@/assets/available-platforms.svg?react';
import HappyUsers from '@/assets/happy-users.svg?react';
import Trusted from '@/assets/trusted.svg?react';
import { appConfig } from '@/providers/config';

import styles from './LandingComments.module.scss';

interface CommentType {
  title: string;
  content: string;
}

export function LandingCommentsAnimation(): ReactElement {
  const comments: CommentType[] = [
    {
      title: 'Great Job!!!',
      content:
        "AI Chatbot gets a full 5 stars from this user! It's accurate..wealth of knowledge on steroids, friendly..and even polite!.. This is the beginning of a wonderful human to bot relationship in learning and exploring the world of things! Thank you to the developers.. you did a great job!",
    },
    {
      title: 'All-in-one. Love it!',
      content:
        'I love being able to ask a question and get an answer right away, this works really well for resume help, recipes for brewing, and I even used it to give me dinner ideas with what I had on hand, I use it everyday to aid in research or create a new recipe for brewing and anything else that I am trying to do!',
    },
    {
      title: 'With one word: Perfect!!!',
      content:
        "From a professional developer's standpoint, this site is a treasure trove of information. Highly recommend it to anyone working in AI development.",
    },
    {
      title: 'Perfect AI Tool',
      content:
        "It's a great time-saver to access all the latest AI models through a single, simple interface. There's no need to switch between tools.",
    },
    {
      title: 'Nice Work Guys!',
      content: `I was hesitant at first about the ${appConfig.name}'s sleek interface allows me to access multiple AI models in one interface. It's convenient and affordable. I've never seen any other app offering this before.`,
    },
    {
      title: `${appConfig.name} is great.`,
      content:
        'All features that were missing have now been added! The speed has also significantly increased. I am extremely impressed with how quickly this service/developers rectified the highlighted issues, as well as improved the service itself.',
    },
    {
      title: 'Crazy Good!!!',
      content: `${appConfig.name} is working great for me. No crashes.. Nothing bad to say about this AI website`,
    },
    {
      title: 'Love it. Very good!!',
      content: `Like ${appConfig.name} from my bottom of my heart. It’s simply ease my jobs by providing wonderful tips and sample mails. Good job AI team.`,
    },
    {
      title: 'Smarter than me',
      content: `So far so good, it's been a while now while using ${appConfig.name}, this AI is beyond my intelligent. I'm glad that you're giving us future promise`,
    },
    {
      title: 'Highly Recommended',
      content:
        'Love the way it responds to my question and it’s very easy to use will recommend it to others how it help me to organize my work and optimize the work I’m doing',
    },
    {
      title: 'Very Good Features',
      content: `Exploring this ${appConfig.name} website, I was immediately impressed by its forward-thinking features. The synergy between ChatGPT-4 and technologies like Gemini and Palm 2 creates a user experience that's both advanced and intuitive.`,
    },
    {
      title: 'Very Cool',
      content:
        'I have found a new aid in all I do on my phone and in all my communications with my associates friends and family What a great tool',
    },
  ];

  const columnSize = Math.ceil(comments.length / 3);
  const columns = Array.from({ length: 3 }, (_, i) =>
    comments.slice(i * columnSize, (i + 1) * columnSize),
  );

  return (
    <div>
      <div className={styles['landing-comments-animation']}>
        {columns.map((column, columnIndex) => (
          <div
            key={columnIndex}
            className={cn(
              styles['landing-comments-animation__comment-column'],
              styles[`column-${columnIndex}`],
            )}
          >
            {[...column, ...column].map((comment, commentIndex) => (
              <div
                className={styles['landing-comments-animation__card']}
                key={commentIndex}
              >
                <div className={styles.stars}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <FontAwesomeIcon color="#ffc568" key={star} icon={faStar} />
                  ))}
                </div>
                <div className={styles.title}>{comment.title}</div>
                <div className={styles.content}>{comment.content}</div>
              </div>
            ))}
          </div>
        ))}
        <div className={styles['landing-comments-animation__main-title']}>
          <div>
            <Trusted />
          </div>
          <div>Trusted by Millions</div>
        </div>
        <div className={styles['landing-comments-animation__footer']}>
          <HappyUsers />
        </div>
      </div>
      <div className={styles.available}>
        <div className={styles.available__divider}></div>
        <div className={styles.available__title}>Available on</div>
        <div>
          <Available />
        </div>
      </div>
    </div>
  );
}
