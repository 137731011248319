import Lottie from 'lottie-light-react';
import type { ReactElement } from 'react';

import checkAnimation from '../../assets/animations/check.json';

export function CheckAnimation(): ReactElement {
  return (
    <Lottie
      animationData={checkAnimation}
      loop={false}
      style={{
        width: 'var(--size-24)',
      }}
    />
  );
}
