import { api } from '@/providers/api';

interface ApiResponse {
  data: {
    success: boolean;
    time: string;
  };
}

export const remove = api.injectEndpoints({
  endpoints: (builder) => ({
    removeUser: builder.mutation<ApiResponse['data'], string>({
      query: (id) => ({
        url: `/v1/users/${id}/delete`,
        method: 'POST',
        headers: {
          x_pr: 'false',
        },
      }),
      transformResponse: (response: ApiResponse) => response.data,
    }),
  }),
});

export const { useRemoveUserMutation } = remove;
