import { type ReactElement } from 'react';

import { Logo } from '@/components';

import styles from './AuthHeader.module.scss';

export function AuthHeader(): ReactElement {
  return (
    <header className={styles.header}>
      <Logo size="large" />
    </header>
  );
}
