import cn from 'clsx';
import { type ReactElement } from 'react';

import styles from './PlanBadge.module.scss';

export function PlanBadge({
  plan,
  className,
}: {
  plan: 'free' | 'pro';
  className?: string;
}): ReactElement {
  const plans = {
    free: {
      text: 'FREE',
    },
    pro: {
      text: 'PRO',
    },
  };

  return (
    <span
      className={cn(
        styles['plan-badge'],
        styles[`plan-badge--plan-${plan}`],
        className,
      )}
    >
      {plans[plan].text}
    </span>
  );
}
