import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Arrow from '@/assets/arrow-left.svg?react';
import Logo from '@/assets/logo-circle.svg?react';
import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import type { Pricing } from '../../hooks/callback-handler';
import styles from './PaymentInformation.module.scss';

export function PaymentInformation({
  pricing,
  interval,
}: {
  pricing: Pricing;
  interval: string | undefined;
}): ReactNode {
  const path = usePathType();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.link}>
          <Link className={styles.link} to={`/${path}/plan`}>
            <Arrow className={styles.arrow} />
            <Logo className={styles.logo} />
            <span className={styles.span}>{appConfig.name}</span>
          </Link>
        </div>
        <div>
          <h2 className={styles.title}>{appConfig.name} Pro</h2>
        </div>
        <div>
          <div className={styles.price}>
            <h1>{pricing?.total}</h1>
            <p className={styles.table__text}>per {interval}</p>
          </div>
          <p className={styles.description}>
            Unlock all {appConfig.name} premium features.
          </p>
          <p className={styles.subdescription}>
            All prices in {pricing?.currency}.
          </p>
        </div>
        <div className={styles.table}>
          <div className={styles.table__content}>
            <p className={styles.table__text}>Subtotal</p>
            <h4>{pricing.subtotal}</h4>
          </div>
          <div>
            <hr className={styles.table__content} />
          </div>
          <div className={styles.table__content}>
            <p className={styles.table__text}>Taxes</p>
            {pricing.tax != null ? (
              <p className={styles.next}>{pricing.tax}</p>
            ) : (
              <p className={styles.next}>Calculated at next step</p>
            )}
          </div>
          <div>
            <hr className={styles.table__content} />
          </div>
          <div className={styles.table__content}>
            <h4>Total price</h4>
            <h4>{pricing.total}</h4>
          </div>
          <div>
            <hr className={styles.table__content} />
          </div>
        </div>
      </div>
    </div>
  );
}
