import {
  type DatabaseReference,
  ref as databaseRef,
  set as databaseSet,
  push as pushDatabase,
  serverTimestamp,
} from 'firebase/database';

import { database } from '@/providers/firebase';
import { store } from '@/providers/store';

import { type ConversationItemType } from '../types/conversation';

export function createConversationRef(): DatabaseReference | null {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return null;
  }

  const chatsRef = databaseRef(database, `${user.uid}/chats`);
  const newChatRef = pushDatabase(chatsRef);

  return newChatRef;
}

export async function pushConversation({
  conversationRef,
}: {
  conversationRef: DatabaseReference;
}): Promise<void> {
  const timestamp = serverTimestamp();

  const newConversation: Omit<
    ConversationItemType,
    'id' | 'createdAt' | 'updatedAt'
  > & {
    createdAt: object;
    updatedAt: object;
  } = {
    title: 'New Chat',
    createdAt: timestamp,
    updatedAt: timestamp,
  };

  await databaseSet(conversationRef, newConversation);
}
