import { type Paddle, initializePaddle } from '@paddle/paddle-js';
import {
  type ReactElement,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface PaddleContextType {
  paddleInstance: Paddle | null;
}

const PaddleContext = createContext<PaddleContextType | null>(null);

export function PaddleProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [paddleInstance, setPaddleInstance] = useState<Paddle | null>(null);

  useEffect(() => {
    void initializePaddle({
      environment: import.meta.env.VITE_PADDLE_ENVIRONMENT,
      token: import.meta.env.VITE_PADDLE_CLIENT_TOKEN,
    }).then((paddle: Paddle | undefined) => {
      if (paddle !== undefined) {
        setPaddleInstance(paddle);
      }
    });
  }, []);

  return (
    <PaddleContext.Provider value={{ paddleInstance }}>
      {children}
    </PaddleContext.Provider>
  );
}

export function usePaddle(): PaddleContextType {
  return useContext(PaddleContext) as PaddleContextType;
}
