import { applyActionCode, getIdToken } from 'firebase/auth';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import { auth as firebaseAuth } from '@/providers/firebase';

import type { HandlerProps } from '../ActionHandler';

type VerifyStatus = 'loading' | 'success' | 'error';

export function VerifyEmail({ oobCode }: HandlerProps): ReactElement {
  const [status, setStatus] = useState<VerifyStatus>('loading');
  const navigate = useNavigate();
  const { syncUserState } = useAuth();
  const applyCode = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (applyCode.current) return;

    async function handleVerification(): Promise<void> {
      applyCode.current = true;
      try {
        await applyActionCode(firebaseAuth, oobCode);
        if (firebaseAuth.currentUser === null) {
          navigate('/');
        } else {
          await getIdToken(firebaseAuth.currentUser, true);
          await firebaseAuth.currentUser.reload();

          syncUserState(firebaseAuth.currentUser);

          setStatus('success');
          navigate('/');
        }
      } catch {
        setStatus('error');
      }
    }

    void handleVerification();
  }, [oobCode, navigate, dispatch, syncUserState]);

  switch (status) {
    case 'loading':
      return <p>Loading...</p>;
    case 'success':
      return <p>Email verified!</p>;
    case 'error':
      return (
        <p>
          Your request to reset your password has expired or the link has
          already been used
        </p>
      );
    default:
      return <Navigate to="/" replace />;
  }
}
