import { type MessageItemType, type MessageListType } from '../types/message';

interface SetActionType {
  type: 'set';
  payload: MessageItemType;
}

interface RemoveActionType {
  type: 'remove';
  payload: MessageItemType['id'];
}

interface AddChunkActionType {
  type: 'add-chunk';
  payload: {
    id: MessageItemType['id'];
    chunk: string;
  };
}

interface ResetActionType {
  type: 'reset';
}

type ActionType =
  | SetActionType
  | RemoveActionType
  | AddChunkActionType
  | ResetActionType;

function setMessage(
  state: MessageListType,
  action: SetActionType,
): MessageListType {
  const itemIds = state.map(({ id }) => id);
  const itemId = action.payload.id;

  // Update if it exists.
  if (itemIds.includes(itemId)) {
    return state.map((currentItem) =>
      currentItem.id === itemId ? action.payload : currentItem,
    );
  }

  // Or add it to the state.
  return [...state, action.payload];
}

function addChunk(
  state: MessageListType,
  action: AddChunkActionType,
): MessageListType {
  const { id, chunk } = action.payload;

  return state.map((currentMessage) => {
    if (currentMessage.id === id) {
      const content = currentMessage.content ?? '';

      return {
        ...currentMessage,
        content: content + chunk,
        error: undefined,
      };
    }

    return currentMessage;
  });
}

export function messagesReducer(
  state: MessageListType,
  action: ActionType,
): MessageListType {
  switch (action.type) {
    case 'set':
      return setMessage(state, action);

    case 'remove':
      return state.filter((currentItem) => currentItem.id !== action.payload);

    case 'add-chunk':
      return addChunk(state, action);

    case 'reset':
      return [];
  }
}
