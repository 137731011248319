import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type FocusEventHandler,
  type ReactElement,
  useEffect,
  useRef,
} from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';
import { z } from 'zod';

import { updateConversationTitle } from '@/features/conversations';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { ConversationActionButton } from '../ConversationActionButton/ConversationActionButton';
import styles from './ConversationTitleEditForm.module.scss';

interface SaveTitleFormType {
  title: string;
}

export function ConversationTitleEditForm({
  id,
  title,
  onCancel,
}: {
  id: string;
  title: string;
  onCancel: () => void;
}): ReactElement {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { register, handleSubmit, formState } = useForm<SaveTitleFormType>({
    defaultValues: {
      title,
    },
    resolver: zodResolver(
      z.object({
        title: z.string().trim().min(1, "Title can't be empty."),
      }),
    ),
  });

  const handleSaveTitle: SubmitHandler<SaveTitleFormType> = async ({
    title,
  }) => {
    await updateConversationTitle({
      title,
      conversationId: id,
    });

    onCancel();
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    const element = event.currentTarget;

    element.scrollTo({
      left: element.scrollWidth,
    });
  };

  const { ref: registerRef, ...registerProps } = register('title');

  return (
    // Should trigger onBlur when focus is not within the form.
    // Although this is a non-interative element, we can't handle onBlur on the input.
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      onSubmit={handlePromiseEvent(handleSubmit(handleSaveTitle))}
      className={styles['conversation-title-edit-form']}
      onBlur={(event) => {
        // If focus is still in the form, do nothing.
        if (event.currentTarget.contains(event.relatedTarget)) {
          return;
        }

        onCancel();
      }}
    >
      <input
        ref={mergeRefs([registerRef, inputRef])}
        className={styles['conversation-title-edit-form__input']}
        type="text"
        maxLength={1024}
        onFocus={handleFocus}
        onKeyUp={(event) => {
          if (event.key === 'Escape') {
            onCancel();
          }
        }}
        {...registerProps}
      />

      <ConversationActionButton
        icon={faCheck}
        type="submit"
        disabled={!formState.isValid}
      />
      <ConversationActionButton icon={faTimes} onClick={onCancel} />
    </form>
  );
}
