import type { ApiPaymentProductType } from '@/features/payment';

export const calculatePriceDiffBetweenMonthlyAndYearly = ({
  monthlyPrice,
  yearlyPrice,
}: {
  monthlyPrice: number;
  yearlyPrice: number;
}): number => {
  const totalMonthlyPriceInYear = monthlyPrice * 12;
  const difference = totalMonthlyPriceInYear - yearlyPrice;

  return difference;
};

export const calculateSavingsPercentageBetweenPlans = ({
  plans,
}: {
  plans: ApiPaymentProductType[];
}): Array<{
  planId: string;
  savingsPercentage: number;
  priceDifference: number;
}> => {
  const multiplier = {
    day: 1,
    week: 7,
    month: 30,
    year: 365,
  };

  // Sort plans by duration in ascending order
  const sortedPlans = plans.sort((a, b) => {
    const aDuration =
      a.billingCycle.frequency * multiplier[a.billingCycle.interval];
    const bDuration =
      b.billingCycle.frequency * multiplier[b.billingCycle.interval];
    return aDuration - bDuration;
  });

  // Calculate savings percentage over the shortest term plan
  const savings = sortedPlans.map((plan, index) => {
    if (index === 0) {
      return {
        planId: plan.id,
        savingsPercentage: 0,
        priceDifference: 0,
      };
    } else {
      const shortestPlan = sortedPlans[0];
      const shortestPlanPrice = parseFloat(shortestPlan.unitPrice.amount) / 100;
      const planPrice = parseFloat(plan.unitPrice.amount) / 100;
      const planDuration =
        plan.billingCycle.frequency * multiplier[plan.billingCycle.interval];
      const shortestPlanDuration =
        shortestPlan.billingCycle.frequency *
        multiplier[shortestPlan.billingCycle.interval];

      const savingsPercentage = Math.round(
        (1 -
          planPrice /
            ((shortestPlanPrice * planDuration) / shortestPlanDuration)) *
          100,
      );

      return {
        planId: plan.id,
        savingsPercentage,
        priceDifference:
          (shortestPlanPrice * planDuration) / shortestPlanDuration - planPrice,
      };
    }
  });

  return savings;
};
