import {
  type DataSnapshot,
  ref as databaseRef,
  set as databaseSet,
  onValue,
} from 'firebase/database';
import { useEffect, useMemo, useState } from 'react';
import { useListVals } from 'react-firebase-hooks/database';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { database } from '@/providers/firebase';

import {
  ConversationItemSchema,
  type ConversationItemType,
  type ConversationModel,
  ConversationStatusKeySchema,
  type ConversationStatusKeyType,
  supportedModels,
} from '../types/conversation';

const validateConversations = (
  conversations: unknown,
): ConversationItemType[] => {
  const parsedConversations = (
    conversations as ConversationItemType[] | []
  ).filter((conversation) => {
    const parsedConversation = ConversationItemSchema.safeParse(conversation);
    return parsedConversation.success;
  });

  // fallback for webSearch
  const modifiedConversations = parsedConversations.map((conversation) => ({
    ...conversation,
    engine:
      conversation.engine === 'web-browsing'
        ? 'webSearch'
        : conversation.engine,
  }));

  return modifiedConversations;
};

const validateConversation = (
  conversation: unknown,
): ConversationItemType | null => {
  const parsedConversation = ConversationItemSchema.safeParse(conversation);

  if (!parsedConversation.success) {
    return null;
  }

  // fallback for webSearch
  if (parsedConversation.data.engine === 'web-browsing') {
    parsedConversation.data.engine = 'webSearch';
  }

  return parsedConversation.data;
};

export function useConversations(): {
  data: ConversationItemType[];
  loading: boolean;
  error: Error | undefined;
} {
  const auth = useAppSelector(selectAuth);

  const [value, loading, error] = useListVals(
    databaseRef(
      database,
      auth.user !== null ? `${auth.user.uid}/chats` : undefined,
    ),
    {
      keyField: 'id',
    },
  );

  const transformedConversations = useMemo(
    () => validateConversations(value),
    [value],
  );

  // Web app don't support conversations other than "assistant" yet.
  const assistantConversations = transformedConversations.filter(
    ({ type }) => type === undefined || type === 'assistant',
  );

  // Exclude conversations that don't have a supported model.
  const supportedConversations = assistantConversations.filter(
    ({ engine }) =>
      engine === undefined ||
      supportedModels.includes(engine as ConversationModel),
  );

  return {
    data: supportedConversations,
    loading,
    error,
  };
}

export function useConversation(id?: ConversationItemType['id']): {
  data?: ConversationItemType | null;
  setConversationStatus: (
    statusName: ConversationStatusKeyType,
    newValue: boolean,
  ) => Promise<void>;
} {
  const auth = useAppSelector(selectAuth);

  // `undefined` means conversation id doesn't exist.
  // `null` means conversation data is not valid or it doesn't exist.
  const [value, setValue] = useState<ConversationItemType | null | undefined>();

  useEffect(() => {
    if (id === undefined || auth.user === null) {
      setValue(undefined);
      return;
    }

    const handleOnValue = (snapshot: DataSnapshot): void => {
      if (!snapshot.exists() || snapshot.key === null) {
        setValue(null);
        return;
      }

      const transformedConversation = validateConversation({
        id: snapshot.key,
        ...snapshot.val(),
      });

      setValue(transformedConversation);
    };

    const chatRef = databaseRef(database, `${auth.user.uid}/chats/${id}`);

    const unsubscribeOnValue = onValue(chatRef, handleOnValue);

    return () => {
      unsubscribeOnValue();
    };
  }, [id, auth.user]);

  async function setConversationStatus(
    statusName: ConversationStatusKeyType,
    newValue: boolean,
  ): Promise<void> {
    const statusCheck = ConversationStatusKeySchema.safeParse(statusName);
    if (auth.user === null || !statusCheck.success) {
      return;
    }

    const firstSuccessfulResponseReceivedRef = databaseRef(
      database,
      `${auth.user.uid}/chats/${id}/${statusName}`,
    );

    await databaseSet(firstSuccessfulResponseReceivedRef, newValue);
  }

  return {
    data: value,
    setConversationStatus,
  };
}
