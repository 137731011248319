import { useEffect, useState } from 'react';

import { type ConversationModel } from '@/features/conversations';

import type { SelectedFilesType } from '../services/file-upload';
import { models } from '../types/models';

const useModelSelection = (
  selectedModel: ConversationModel,
  selectedFiles: SelectedFilesType,
): ConversationModel => {
  const [updatedModel, setUpdatedModel] =
    useState<ConversationModel>(selectedModel);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      const getUpdatedModel = (
        currentModel: ConversationModel,
        files: SelectedFilesType,
      ): ConversationModel => {
        if (
          files.some((file) => file?.mimeType?.includes('image')) &&
          (models[selectedModel].modelRedirectSupport ?? false)
        ) {
          return 'gpt-4o';
        } else if (models[selectedModel].modelRedirectSupport ?? false) {
          return 'superbot';
        }
        return currentModel;
      };
      setUpdatedModel(getUpdatedModel(selectedModel, selectedFiles));
    }
  }, [selectedModel, selectedFiles]);

  return updatedModel;
};

export default useModelSelection;
