import { CheckoutEventsTimePeriodInterval } from '@paddle/paddle-js';

import type { BillingCycleType } from '../types/billing-cycle';

export function getInterval(cycle: BillingCycleType): string {
  switch (cycle.interval) {
    case CheckoutEventsTimePeriodInterval.DAY:
      if (cycle.frequency > 1) {
        return `Every ${cycle.frequency} days`;
      }
      return 'Per day';
    case CheckoutEventsTimePeriodInterval.WEEK:
      if (cycle.frequency > 1) {
        return `Every ${cycle.frequency} weeks`;
      }
      return 'Per week';
    case CheckoutEventsTimePeriodInterval.MONTH:
      if (cycle.frequency > 1) {
        return `Every ${cycle.frequency} months`;
      }
      return 'Per month';
    case CheckoutEventsTimePeriodInterval.YEAR:
      return 'Per year';
    default:
      return '';
  }
}
