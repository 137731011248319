import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { PasswordlessLogin } from '../containers/PasswordlessLogin/PasswordlessLogin';

export function PasswordlessLoginRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Login - {appConfig.name}</title>
      </Helmet>

      <PasswordlessLogin />
    </>
  );
}
