import { paymentApi } from '@/providers/api';

import type {
  TransactionStatusType,
  TransactionType,
} from '../types/transaction';

interface TransactionPayload {
  statusList?: TransactionStatusType[];
}

export interface TransactionResponse {
  success: boolean;
  data: TransactionType;
  time: string;
}

interface TransactionListResponse {
  success: boolean;
  data: Array<TransactionResponse['data']>;
  time: string;
}

interface CreateTransactionPayload {
  priceId: string;
  trackingId: string | null;
}

interface GetTransactionPayload {
  transactionId: string;
}

interface TransactionInvoiceResponse {
  success: boolean;
  data: string;
  time: string;
}

const transactionApi = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    createTransaction: builder.mutation<
      TransactionResponse['data'],
      CreateTransactionPayload
    >({
      query: (payload) => ({
        url: `/transaction/create`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: TransactionResponse) => response.data,
    }),
    getTransaction: builder.query<
      TransactionResponse['data'],
      GetTransactionPayload
    >({
      query: (payload) => ({
        url: `/transaction/get`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response: TransactionResponse) => response.data,
    }),
    getTransactionList: builder.query<
      TransactionListResponse['data'],
      TransactionPayload & { refetch?: boolean }
    >({
      query: ({ statusList }) => ({
        url: `/transaction/all`,
        method: 'POST',
        body: { statusList: statusList ?? ['completed'] },
      }),
      transformResponse: (response: TransactionListResponse) => response.data,
    }),
    getTransactionInvoice: builder.mutation<
      TransactionInvoiceResponse['data'],
      GetTransactionPayload
    >({
      query: ({ transactionId }) => ({
        url: `/transaction/invoice`,
        method: 'POST',
        body: { transactionId },
      }),
      transformResponse: (response: TransactionInvoiceResponse) =>
        response.data,
    }),
    changePayment: builder.query<TransactionResponse['data'], string>({
      query: () => ({
        url: `/transaction/change-payment`,
        method: 'GET',
      }),
      transformResponse: (response: TransactionResponse) => response.data,
    }),
  }),
});

export const {
  useCreateTransactionMutation,
  useGetTransactionQuery,
  useGetTransactionListQuery,
  useGetTransactionInvoiceMutation,
  useChangePaymentQuery,
} = transactionApi;
