import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, eventQuery, paymentQuery } from './fetch';

const isDevelopment = import.meta.env.DEV;

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
  tagTypes: [],
  keepUnusedDataFor: isDevelopment ? 0 : 10,
  refetchOnMountOrArgChange: isDevelopment,
});

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: eventQuery,
  endpoints: () => ({}),
  tagTypes: [],
  keepUnusedDataFor: isDevelopment ? 0 : 10,
  refetchOnMountOrArgChange: isDevelopment,
});

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: paymentQuery,
  endpoints: () => ({}),
  tagTypes: [],
  keepUnusedDataFor: isDevelopment ? 0 : 10,
  refetchOnMountOrArgChange: isDevelopment,
});
