import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import cn from 'clsx';
import type { ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './PhotoViewToolbar.module.scss';

export function PhotoViewToolbar({
  onClickDownloadImage,
}: {
  onClickDownloadImage: () => void;
}): ReactElement {
  return (
    <Icon
      icon={faArrowDownToLine}
      className={cn('PhotoView-Slider__toolbarIcon', styles['download-icon'])}
      onClick={onClickDownloadImage}
    />
  );
}
