export const retryPromise = async ({
  request,
  retryLimit = 3,
  retryInterval = 1000,
}: {
  request: () => Promise<void>;
  retryLimit?: number;
  retryInterval?: number;
}): Promise<void> => {
  let retryCount = 0;
  while (retryCount < retryLimit) {
    try {
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      await request();
      return; // Resolve the promise if successful
    } catch (error) {
      retryCount++;
      retryInterval *= 2; // Exponential backoff
      if (retryCount === retryLimit) {
        throw error; // Rethrow the error if retry limit is reached
      }
    }
  }
};
