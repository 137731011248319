import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { GetStarted } from '../containers/GetStarted/GetStarted';

export function GetStartedRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>{appConfig.name} - AI Chatbot</title>
      </Helmet>

      <GetStarted />
    </>
  );
}
