import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import { LandingWrapper } from '../../components/LandingWrapper/LandingWrapper';
import { LandingPlan } from '../../containers/LandingPlan/LandingPlan';
import { LandingPlanWhite } from '../../containers/LandingPlanWhite/LandingPlanWhite';

export function LandingPlanRoute(): ReactElement {
  const path = usePathType();

  let renderComponent;
  if (
    path === 'landing' ||
    path === 'landing-gpt4o' ||
    path === 'landing-image' ||
    path === 'landing-3' ||
    path === 'landing-pdf' ||
    path === 'landing-aiwriting'
  ) {
    renderComponent = <LandingPlan />;
  } else {
    renderComponent = (
      <LandingWrapper VariantA={LandingPlan} VariantB={LandingPlanWhite} />
    );
  }

  return (
    <>
      <Helmet>
        <title>Choose Your Plan - {appConfig.name}</title>
      </Helmet>
      {renderComponent}
    </>
  );
}
