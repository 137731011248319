import Lottie from 'lottie-light-react';
import type { ReactElement } from 'react';

import loadingAnimation from '../../assets/animations/Generate.json';

export function GenerationLoader(): ReactElement {
  return (
    <Lottie
      animationData={loadingAnimation}
      loop
      style={{
        width: 'var(--size-80)',
      }}
    />
  );
}
