import { type ReactElement, useState } from 'react';

import { Logo } from '@/components';
import { LandingWhiteLayout } from '@/layouts/LandingWhiteLayout/LandingWhiteLayout';
import { appConfig } from '@/providers/config';

import { LandingWhiteCommentsAnimation } from '../../components/LandingWhiteComments/LandingWhiteComments';
import { LandingWhiteLoginForm } from '../../components/LandingWhiteLoginForm/LandingWhiteLoginForm';
import { AppleLogin } from '../../components/SocialLogin/AppleLogin/AppleLogin';
import { GoogleLogin } from '../../components/SocialLogin/GoogleLogin/GoogleLogin';
import styles from './LandingLogin.module.scss';

interface StepType {
  title: string;
  description?: string;
  content: ReactElement;
}

export function LandingWhiteLogin(): ReactElement {
  // const handleLoginWithEmail = (): void => {
  //   setStep(steps[1]);
  // };

  const steps: StepType[] = [
    {
      title: 'Join Millions of\n Happy Users',
      content: (
        <div className={styles['login-methods']}>
          <p className={styles['login-description']}>
            Sign in to your {appConfig.name} account
          </p>
          <div className={styles['login-buttons']}>
            <GoogleLogin
              className={styles['button-google']}
              iconClassName={styles['button-google-icon']}
              variant={'secondary'}
              source={'login'}
            />
            <AppleLogin
              className={styles['button-apple']}
              iconClassName={styles['button-apple-icon']}
              variant={'secondary'}
              source={'login'}
            />
            <div className={styles.line}>
              <hr className={styles.left__hr} />
              <span className={styles.or__text}>OR</span>
              <hr className={styles.right__hr} />
            </div>
            <div>
              <LandingWhiteLoginForm />
            </div>
          </div>
        </div>
      ),
    },
  ];
  const [step] = useState<StepType>(steps[0]);

  return (
    <LandingWhiteLayout
      leftChild={<LandingWhiteCommentsAnimation />}
      rightChild={
        <div className={styles.container}>
          <Logo className={styles.logo} size={'large'} />
          <div className={styles.content}>
            <h1 className={styles.title}>{step.title}</h1>
            {step.description !== undefined && (
              <p className={styles.description}>{step.description}</p>
            )}
            {step.content}
          </div>
        </div>
      }
    />
  );
}
