import cn from 'clsx';
import { type ReactElement, useMemo } from 'react';

import type { TransactionStatusType } from '../../types/transaction';
import styles from './TransactionStatusTag.module.scss';

interface TransactionStatusProps {
  status: TransactionStatusType;
}

export function TransactionStatusTag({
  status,
}: TransactionStatusProps): ReactElement {
  const statusLabel = useMemo(() => {
    return status
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }, [status]);

  return (
    <span className={cn(styles['status-tag'], styles[`status-tag--${status}`])}>
      {statusLabel}
    </span>
  );
}
