import { type ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { Dialog } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { getNames, selectAuth } from '@/features/auth';
import {
  type ConversationModel,
  defaultModel,
  useConversation,
} from '@/features/conversations';
import {
  GeminiSuperbotPopup,
  ProPlanWelcomeDialog,
  SuperbotPopup,
} from '@/features/plans';
import { useAppParams, useAppSelector, useWelcomePro } from '@/hooks';
import { appConfig } from '@/providers/config';

import { usePopupStatus } from '../../analytics/hooks/popup-status';
import { Chat } from '../containers/Chat/Chat';
import { SelectedModelProvider } from '../contexts/selected-model';
import { TypewriterAnimationProvider } from '../contexts/typewriter-animation';
import { useMessages } from '../services/list';
import { ResponseError, RetriableClientError } from '../types/errors';
import type { MessageItemType, MessageListType } from '../types/message';

function ChatWrapper(): ReactElement {
  const auth = useAppSelector(selectAuth);
  const params = useAppParams(
    z.object({
      id: z.string(),
    }),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [retriable, setRetriable] = useState(true);
  const [responseFailed, setResponseFailed] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  useEffect(() => {
    if (searchParams.has('amount') && searchParams.has('plan')) {
      const eventData: Record<string, string | null | undefined> = {
        amount: searchParams.get('amount'),
        plan: searchParams.get('plan'),
        currency: searchParams.get('currency') ?? 'USD',
        email: auth.user?.email,
        external_id: auth.user?.uid,
      };

      // Disabled because otherwise TypeScript (v5.2.2) doesn't know that
      // auth.user is not null.
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (auth.user !== null && auth.user.displayName !== null) {
        const userNames = getNames(auth.user.displayName);

        if (userNames === null) {
          return;
        }

        eventData.first_name = userNames.firstName;
        eventData.last_name = userNames.lastName;
      }

      if ('dataLayer' in window) {
        window.dataLayer.push(eventData);
      }

      logEvent('purchase_success', {
        amount: eventData.amount,
        plan: eventData.plan,
        currency: eventData.currency,
        external_id: eventData.external_id,
      });
    }
  }, [searchParams, auth.user?.email, auth.user?.uid, auth.user]);

  useEffect(() => {
    logEvent('lnd_chatpage', {
      user_id: auth.user?.uid,
    });
  }, [auth.user?.uid]);

  const conversationIdRef = useRef(params?.id ?? null);
  const stuckImagesSavedRef = useRef(false);

  const {
    messages,
    sendMessage,
    isSendingMessage,
    regenerateResponse,
    stopGenerating,
    isAnswering,
    resetMessages,
    isGeneratingImagePrompt,
    retryImageGeneration,
    saveStuckImages,
  } = useMessages({
    conversationId: params?.id,
    currentModel: searchParams.get('model') as ConversationModel,
  });
  const { data: conversation, setConversationStatus } = useConversation(
    params?.id,
  );

  useEffect(() => {
    // Reset messages if new chat is requested. This is used to reset the
    // messages when the user clicks on the "New Chat" button which is a link
    // to / route and it has a query param new-chat=true.
    if (searchParams.get('new-chat') === 'true' && params?.id === undefined) {
      searchParams.delete('new-chat');
      setSearchParams(searchParams, {
        replace: true,
      });
      stopGenerating();
      resetMessages();
    }
  }, [setSearchParams, params, searchParams, resetMessages, stopGenerating]);

  useEffect(() => {
    // Stop generating response if conversation id changed.
    if (
      (conversationIdRef.current !== null ||
        (conversationIdRef.current == null && params?.id !== undefined)) &&
      conversationIdRef.current !== params?.id
    ) {
      stopGenerating();

      stuckImagesSavedRef.current = false;
    }

    conversationIdRef.current = params?.id ?? null;
  }, [params, stopGenerating]);

  useEffect(() => {
    if (stuckImagesSavedRef.current) {
      return;
    }

    if (conversation?.engine === 'image-generator' && messages.length > 0) {
      saveStuckImages();
      stuckImagesSavedRef.current = true;
    }
  }, [conversation, messages, saveStuckImages]);

  // Redirect to root if conversation is not longer available.
  if (params !== null && conversation === null) {
    return <Navigate to="/" replace />;
  }

  const getCurrentConversationModel = (): ConversationModel | null => {
    if (conversation === null || conversation === undefined) {
      return null;
    }

    if (conversation?.engine === undefined) {
      return defaultModel;
    }

    // Filtered out unsupported values.
    return conversation.engine as ConversationModel;
  };

  const isConversationAvailable =
    conversation !== null && conversation !== undefined;
  const hasSentMessages = messages.length > 0;

  const isChatDisabled = !isConversationAvailable && hasSentMessages;
  const isChatLimitReached = conversation?.maxTokenReached === true;
  const isLastMessageError =
    messages.length > 0 && messages[messages.length - 1].error !== undefined;
  const limitReachedMessage: MessageItemType = {
    id: '',
    role: 'assistant',
    error: {
      status: 400,
      code: 6004,
    },
    updatedAt: null,
    createdAt: null,
  };
  const messagesWithInfo: MessageListType = [
    ...messages,
    ...(isChatLimitReached && !isLastMessageError ? [limitReachedMessage] : []),
  ];

  return (
    <>
      <Helmet>
        <title>
          {conversation?.title !== undefined
            ? `${conversation.title} - ${appConfig.name}`
            : `${appConfig.name} - AI Chatbot`}
        </title>
      </Helmet>

      <Chat
        sending={(isSendingMessage || sendingMessage) && !responseFailed}
        answering={isAnswering || isGeneratingImagePrompt}
        disabled={isChatDisabled}
        chatLimitReached={isChatLimitReached}
        messages={messagesWithInfo}
        onSendMessage={({ content, model, documents, update }) => {
          setSendingMessage(true);
          setResponseFailed(false);
          sendMessage({
            content,
            model:
              update === true ? model : getCurrentConversationModel() ?? model,
            ...(documents !== undefined && { documents }),
            ...(conversation?.captionHistoryId !== undefined && {
              conversationHistoryId: conversation.captionHistoryId,
            }),
          })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error(error);
              setResponseFailed(true);
              if (
                error instanceof ResponseError &&
                (error.code === 5004 || error.code === 6004)
              ) {
                void setConversationStatus('maxTokenReached', true);
              }

              if (
                error instanceof RetriableClientError ||
                (error instanceof ResponseError && error.retriable)
              ) {
                setRetriable(true);
              } else {
                setRetriable(false);
              }
            })
            .finally(() => {
              setSendingMessage(false);
            });
        }}
        regenerateDisabled={!isConversationAvailable || !retriable}
        onRegenerateResponse={(message?: string) => {
          if (message !== undefined) {
            setRetriable(true);
          }

          const model = getCurrentConversationModel();

          if (model === null && message === undefined) {
            setRetriable(false);
            return;
          }
          setResponseFailed(false);

          regenerateResponse({
            model: model ?? (searchParams.get('model') as ConversationModel),
            ...(conversation?.captionHistoryId !== undefined && {
              conversationHistoryId: conversation.captionHistoryId,
            }),
            message,
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            setResponseFailed(true);
            if (
              error instanceof RetriableClientError ||
              (error instanceof ResponseError && error.retriable)
            ) {
              setRetriable(true);
            } else {
              setRetriable(false);
            }
          });
        }}
        onStopGenerating={stopGenerating}
        onRetryImageGeneration={({ messageId, image, imageIndex }) => {
          void retryImageGeneration({ messageId, image, imageIndex });
        }}
      />
    </>
  );
}

export function ChatRoute(): ReactElement {
  const { isPaymentSuccessful, clearPaymentSuccessfulParam } = useWelcomePro();
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const [geminiPopupStatus, setGeminiPopupStatus] = usePopupStatus('gemini');
  const [superbotPopupStatus, setSuperbotPopupStatus] =
    usePopupStatus('superbot');

  const [searchParams] = useSearchParams();
  const model = searchParams.get('model');

  const handleClickAskMeAnything = (): void => {
    clearPaymentSuccessfulParam();

    // Run at the end of the current event loop iteration.
    setTimeout(() => {
      const messageFieldInput = document.querySelector('#message-field-input');

      if (messageFieldInput !== null) {
        // Focus chat input.
        (messageFieldInput as HTMLInputElement).focus();
      }
    }, 0);
  };

  const handleClickPopupSeen = (model: string): void => {
    if (model === 'superbot') {
      void setSuperbotPopupStatus(false);
    } else {
      void setGeminiPopupStatus(false);
    }

    // Run at the end of the current event loop iteration.
    setTimeout(() => {
      const messageFieldInput = document.querySelector('#message-field-input');

      if (messageFieldInput !== null) {
        // Focus chat input.
        (messageFieldInput as HTMLInputElement).focus();
      }
    }, 0);
  };

  useEffect(() => {
    if (isPaymentSuccessful) {
      logBigQueryEvent('purchase_successful_popup');
      logEvent('purchase_successful_popup');
    }
  }, [isPaymentSuccessful, logBigQueryEvent]);

  return (
    <SelectedModelProvider>
      <TypewriterAnimationProvider>
        <ChatWrapper />
        <Dialog
          open={isPaymentSuccessful}
          onOpenChange={() => {
            clearPaymentSuccessfulParam();
          }}
        >
          <ProPlanWelcomeDialog
            onClickAskMeAnything={handleClickAskMeAnything}
          />
        </Dialog>

        {model === 'gemini' && (
          <Dialog
            open={geminiPopupStatus ?? false}
            onOpenChange={() => {
              void setGeminiPopupStatus(false);
            }}
          >
            <GeminiSuperbotPopup
              onClick={() => {
                handleClickPopupSeen('gemini');
              }}
            />
          </Dialog>
        )}
        {model === 'superbot' && (
          <Dialog
            open={superbotPopupStatus ?? false}
            onOpenChange={() => {
              void setSuperbotPopupStatus(false);
            }}
          >
            <SuperbotPopup
              onClick={() => {
                handleClickPopupSeen('superbot');
              }}
            />
          </Dialog>
        )}
      </TypewriterAnimationProvider>
    </SelectedModelProvider>
  );
}
