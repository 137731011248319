import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import styles from './NavLink.module.scss';

export function NavLink({
  href,
  children: title,
  onClick,
}: {
  href: string;
  children: string;
  onClick?: () => Promise<void> | void;
}): ReactElement {
  if (typeof href === 'string' && href.startsWith('#')) {
    return (
      <button
        className={styles['nav-link']}
        onClick={() => {
          const element = document.querySelector(href);
          element?.scrollIntoView({ behavior: 'smooth' });
          void onClick?.();
        }}
      >
        {title}
      </button>
    );
  }

  return (
    <Link
      to={href}
      className={styles['nav-link']}
      onClick={() => {
        void onClick?.();
      }}
    >
      {title}
    </Link>
  );
}
