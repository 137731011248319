import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import { ElementType } from 'domelementtype';
import hljs from 'highlight.js';
import parseHtmlToReact, {
  type DOMNode,
  Element,
  type HTMLReactParserOptions,
  domToReact,
} from 'html-react-parser';
import { Marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import { type ReactElement, type ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogTrigger, Icon } from '@/components';
import { PaywallDialog } from '@/features/account';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { GenerationLoader } from '@/features/generation';

import { DocumentList } from '../../../../../components/DocumentList/DocumentList';
import { useSelectedModel } from '../../../../../contexts/selected-model';
import { useTypewriterAnimation } from '../../../../../contexts/typewriter-animation';
import {
  ImageState,
  type MessageImageType,
  type MessageItemType,
} from '../../../../../types/message';
import { getErrorDetail } from '../../../../../utils/get-error-detail';
import { CodeBlock } from './CodeBlock/CodeBlock';
import { ImageList } from './ImageList/ImageList';
import WebMessage from './Message/WebMessage';
import styles from './MessageContent.module.scss';

const getParsedReactNode = (content: string | null): ReactNode => {
  if (content === null) {
    return;
  }

  const marked = new Marked(
    markedHighlight({
      async: false,
      langPrefix: 'language-',
      highlight(code, lang) {
        const language =
          hljs.getLanguage(lang) !== undefined ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    }),
  );

  const parsedContent = marked.parse(content);

  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (
        domNode instanceof Element &&
        domNode.type === ElementType.Tag &&
        domNode.name === 'pre'
      ) {
        let language = 'text';

        const codeEl = domNode.children[0];

        if (
          codeEl instanceof Element &&
          typeof codeEl?.attribs?.class === 'string'
        ) {
          // "language-" string comes from highlight.js.
          language = codeEl.attribs.class.replace('language-', '');
        }

        return (
          <CodeBlock language={language}>
            {domToReact(domNode.children as DOMNode[], options)}
          </CodeBlock>
        );
      }
    },
  };

  const parsedReactNode = parseHtmlToReact(parsedContent as string, options);

  return parsedReactNode;
};

export function MessageContent({
  id,
  role,
  content,
  loading = false,
  error,
  images,
  documents,
  onClickRetry,
  onClickOption,
}: Omit<MessageItemType, 'createdAt' | 'updatedAt'> & {
  loading?: boolean;
  onClickRetry: ({
    messageId,
    image,
    imageIndex,
  }: {
    messageId: string;
    image: MessageImageType;
    imageIndex: number;
    url?: string;
  }) => void;
  onClickOption: (option: string) => void;
}): ReactElement {
  const navigate = useNavigate();
  const { selectedModel } = useSelectedModel();
  const { animatingMessageId } = useTypewriterAnimation();

  const { logBigQueryEvent } = useLogBigQueryEvent();

  let errorDetail = null;
  let messageContent: ReactNode;
  let requiredPayment: boolean = false;
  let requiredNewChat: boolean = false;
  let showOptions: boolean = false;

  if (error?.code !== undefined) {
    errorDetail = getErrorDetail(error.code);
    requiredPayment = errorDetail?.action === 'payment-required' ?? false;
    requiredNewChat = errorDetail?.action === 'new-chat' ?? false;
    showOptions = errorDetail?.action === 'show-options' ?? false;

    if (errorDetail?.message !== undefined) {
      messageContent = errorDetail.message;
    } else {
      messageContent = 'Something went wrong.';
    }
  } else if (typeof content === 'string') {
    messageContent =
      role === 'assistant' ? getParsedReactNode(content) : content;
  }

  const animating = id === animatingMessageId && typeof content === 'string';

  const imageLoading =
    images !== undefined &&
    images.some((item) => item.state === ImageState.Loading);

  const [showDone, setShowDone] = useState(true);
  const [showMessageContent, setShowMessageContent] = useState(false);

  useEffect(() => {
    if (!loading && animating) {
      setShowDone(true);
      const timer = setTimeout(() => {
        setShowMessageContent(true);

        setShowDone(false);
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowMessageContent(true);

      setShowDone(false);
    }
  }, [loading, animating]);

  const websearchWithFallback = ['webSearch', 'web-browsing'];

  return (
    <>
      {documents !== undefined && documents.length > 0 && (
        <DocumentList
          documents={documents}
          className={styles['message-documents']}
        />
      )}

      <div
        className={cn(styles['message-content'], {
          [styles['message-content--markdown']]: role === 'assistant',
          [styles['message-content--blinking']]:
            animating && !websearchWithFallback.includes(selectedModel),
          [styles['message-content--loading']]:
            loading &&
            !websearchWithFallback.includes(selectedModel) &&
            loading &&
            selectedModel !== 'image-generator',
          [styles['message-content--error']]:
            error !== undefined && error.status !== 0,
        })}
      >
        {websearchWithFallback.includes(selectedModel) ? (
          <WebMessage
            loading={loading}
            showDone={showDone}
            animating={animating}
            showMessageContent={showMessageContent}
            messageContent={messageContent}
          />
        ) : (
          messageContent
        )}
      </div>
      {(imageLoading || loading) && selectedModel === 'image-generator' ? (
        <GenerationLoader />
      ) : (
        images !== undefined &&
        images.length > 0 && (
          <div className={styles['message-images']}>
            <ImageList
              images={images}
              onClickRetry={({ image, imageIndex }) => {
                onClickRetry({ messageId: id, image, imageIndex });
              }}
            />
          </div>
        )
      )}

      {error !== undefined && requiredPayment && (
        <div className={styles['message-actions']}>
          <Dialog
            onOpenChange={(open) => {
              if (open) {
                logBigQueryEvent('lnd_paywall');
                logEvent('lnd_paywall', {
                  paywallType: 'in_app',
                });
              }
            }}
          >
            <DialogTrigger asChild>
              <Button size="small" type="button" variant="primary">
                Upgrade to PRO
              </Button>
            </DialogTrigger>

            <PaywallDialog />
          </Dialog>
        </div>
      )}
      {error !== undefined && requiredNewChat && (
        <div className={styles['message-actions']}>
          <Button
            onClick={() => {
              navigate(`/?model=${selectedModel}`);
            }}
            size="small"
            type="button"
            variant="primary"
          >
            Start a new chat
          </Button>
        </div>
      )}
      {error !== undefined && showOptions && (
        <div className={styles['message-actions']}>
          {errorDetail?.options?.map((option, index) => (
            <Button
              onClick={() => {
                onClickOption(option);
              }}
              variant="white-outline"
              size="small"
              className={styles['message-actions__option']}
              key={index}
            >
              {option} <Icon icon={faChevronRight} />
            </Button>
          ))}
        </div>
      )}
    </>
  );
}
