import { faCheckCircle, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { Table } from '@radix-ui/themes';
import cn from 'clsx';
import { type ReactElement } from 'react';

import AdvancedToolsLogoSource from '@/assets/advanced-tools-logo.svg?react';
import GeminiLogoSource from '@/assets/gemini-logo.svg?react';
import Gpt4LogoSource from '@/assets/gpt4-logo-2.svg?react';
import ImageGenerationSource from '@/assets/image-generate-logo.svg?react';
import MetaLogo from '@/assets/meta-logo.svg?react';
import NoLimitsLogoSource from '@/assets/no-limits-logo.svg?react';
import WhatsAppLogoSource from '@/assets/whatsApp-logo.svg?react';
import { Icon } from '@/components';
import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import styles from './PaywallFeatures.module.scss';

interface Feature {
  icon: ReactElement;
  title: string;
  description: string;
  isFree: boolean;
  badge?: string;
}

const features: Feature[] = [
  {
    icon: <MetaLogo />,
    title: 'Access to ChatGPT',
    description: 'Chat with ChatGPT',
    isFree: true,
  },
  {
    icon: <Gpt4LogoSource />,
    title: 'Access to GPT-4o',
    description: 'More accurate and detailed answers',
    isFree: false,
  },
  {
    icon: <GeminiLogoSource />,
    title: 'Access to Google Gemini',
    description: "Google's latest AI model",
    isFree: false,
  },
  {
    icon: <AdvancedToolsLogoSource />,
    title: 'Unlock Advanced Tools',
    description: 'Access advanced AI tools such as Image Generator and Ask PDF',
    isFree: false,
  },
  {
    icon: <NoLimitsLogoSource />,
    title: 'No Limits',
    description: 'Unlimited dialogues with all AI models',
    isFree: false,
  },
  {
    icon: <WhatsAppLogoSource />,
    title: 'Chat in WhatsApp',
    description: `Use ${appConfig.name} within WhatsApp`,
    isFree: false,
    badge: 'Coming Soon',
  },
];

const NewFeature: Feature[] = [
  {
    icon: <MetaLogo />,
    title: 'Access to ChatGPT',
    description: 'Chat with ChatGPT',
    isFree: true,
  },
  {
    icon: <ImageGenerationSource />,
    title: 'Advanced AI Image Generation Tools',
    description: `Chat with ChatGPT`,
    isFree: false,
  },
  {
    icon: <Gpt4LogoSource />,
    title: 'Unlimited Access to GPT-4o',
    description: 'More accurate and detailed answers',
    isFree: false,
  },
  {
    icon: <GeminiLogoSource />,
    title: 'Access to Google Gemini',
    description: "Google's latest AI model",
    isFree: false,
  },
  {
    icon: <AdvancedToolsLogoSource />,
    title: 'Unlock Advanced Tools',
    description:
      'Access advanced AI tools such as \nImage Generator and Ask PDF',
    isFree: false,
  },
  {
    icon: <NoLimitsLogoSource />,
    title: 'No Limits',
    description: 'Unlimited dialogues with all AI models',
    isFree: false,
  },
];

const headerLabels = ['', 'FREE', 'PRO'];

export function PaywallFeatures({
  compact,
}: {
  compact?: boolean;
}): ReactElement {
  const path = usePathType();
  const isNewFeature = path === 'landing-image' || path === 'landing-aiwriting';

  return (
    <Table.Root
      size="1"
      className={cn(styles['paywall-features'], { [styles.compact]: compact })}
    >
      <Table.Header>
        <Table.Row>
          {headerLabels.map((label, index) => {
            return (
              <Table.ColumnHeaderCell
                key={index}
                justify="center"
                className={styles['feature-header-cell-container']}
              >
                <div className={styles['feature-header-cell']}>
                  <h4 className={styles['feature-header-label']}>{label}</h4>
                  <div className={styles['feature-header-bottom-line']} />
                </div>
              </Table.ColumnHeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {(isNewFeature ? NewFeature : features).map(
          ({ title, description, icon, isFree, badge }, index) => {
            return (
              <Table.Row
                className={styles['feature-row']}
                key={index}
                align="center"
              >
                <Table.RowHeaderCell>
                  <div className={styles['feature-content-area']}>
                    <div className={styles.icon}>{icon}</div>

                    <div className={styles['feature-content']}>
                      <div
                        className={styles['feature-content-title-container']}
                      >
                        <h3 className={styles['feature-title']}>{title}</h3>
                        {badge !== undefined && (
                          <span className={styles['feature-badge']}>
                            {badge}
                          </span>
                        )}
                      </div>
                      <p className={styles['feature-subtitle']}>
                        {description}
                      </p>
                    </div>
                  </div>
                </Table.RowHeaderCell>

                <Table.Cell justify="center">
                  <Icon
                    icon={isFree ? faCheckCircle : faCircleXmark}
                    className={cn(styles['feature-check-icon'], {
                      [styles['feature-check-icon--not-included-free']]:
                        !isFree,
                    })}
                  />
                </Table.Cell>

                <Table.Cell justify="center">
                  <Icon
                    icon={faCheckCircle}
                    className={styles['feature-check-icon']}
                  />
                </Table.Cell>
              </Table.Row>
            );
          },
        )}
      </Table.Body>
    </Table.Root>
  );
}
