import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement } from 'react';

import { Icon, Text } from '@/components';

import styles from './ExampleItem.module.scss';

export function ExampleItem({
  children,
  onClick,
}: {
  children: string;
  onClick: () => void;
}): ReactElement {
  return (
    <button type="button" className={styles['example-item']} onClick={onClick}>
      <Icon className={styles['example-item__icon']} icon={faArrowRight} />

      <Text
        as="p"
        size={{ initial: '2' }}
        weight="medium"
        className={styles['example-item__question']}
      >
        {children}
      </Text>
    </button>
  );
}
