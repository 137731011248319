import { Radio } from '@radix-ui/themes';
import cn from 'clsx';
import { type ReactElement, useId } from 'react';

import styles from './PlanItem.module.scss';

type PlanItemAdornmentType = 'most-popular' | 'none';
export interface PlanItemAdornment {
  type: PlanItemAdornmentType;
  value?: string;
  discount?: string;
}

export type PlanItemVariant = 'jade' | 'black';

export function PlanItem({
  lookupKey,
  name,
  price,
  pricePerDay,
  compact,
  adornment,
  checked,
  onSelectPlan,
  variant = 'jade',
}: {
  lookupKey: string;
  name: string;
  price: string;
  pricePerDay: string;
  compact?: boolean;
  adornment?: PlanItemAdornment;
  checked: boolean;
  onSelectPlan: () => void;
  variant?: PlanItemVariant;
}): ReactElement {
  const id = useId();

  return (
    <label
      htmlFor={id}
      className={cn(styles.plan, {
        [styles['plan--checked']]: checked,
        [styles['plan--most-popular']]: adornment?.type === 'most-popular',
        [styles.compact]: compact,
        [styles['variant--black']]: variant === 'black',
      })}
      aria-labelledby={`label-${id}`}
    >
      <Radio
        id={id}
        color={variant === 'black' ? 'gray' : 'jade'}
        name="lookup_key"
        value={lookupKey}
        checked={checked}
        required
        className={styles.plan__input}
        onChange={onSelectPlan}
      />

      <div className={styles.plan__content}>
        <div className={styles.title}>
          <span className={styles.title__title}>{name}</span>
          {adornment?.discount !== undefined && (
            <div className={styles.pricing__discount}>
              <span>{adornment?.discount}</span>
            </div>
          )}
          <span className={styles.title__price}>{price}</span>
        </div>

        <div className={styles.pricing}>
          <div className={styles.price}>
            <h4 className={styles.price__amount}>{pricePerDay}</h4>

            <span className={styles.price__label}>PER DAY</span>
          </div>
        </div>
      </div>
    </label>
  );
}
