import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import type { ReactElement } from 'react';

import { Icon } from '@/components';
import { usePaddle } from '@/features/payment';

export function PaymentForm(): ReactElement {
  const { paddleInstance } = usePaddle();

  return (
    <div className="checkout-container">
      {paddleInstance === null ? (
        <Icon icon={faSpinnerThird} spin size="xl" />
      ) : null}
    </div>
  );
}
