import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { ChoosePlan } from '../../containers/ChoosePlan/ChoosePlan';

export function ChoosePlanRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Choose Your Plan - {appConfig.name}</title>
      </Helmet>

      <ChoosePlan />
    </>
  );
}
