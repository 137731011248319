import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { MyPlan } from '../../containers/MyPlan/MyPlan';

export function MyPlanRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>My Plan - {appConfig.name}</title>
      </Helmet>

      <MyPlan />
    </>
  );
}
