import type { ReactElement } from 'react';
import { toast } from 'react-toastify';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
  Button,
} from '@/components';
import { useRemoveUserMutation } from '@/features/account';
import { logEvent } from '@/features/analytics';
import { logOut, selectAuth } from '@/features/auth';
import { useGetSubscriptionQuery } from '@/features/payment';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { auth as firebaseAuth } from '@/providers/firebase';

import {
  AccountSection,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionFooterNote,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionSpacer,
  AccountSectionTitle,
} from '../../../components/AccountSection/AccountSection';
import { AccountDeleted } from './PopupContent/AccountDeleted';
import { CancelSubscription } from './PopupContent/CancelSubscriptionContent';
import { DeleteContent } from './PopupContent/DeleteContent/DeleteContent';

export function DeleteAccount(): ReactElement {
  const { data: subscription } = useGetSubscriptionQuery('subscription');
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [removeUser, { isLoading, isSuccess }] = useRemoveUserMutation();

  const isActive =
    subscription?.scheduledChange === null &&
    (subscription?.status === 'active' || subscription?.status === 'past_due');

  const removeUsers = async (): Promise<void> => {
    if (auth.user?.uid != null) {
      const res = await removeUser(auth.user.uid);
      if ('error' in res) {
        toast.error('An error occurred while deleting your account.');
        return;
      }

      await LogOut();
    }
  };

  const LogOut = async (): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await firebaseAuth.signOut();
    dispatch(logOut());
  };

  return (
    <AccountSection>
      <AccountSectionHeader>
        <AccountSectionHeaderContainer>
          <AccountSectionTitle>Delete Account</AccountSectionTitle>
          <AccountSectionDescription>
            Deleting your account is permanent and cannot be undone.
          </AccountSectionDescription>
        </AccountSectionHeaderContainer>
      </AccountSectionHeader>

      <AccountSectionSpacer />

      <AccountSectionFooter variant="destructive">
        <AccountSectionFooterNote>
          This action is irreversible
        </AccountSectionFooterNote>

        <AccountSectionFooterActions>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="destructive"
                size="small"
                onClick={() => {
                  logEvent('btn_delete');
                }}
              >
                Delete
              </Button>
            </AlertDialogTrigger>

            <AlertDialogContent>
              {isActive ? (
                <CancelSubscription />
              ) : (
                !isSuccess && (
                  <DeleteContent
                    loading={isLoading}
                    onClick={() => {
                      void removeUsers();
                    }}
                  />
                )
              )}
              {isSuccess && (
                <AccountDeleted
                  onClick={() => {
                    void LogOut();
                  }}
                />
              )}
            </AlertDialogContent>
          </AlertDialog>
        </AccountSectionFooterActions>
      </AccountSectionFooter>
    </AccountSection>
  );
}
