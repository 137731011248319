import { type ReactElement } from 'react';

import { appConfig } from '@/providers/config';

import { NavLink } from './NavLink/NavLink';
import styles from './PublicFooter.module.scss';

const links = [
  {
    name: 'Support',
    href: `mailto:${appConfig.supportMail}?subject=${encodeURIComponent(
      appConfig.name,
    )}%20Landing%20Page`,
    value: 'support',
  },
  {
    name: 'Privacy Policy',
    href: `${appConfig.landing}/privacy`,
  },
  {
    name: 'Terms of Use',
    href: `${appConfig.landing}/terms`,
  },
  {
    name: 'Refund Policy',
    href: `${appConfig.landing}/refund-policy`,
  },
];

export function PublicFooter(): ReactElement {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <ul className={styles['nav-links']}>
          {links.map(({ name, href }) => (
            <NavLink key={name} name={name} href={href} />
          ))}
        </ul>

        <div className={styles['copyright-text']}>
          {`© ${new Date().getFullYear()} ${appConfig.name}`}
        </div>
      </div>
    </footer>
  );
}
