import * as Popover from '@radix-ui/react-popover';
import type { ReactElement } from 'react';

import { ProfilePicture } from '@/features/account';

import styles from './AccountPopover.module.scss';
import { AccountPopoverContent } from './AccountPopoverContent/AccountPopoverContent';

export function AccountPopover(): ReactElement {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button type="button" className={styles['account-popover-trigger']}>
          <ProfilePicture />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          sideOffset={8}
          align="end"
          className={styles['account-popover-content']}
        >
          <AccountPopoverContent />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
