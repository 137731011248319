import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import type { ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './Loader.module.scss';

export function Loader(): ReactElement {
  return (
    <div className={styles.loader}>
      <Icon icon={faSpinnerThird} spin size="2x" />
    </div>
  );
}
