export function getNames(
  fullName: string,
): { firstName: string; lastName: string | null } | null {
  const trimmedFullName = fullName.trim();

  if (trimmedFullName === '') {
    return null;
  }

  const names = trimmedFullName.split(' ');

  const firstName =
    names.length === 1 ? names[0] : names.slice(0, -1).join(' ');
  const lastName = names.length > 1 ? names[names.length - 1] : null;

  return { firstName, lastName };
}
