import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import type { ReactElement } from 'react';

import Information from '@/assets/information-icon.svg?react';
import { Icon } from '@/components';

import styles from './RedirectedMessage.module.scss';

export function RedirectedMessage({
  currentModel,
  newModel,
  onClick,
}: {
  currentModel: string;
  newModel: string;
  onClick: () => void;
}): ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.left__content}>
        <div>
          <Information />
        </div>
        <div className={styles.text__content}>
          <h1>Chat redirected</h1>
          <p>
            You will use this feature with <b>{newModel}</b> instead of{' '}
            <b>{currentModel}</b>
          </p>
        </div>
      </div>
      <Icon icon={faXmark} onClick={onClick} className={styles.icon} />
    </div>
  );
}
