import type { ReactNode } from 'react';

import { Button } from '@/components';

import {
  useGetSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from '../../services/subscription';

export function ReactivateSubscription(): ReactNode {
  const { refetch: subscriptionRefetch } =
    useGetSubscriptionQuery('subscription');
  const [
    updateSubscription,
    { isLoading: isUpdatingSubscription, isSuccess: isUpdateSuccess },
  ] = useUpdateSubscriptionMutation();

  if (isUpdateSuccess) {
    return null;
  }

  return (
    <Button
      loading={isUpdatingSubscription}
      onClick={() => {
        updateSubscription('update-subscription')
          .then(() => {
            void subscriptionRefetch();
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error while updating subscription', error);
          });
      }}
      variant="primary"
      size="large"
    >
      Re-Activate
    </Button>
  );
}
