import {
  type Dispatch,
  type ReactElement,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react';

interface DrawerDialogContextType {
  open: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
  preventAutoFocus: boolean;
  closeWithoutAutoFocus: () => void;
}

const DrawerDialogContext = createContext<DrawerDialogContextType | null>(null);

export function DrawerDialogProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [open, toggle] = useState(false);
  const preventAutoFocus = useRef(false);

  const closeWithoutAutoFocus = (): void => {
    preventAutoFocus.current = true;
    toggle(false);
  };

  return (
    <DrawerDialogContext.Provider
      value={{
        open,
        toggle,
        preventAutoFocus: preventAutoFocus.current,
        closeWithoutAutoFocus,
      }}
    >
      {children}
    </DrawerDialogContext.Provider>
  );
}

export function useDrawerDialog(): DrawerDialogContextType | null {
  return useContext(DrawerDialogContext);
}
