import Cookies from 'js-cookie';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getGoogleAnalyticsClientId } from '@/features/analytics';
import { waitForCookie } from '@/utils/wait-cookie';

interface ExperimentContextType {
  experiment: ExperimentType;
  anonymousId: string | undefined;
  experimentId: string | undefined;
  variationId: string | undefined;
  setExperimentDetails: (experimentKey: string, variationKey: string) => void;
}

type ExperimentType = 'current-flow' | 'alternative-flow' | 'landing-flow';

const ExperimentContext = createContext<ExperimentContextType | null>(null);

export function ExperimentProvider({
  children,
}: {
  children: ReactNode;
}): ReactNode {
  const [experiment, setExperiment] = useState<ExperimentType>();
  const [anonymousId, setAnonymousId] = useState<string>();
  const [experimentId, setExperimentId] = useState<string>();
  const [variationId, setVariationId] = useState<string>();

  useEffect(() => {
    waitForCookie(getGoogleAnalyticsClientId, {
      onFound: (cookieValue) => {
        setAnonymousId(cookieValue);
        const anonymousId = Cookies.get('anonymousId');
        if (anonymousId === undefined) {
          Cookies.set('anonymousId', cookieValue, { expires: 365 });
        }
      },
      onTimeout: () => {
        const anonymousId = Cookies.get('anonymousId');
        if (anonymousId === undefined) {
          const newAnonymousId = crypto.randomUUID();
          Cookies.set('anonymousId', newAnonymousId, { expires: 365 });
          setAnonymousId(newAnonymousId);
        } else {
          setAnonymousId(anonymousId);
        }
      },
    });
  }, []);

  const setExperimentDetails = (
    experimentKey: string,
    variationKey: string,
  ): void => {
    setExperimentId(experimentKey);
    setVariationId(variationKey);
  };

  if (experiment === undefined) {
    const experimentCookie = Cookies.get('experiment');
    if (experimentCookie === 'alternative-flow') {
      setExperiment('alternative-flow');
    } else if (experimentCookie === 'landing-flow') {
      setExperiment('landing-flow');
    } else {
      setExperiment('current-flow');
    }
    return null;
  }

  return (
    <ExperimentContext.Provider
      value={{
        experiment,
        anonymousId,
        experimentId,
        variationId,
        setExperimentDetails,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
}

export function useExperiment(): ExperimentContextType {
  return useContext(ExperimentContext) as ExperimentContextType;
}
