import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Text } from '@/components';
import { appConfig } from '@/providers/config';

import styles from './LegalText.module.scss';

export function LegalText(): ReactElement {
  return (
    <Text
      as="p"
      size={{
        initial: '0',
      }}
      weight="regular"
      className={styles['legal-text']}
    >
      By proceeding with the purchase of a plan, you agree to our{' '}
      <Link
        to={`${appConfig.landing}/terms`}
        target="_blank"
        className={styles['legal-text__link']}
      >
        Terms of Service
      </Link>
      ,{' '}
      <Link
        to={`${appConfig.landing}/privacy`}
        target="_blank"
        className={styles['legal-text__link']}
      >
        Privacy Policy
      </Link>{' '}
      and{' '}
      <Link
        to={`${appConfig.landing}/refund-policy`}
        target="_blank"
        className={styles['legal-text__link']}
      >
        Refund & Cancellation Policy
      </Link>
      .
    </Text>
  );
}
