import {
  type ReactElement,
  type ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

interface TypewriterAnimationContextType {
  animatingMessageId: string | null;
  setAnimatingMessageId: (animatingMessageId: string | null) => void;
}

const TypewriterAnimationContext =
  createContext<TypewriterAnimationContextType | null>(null);

export function TypewriterAnimationProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [animatingMessageId, setAnimatingMessageId] = useState<string | null>(
    null,
  );

  return (
    <TypewriterAnimationContext.Provider
      value={{ animatingMessageId, setAnimatingMessageId }}
    >
      {children}
    </TypewriterAnimationContext.Provider>
  );
}

export function useTypewriterAnimation(): TypewriterAnimationContextType {
  return useContext(
    TypewriterAnimationContext,
  ) as TypewriterAnimationContextType;
}
