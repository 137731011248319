import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { ActionHandler } from '../containers/ActionHandler/ActionHandler';

export function ActionHandlerRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>{appConfig.name}</title>
      </Helmet>

      <ActionHandler />
    </>
  );
}
