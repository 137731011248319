import cn from 'clsx';
import { type ReactElement } from 'react';

import { useExperiment } from '@/features/experiment';
import { appConfig } from '@/providers/config';

import styles from './Logo.module.scss';

export function Logo({
  size,
  className,
}: {
  size: 'x-small' | 'small' | 'medium' | 'large';
  className?: string;
}): ReactElement {
  const { experiment } = useExperiment();
  const Logo = appConfig.logo;
  const AltLogo = appConfig.altLogo;

  if (experiment === 'alternative-flow') {
    return (
      <div className={cn(styles.logo, styles.alternate, className)}>
        <AltLogo
          className={cn(styles.logo__logo, styles[`logo__logo--size-${size}`])}
        />
      </div>
    );
  }

  return (
    <div className={cn(styles.logo, className)}>
      <Logo
        className={cn(styles.logo__logo, styles[`logo__logo--size-${size}`])}
      />
    </div>
  );
}
