import { z } from 'zod';

import { type ApiMessageItemType } from '@/features/chat';
import { api } from '@/providers/api';

const ImageGeneratorPromptSchema = z.object({
  prompt: z.string(),
  url: z.string(),
});

const PromptGeneratorResponseSchema = z.object({
  data: z.object({
    text: z.string(),
    images: z.array(ImageGeneratorPromptSchema),
  }),
});

type ImageGeneratorPrompt = z.infer<typeof ImageGeneratorPromptSchema>;
type PromptGeneratorResponse = z.infer<typeof PromptGeneratorResponseSchema>;
type PromptGeneratorResult = Omit<PromptGeneratorResponse['data'], 'text'> & {
  content: string;
};

type MessagePayload = ApiMessageItemType[];

const imageGeneratorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generatePrompt: builder.mutation<PromptGeneratorResult, MessagePayload>({
      query: (messages) => ({
        url: '/chat/image',
        method: 'POST',
        body: {
          messages,
        },
        headers: {
          X_Model: '4',
          X_Stream: 'false',
          X_Pr: 'true',
        },
        responseHandler: async (response) =>
          await PromptGeneratorResponseSchema.parseAsync(await response.json()),
      }),
      transformResponse: ({
        data: { text, images },
      }: PromptGeneratorResponse) => ({
        content: text,
        images,
      }),
    }),
    generateImage: builder.mutation<unknown, ImageGeneratorPrompt>({
      query: ({ prompt, url }) => ({
        url: '/image-generator',
        method: 'POST',
        body: {
          prompt,
          url,
        },
        headers: {
          X_Pr: 'true',
        },
      }),
    }),
  }),
});

export const { useGeneratePromptMutation, useGenerateImageMutation } =
  imageGeneratorApi;
