import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'clsx';
import type { ReactElement } from 'react';

import Available from '@/assets/available-platforms-white.svg?react';
import HappyUsers from '@/assets/happy-users-white.svg?react';
import { appConfig } from '@/providers/config';

import styles from './LandingComments.module.scss';

interface CommentType {
  title: string;
  content: string;
}

export function LandingWhiteCommentsAnimation(): ReactElement {
  const comments: CommentType[] = [
    {
      title: 'Very user friendly',
      content:
        "This one is the most easy-to-use AI app I've used so far. Love it for any question I have.",
    },

    {
      title: 'The perfect all-in-one app!',
      content:
        'So crazy how complicated and intelligent this is, but also the excellent feedback you get to inspire or help you. I’m a writer, so it’s incredible to get our of writer’s block in any form possible.',
    },
    {
      title: 'Loving the AI',
      content:
        "So personalized and so fast! I'm loving how this AI App responds to anything immediately.",
    },
    {
      title: 'Perfect AI Tool',
      content:
        "It's a great time-saver to access all the latest AI models through a single, simple interface. There's no need to switch between tools.",
    },
    {
      title: 'Very Cool',
      content:
        'It is amazing app for fun and useful generating content, creating great ideas! This AI chatbot saves me in sad evenings',
    },
    {
      title: `${appConfig.name} is great.`,
      content:
        'All features that were missing have now been added! The speed has also significantly increased. I am extremely impressed with how quickly this service/developers rectified the highlighted issues, as well as improved the service itself.',
    },
    {
      title: 'Crazy Good!!!',
      content: `${appConfig.name} is working great for me. No crashes.. Nothing bad to say about this AI website`,
    },
    {
      title: 'Great app',
      content:
        'It is amazing app for fun and useful generating content, creating great ideas! This AI chatbot saves me in sad evenings',
    },
    {
      title: 'Smarter than me',
      content: `So far so good, it's been a while now while using ${appConfig.name}, this AI is beyond my intelligent. I'm glad that you're giving us future promise`,
    },
    {
      title: 'Highly Recommended',
      content:
        'Love the way it responds to my question and it’s very easy to use will recommend it to others how it help me to organize my work and optimize the work I’m doing',
    },
    {
      title: 'Crazy Good!!! This is the future.',
      content:
        'So crazy how complicated and intelligent this is, but also the excellent feedback you get to inspire or help you. I’m a writer, so it’s incredible to get our of writer’s block in any form possible.',
    },
    {
      title: 'Loving the AI',
      content:
        "So personalized and so fast! I'm loving how this AI App responds to anything immediately.",
    },
    {
      title: 'Very Good Features',
      content: `Exploring this ${appConfig.name} website, I was immediately impressed by its forward-thinking features. The synergy between ChatGPT-4 and technologies like Gemini and Palm 2 creates a user experience that's both advanced and intuitive.`,
    },
    {
      title: 'Love it. Very good!!',
      content: `Like ${appConfig.name} from my bottom of my heart. It’s simply ease my jobs by providing wonderful tips and sample mails. Good job AI team.`,
    },

    {
      title: 'Nice Work Guys!',
      content: `I was hesitant at first about the ${appConfig.name}'s sleek interface allows me to access multiple AI models in one interface. It's convenient and affordable. I've never seen any other app offering this before.`,
    },
    {
      title: `${appConfig.name} is great.`,
      content:
        'All features that were missing have now been added! The speed has also significantly increased. I am extremely impressed with how quickly this service/developers rectified the highlighted issues, as well as improved the service itself.',
    },
  ];

  const columnSize = Math.ceil(comments.length / 12);
  const columns = Array.from({ length: 12 }, (_, i) =>
    comments.slice(i * columnSize, (i + 1) * columnSize),
  );

  return (
    <div className={styles.container}>
      <div className={styles.available}>
        <div>
          <HappyUsers />
        </div>
        <div>
          <Available />
        </div>
        <div>
          <h1>Trusted by Millions</h1>
        </div>
      </div>

      <div className={styles['landing-comments-animation']}>
        {[...columns, ...columns].map((column, columnIndex) => (
          <div
            key={columnIndex}
            className={cn(
              styles['landing-comments-animation__comment-column'],
              styles.column,
            )}
          >
            {[...column, ...column].map((comment, commentIndex) => (
              <div
                className={styles['landing-comments-animation__card']}
                key={commentIndex}
              >
                <div className={styles.stars}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <FontAwesomeIcon color="#ffc568" key={star} icon={faStar} />
                  ))}
                </div>
                <div className={styles.title}>{comment.title}</div>
                <div className={styles.content}>{comment.content}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
