import { type ReactElement } from 'react';

import { useConversations } from '../../services/list';
import { ConversationList } from './ConversationList/ConversationList';
import styles from './Conversations.module.scss';
import { NoConversationsMessage } from './NoConversationsMessage/NoConversationsMessage';

export function Conversations(): ReactElement {
  const { data: conversations, loading, error } = useConversations();

  const conversationCount = conversations.length;
  const hasConversations = conversationCount > 0;

  return (
    <div className={styles.conversations}>
      {loading && <p className={styles.conversations__loading}>Loading...</p>}
      {error !== undefined && (
        <p className={styles['conversations__error-message']}>
          {error.message}
        </p>
      )}

      {hasConversations && (
        <ConversationList
          conversations={conversations.sort(
            (a, b) => b.updatedAt - a.updatedAt,
          )}
        />
      )}

      {!loading && error === undefined && !hasConversations && (
        <NoConversationsMessage />
      )}
    </div>
  );
}
