import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import { type ReactElement, useState } from 'react';

import { Icon } from '@/components';

import type { Pricing } from '../../hooks/callback-handler';
import styles from './PriceCollapsible.module.scss';

export function PriceCollapsible({
  pricing,
  loading = false,
}: {
  pricing: Pricing;
  loading?: boolean;
}): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <Collapsible.Root
      className={styles.price}
      open={open}
      onOpenChange={setOpen}
    >
      <Collapsible.Content className={styles['price-content']}>
        <div className={styles['price-content__row']}>
          <span className={styles['price-content__row-text']}>Subtotal</span>
          <span className={styles['price-content__row-value']}>
            {pricing.subtotal}
          </span>
        </div>
        <div className={styles['price-content__row']}>
          <span className={styles['price-content__row-text']}>Taxes</span>
          <span className={styles['price-content__row-value']}>
            {pricing.tax}
          </span>
        </div>
      </Collapsible.Content>
      <Collapsible.Trigger asChild>
        <button className={styles['price-trigger']}>
          <span>Total</span>
          <span className={styles['price-trigger__total']}>
            {loading ? <Icon icon={faSpinnerThird} spin /> : pricing.total}
          </span>
          <span className={styles['price-trigger__arrow']}>
            <Icon icon={faChevronUp} rotation={open ? 180 : undefined} />
          </span>
        </button>
      </Collapsible.Trigger>
    </Collapsible.Root>
  );
}
