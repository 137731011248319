import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Heading, Icon, Typography } from '@/components';

import styles from './AccountPageHeader.module.scss';

export function AccountPageHeader({
  children,
}: {
  children: string;
}): ReactElement {
  return (
    <div>
      <Typography
        asChild
        weight="semi-bold"
        size={{ initial: '2' }}
        className={styles['account-back-button']}
      >
        <Link to="/account">
          <Icon icon={faChevronLeft} fixedWidth />
          My Account
        </Link>
      </Typography>

      <Heading
        as="h1"
        weight="semi-bold"
        size={{ initial: '4', md: '6', lg: '8' }}
        className={styles['account-title']}
      >
        {children}
      </Heading>
    </div>
  );
}
