import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { type ReactElement } from 'react';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';

import { Button, type ButtonVariant, Icon } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { useExperiment } from '@/features/experiment';
import { auth } from '@/providers/firebase';

import GoogleIcon from '../../../assets/google.svg?react';
import { getNames } from '../../../utils/names';

export function GoogleLogin({
  source,
  variant,
  className,
  iconClassName,
}: {
  source: 'register' | 'login';
  variant?: ButtonVariant;
  className?: string;
  iconClassName?: string;
}): ReactElement {
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const { experiment } = useExperiment();

  const handleLogin = async (): Promise<void> => {
    const credentials = await signInWithGoogle();

    if (credentials === undefined) {
      return;
    }

    const eventData: Record<string, string | null> = {
      email: credentials.user.email,
      external_id: credentials.user.uid,
    };

    if (credentials.user.displayName !== null) {
      const userNames = getNames(credentials.user.displayName);

      if (userNames === null) {
        return;
      }

      eventData.first_name = userNames.firstName;
      eventData.last_name = userNames.lastName;
    }

    if ('dataLayer' in window) {
      window.dataLayer.push(eventData);
    }

    logEvent('btn_login_continue_google');
    logBigQueryEvent(`btn_${source}_continue_google`);

    if (source === 'register') {
      logBigQueryEvent('sign_up', {
        type: 'google',
      });
      logEvent('sign_up', {
        type: 'google',
      });
    }
  };

  return (
    <Button
      size="large"
      variant={
        variant ??
        (experiment === 'current-flow' ? 'secondary' : 'white-outline')
      }
      type="button"
      onClick={handleLogin}
      className={className}
    >
      {experiment === 'alternative-flow' ? (
        <GoogleIcon />
      ) : (
        <Icon className={iconClassName} icon={faGoogle} />
      )}
      Continue with Google
    </Button>
  );
}
