import { zodResolver } from '@hookform/resolvers/zod';
import { type ReactElement, useEffect, useState } from 'react';
import {
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
} from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import Arrow from '@/assets/arrow.svg?react';
import { Button, Form, PasswordField, TextField } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { auth as firebaseAuth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { createFormError } from '../..';
import { FormValidate } from '../FormValidate/FormValidate';
import styles from './LandingSignUpForm.module.scss';

interface SignUpFormType {
  email: string;
  password: string;
}

const signUpSchema = z.object({
  email: z.string().trim().min(1, "Email can't be empty."),
  password: z
    .string()
    .min(6, 'Password needs to be at least 6 characters long.')
    .regex(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Password must contain at least one special character.',
    ),
});

export function LandingWhiteSignUpForm(): ReactElement {
  const [createUserWithEmailAndPassword, , signUpLoading, signUpError] =
    useCreateUserWithEmailAndPassword(firebaseAuth);
  const [
    sendEmailVerification,
    sendEmailVerificationLoading,
    sendEmailVerificationError,
  ] = useSendEmailVerification(firebaseAuth);

  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  const [params] = useSearchParams();
  const initialEmail = params.get('email');

  const { logBigQueryEvent } = useLogBigQueryEvent();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<SignUpFormType>({
    defaultValues: {
      email: initialEmail ?? '',
      password: '',
    },
    resolver: zodResolver(signUpSchema),
  });

  const handleSignUp: SubmitHandler<SignUpFormType> = async ({
    email,
    password,
  }) => {
    const credentials = await createUserWithEmailAndPassword(email, password);

    if (credentials === undefined) {
      return;
    }

    logBigQueryEvent('sign_up', {
      type: 'email',
    });
    logEvent('sign_up', {
      type: 'email',
    });

    logBigQueryEvent('btn_register_sign_up', {
      eventValue: {
        source: 'register_page_content',
      },
    });

    if ('dataLayer' in window) {
      window.dataLayer.push({
        email,
        external_id: credentials.user.uid,
      });
    }

    logEvent('btn_register_sign_up');

    if (!credentials.user.emailVerified) {
      const success = await sendEmailVerification();

      setEmailVerificationSent(success);
    }
  };

  useEffect(() => {
    if (signUpError === undefined) {
      return;
    }

    logBigQueryEvent('register_error', {
      eventValue: {
        info: signUpError.message,
      },
    });
    logEvent('register_error', {
      info: signUpError.message,
    });

    const error = createFormError(signUpError.code);

    if (error === null) {
      toast.error(signUpError.message);
      return;
    }

    if (error.target === 'email' || error.target === 'password') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [signUpError, setError, logBigQueryEvent]);

  const emailValue = useWatch({
    control,
    name: 'email',
  });

  const passwordValue = useWatch({
    control,
    name: 'password',
  });

  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState<
    boolean | null
  >(null);
  const [isPasswordSpecialCharValid, setIsPasswordSpecialCharValid] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (passwordValue === undefined || passwordValue === '') {
      setIsPasswordLengthValid(null);
      setIsPasswordSpecialCharValid(null);
    } else {
      setIsPasswordLengthValid(passwordValue.length >= 6);
      setIsPasswordSpecialCharValid(
        /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue),
      );
    }
  }, [passwordValue]);

  return (
    <div className={styles['sign-up-form']}>
      {emailVerificationSent && (
        <p>Check your email for instructions to verify your email.</p>
      )}

      {!emailVerificationSent && (
        <Form
          className={styles.form}
          onSubmit={handlePromiseEvent(handleSubmit(handleSignUp))}
        >
          <div>
            <p className={styles.description}>Email</p>
            <TextField
              value={emailValue}
              variant="-white"
              className={styles.input}
              backgroundColor="#fff"
              type="email"
              placeholder="Enter your email"
              errorMessage={errors.email?.message}
              {...register('email')}
            />
          </div>
          <div>
            <p className={styles.description}>Password</p>
            <PasswordField
              value={passwordValue}
              variant="-white"
              className={styles.input}
              backgroundColor="#fff"
              placeholder="Create a password"
              errorMessage={errors.password?.message}
              {...register('password')}
            />
          </div>

          {sendEmailVerificationError !== undefined && (
            <p className={styles.error}>{sendEmailVerificationError.message}</p>
          )}
          <div>
            <FormValidate
              isValid={isPasswordLengthValid}
              text="Must be at least 6 characters"
            />
            <div className={styles.validate}>
              <FormValidate
                isValid={isPasswordSpecialCharValid}
                text="Must contain at least one special character"
              />
            </div>
          </div>

          <Button
            className={styles.button}
            size="large"
            variant="primary"
            loading={signUpLoading || sendEmailVerificationLoading}
            type="submit"
          >
            Sign up
            <Arrow className={styles.arrow} />
          </Button>
        </Form>
      )}
    </div>
  );
}
