import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Cookies from 'js-cookie';
import { type ReactElement } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import PoweredByOpenAi from '@/assets/powered-by-openai.svg?react';
import { AlertDialog, Icon, InlineLink, PaywallFeatures } from '@/components';
import { usePremium } from '@/features/account';
import usePathType from '@/hooks/path-type';
import { LandingLayout } from '@/layouts';

import { usePaddleCallbackHandler } from '../../hooks/callback-handler';
import { useCreateTransaction } from '../../hooks/create-transaction';
import { useOpenCheckout } from '../../hooks/open-checkout';
import { PaymentForm } from '../Checkout/PaymentForm/PaymentForm';
import { ProcessDialog } from '../Checkout/ProcessDialog/ProcessDialog';
import styles from './LandingCheckout.module.scss';

export function LandingCheckout(): ReactElement {
  const { data: isPremium } = usePremium();
  const [searchParams] = useSearchParams();
  const { pricing, checkoutCompleted, interval } = usePaddleCallbackHandler();
  const path = usePathType();
  useCreateTransaction({ path: `/${path}/checkout` });
  const { loading } = useOpenCheckout();
  const checkoutProcessCompleted =
    checkoutCompleted !== undefined && isPremium === true;

  const transaction = searchParams.get('transaction');
  const plan = searchParams.get('plan');

  if (transaction === null && plan === null) {
    <Navigate to="/choose-plan" />;
  }

  const onSkip = (): void => {
    Cookies.set('landing_skip', 'true', { expires: 1 });
  };

  return (
    <LandingLayout
      leftChild={
        <div className={styles.container}>
          <PaywallFeatures />
        </div>
      }
      rightChild={
        <div className={styles['right-container']}>
          <div className={styles.skip}>
            <InlineLink href="/" onClick={onSkip}>
              Skip
            </InlineLink>
          </div>
          <div className={styles.content}>
            <div className={styles['price-info']}>
              <h1>Activate your personalised AI</h1>
              <h2>
                {pricing.total === null ? (
                  <span className={styles['checkout-payment__loading']}></span>
                ) : (
                  <>
                    {pricing.total} / {interval}
                  </>
                )}
              </h2>
            </div>
            <div className={styles['checkout-form']}>
              {loading ? (
                <div className={styles['checkout-form__loading']}>
                  <Icon icon={faSpinnerThird} spin size="2x" />
                </div>
              ) : null}
              <PaymentForm />
            </div>
          </div>
          <span className={styles['powered-by']}>
            <PoweredByOpenAi />
          </span>
          <AlertDialog open={checkoutCompleted !== undefined}>
            <ProcessDialog
              pricing={pricing}
              complete={checkoutProcessCompleted}
            />
          </AlertDialog>
        </div>
      }
    />
  );
}
