import { z } from 'zod';

import { appConfig } from '@/providers/config';

export const ConversationItemSchema = z.object({
  id: z.string(),
  title: z.string(),
  updatedAt: z.number(),
  createdAt: z.number(),
  type: z.enum(['assistant', 'bot']).optional(),
  captionHistoryId: z.string().optional(),
  engine: z
    .enum([
      'gpt-3.5',
      'gpt-4',
      'gpt-4o',
      'bard',
      'image-generator',
      'web-browsing',
      'vision',
      'googleVision',
      'document',
      'llama2',
      'chatbotapp',
      'nova',
      'aiapp',
      'gemini',
      'superbot',
      'claude',
      'aiappai',
      'webSearch',
    ])
    .optional(),
  maxTokenReached: z.boolean().optional(),
});

export const ConversationStatusKeySchema = z.enum(['maxTokenReached']);
export type ConversationStatusKeyType = z.infer<
  typeof ConversationStatusKeySchema
>;

export const ConversationListSchema = z.array(ConversationItemSchema);

export type ConversationItemType = z.infer<typeof ConversationItemSchema>;
export type ConversationListType = z.infer<typeof ConversationListSchema>;

export const supportedModels: Array<
  Extract<
    ConversationItemType['engine'],
    | 'gpt-3.5'
    | 'gpt-4'
    | 'gpt-4o'
    | 'chatbotapp'
    | 'webSearch'
    | 'nova'
    | 'aiapp'
    | 'image-generator'
    | 'gemini'
    | 'superbot'
    | 'claude'
    | 'document'
  >
> = [
  'gpt-3.5',
  'gpt-4',
  'gpt-4o',
  'chatbotapp',
  'webSearch',
  'nova',
  'aiapp',
  'image-generator',
  'gemini',
  'superbot',
  'claude',
  'document',
];

export const defaultModel: ConversationModel = 'gpt-3.5';

export type ConversationModel = (typeof supportedModels)[number];

export type UpcomingConversationModel = 'webSearch' | 'link-and-ask';

export const upcomingModels: UpcomingConversationModel[] = ['link-and-ask'];
if (!appConfig.featureFlags.webBrowsing) {
  upcomingModels.push('webSearch');
}
