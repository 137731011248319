import type { EventParams } from 'firebase/analytics';
import { useCallback } from 'react';

import { selectAuth } from '@/features/auth';
import { isFetchBaseQueryError } from '@/helpers/services';
import { useAppSelector } from '@/hooks';
import { appConfig } from '@/providers/config';

import { getGoogleAnalyticsClientId } from '../hooks/client-id';
import { useLogBigQueryEventMutation } from '../services/log-big-query-event';
import { logEvent } from '../services/log-event';
import type { EventName } from '../types/events';

export function useLogBigQueryEvent(): {
  logBigQueryEvent: (
    eventName: EventName,
    params?: Partial<Omit<EventParams, 'userId' | 'authId' | 'deploymentId'>>,
  ) => void;
} {
  const [sendBigQueryEvent] = useLogBigQueryEventMutation();
  const auth = useAppSelector(selectAuth);

  const logBigQueryEvent = useCallback(
    (
      eventName: EventName,
      params?: Partial<Omit<EventParams, 'userId' | 'authId' | 'deploymentId'>>,
    ): void => {
      const clientId = getGoogleAnalyticsClientId();
      void sendBigQueryEvent({
        eventName,
        userId: clientId,
        authId: auth.user?.uid,
        deploymentId: import.meta.env.VITE_VERCEL_DEPLOYMENT_ID,
        ...params,
      }).catch((error) => {
        if (import.meta.env.VITE_PADDLE_ENVIRONMENT === 'production') {
          if (isFetchBaseQueryError(error)) {
            logEvent('xevent_error', { error: error.status });
          } else if (error instanceof Error) {
            logEvent('xevent_error', { error: error.message });
          }
        }
      });
    },
    [auth.user, sendBigQueryEvent],
  );

  return {
    logBigQueryEvent: appConfig.xEventEnabled ? logBigQueryEvent : () => {},
  };
}
