import type { ReactElement } from 'react';

import { Heading } from '@/components';

import { AccountPageContainer } from '../../components/AccountPageContainer/AccountPageContainer';
import styles from './AccountHome.module.scss';
import { AccountHomeItem } from './AccountHomeItem/AccountHomeItem';

export function AccountHome(): ReactElement {
  return (
    <AccountPageContainer className={styles['account-home']}>
      <Heading
        as="h1"
        size={{
          initial: '7',
        }}
        weight="semi-bold"
        className={styles['account-home__title']}
      >
        My Account
      </Heading>

      <nav className={styles['account-home__nav']}>
        <AccountHomeItem to="/account/settings" title="Account Settings" />
        <AccountHomeItem
          to="/account/login-connections"
          title="Login Connections"
        />
        <AccountHomeItem to="/account/plan" title="My Plan" />
      </nav>
    </AccountPageContainer>
  );
}
