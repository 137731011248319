import cn from 'clsx';
import { type ReactElement, useId } from 'react';

import { Badge } from './Badge/Badge';
import styles from './PlanItem.module.scss';

type PlanItemAdornmentType = 'bestseller' | 'save' | 'none';
export interface PlanItemAdornment {
  type: PlanItemAdornmentType;
  value?: string;
  discount?: string;
}

export function PlanItem({
  lookupKey,
  name,
  price,
  pricePerDay,
  undiscountedPrice,
  adornment,
  checked,
  onSelectPlan,
}: {
  lookupKey: string;
  name: string;
  price: string;
  pricePerDay: string;
  undiscountedPrice?: string;
  adornment?: PlanItemAdornment;
  checked: boolean;
  onSelectPlan: () => void;
}): ReactElement {
  const id = useId();

  return (
    <label
      htmlFor={id}
      className={cn(styles.plan, {
        [styles['plan--checked']]: checked,
      })}
      aria-labelledby={`label-${id}`}
    >
      <input
        id={id}
        type="radio"
        name="lookup_key"
        value={lookupKey}
        checked={checked}
        onChange={onSelectPlan}
        required
        className={styles.plan__input}
      />

      <div className={styles.plan__content}>
        <div className={styles.title}>
          <span className={styles.title__title}>{name}</span>
          <span className={styles.title__price}>{price}</span>
        </div>

        <div className={styles.price}>
          {adornment !== undefined ? (
            <>
              {adornment.type === 'bestseller' && (
                <Badge type="bestseller">BESTSELLER</Badge>
              )}
              {adornment.type === 'save' && (
                <Badge type="save">SAVE {adornment.value}</Badge>
              )}
            </>
          ) : null}

          <div className={styles.price__price}>
            <span className={styles['price__undiscounted-price']}>
              {undiscountedPrice}
            </span>

            <span className={styles['price__daily-price']}>
              {pricePerDay}/day
            </span>
          </div>
        </div>
      </div>
    </label>
  );
}
