import { Slot } from '@radix-ui/react-slot';
import cn from 'clsx';
import {
  type ButtonHTMLAttributes,
  type ForwardedRef,
  type ReactElement,
  type ReactNode,
  forwardRef,
} from 'react';

import styles from './Button.module.scss';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'destructive'
  | 'destructive-outline'
  | 'warning'
  | 'white-outline'
  | 'white-theme';

function ButtonComponent(
  {
    asChild,
    variant,
    children,
    size,
    loading = false,
    type = 'button',
    className,
    onClick,
    disabled = false,
  }: {
    asChild?: boolean;
    variant: ButtonVariant;
    children: ReactNode;
    size: 'small' | 'large';
    loading?: boolean;
    onClick?: () => Promise<void> | void;
    className?: string;
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
    disabled?: boolean;
  },
  ref: ForwardedRef<HTMLButtonElement>,
): ReactElement {
  const Comp = asChild === true ? Slot : 'button';

  return (
    <Comp
      ref={ref}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      className={cn(
        styles.button,
        styles[`button--variant-${variant}`],
        styles[`button--size-${size}`],
        {
          [styles[`button--disabled`]]: loading || disabled,
        },
        className,
      )}
    >
      {children}
    </Comp>
  );
}

export const Button = forwardRef(ButtonComponent);
