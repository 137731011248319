import * as Sentry from '@sentry/react';
import { fetchAndActivate, getAll } from 'firebase/remote-config';
import {
  type ReactElement,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  RemoteConfigSchema,
  type RemoteConfigType,
  remoteConfig,
} from '@/providers/firebase';

const RemoteConfigContext = createContext<RemoteConfigType | null>(null);

const safeJsonParse = (candidate: string): unknown => {
  let parsedJson = null;

  try {
    parsedJson = JSON.parse(candidate) as unknown;
  } catch (err) {}

  return parsedJson ?? candidate;
};

export function RemoteConfigProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [config, setConfig] = useState<RemoteConfigType | null>(null);

  useEffect(() => {
    (async () => {
      await fetchAndActivate(remoteConfig);

      const rawRemoteConfig = getAll(remoteConfig);

      const structuredRemoteConfig = Object.entries(rawRemoteConfig).reduce(
        (config, [key, value]) => {
          return {
            ...config,
            [key]: safeJsonParse(value.asString()),
          };
        },
        {},
      );

      const parsedRemoteConfig = RemoteConfigSchema.safeParse(
        structuredRemoteConfig,
      );

      if (parsedRemoteConfig.success) {
        setConfig(parsedRemoteConfig.data);
      } else {
        throw new Error(parsedRemoteConfig.error.message);
      }
    })().catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);

      Sentry.captureException(error);
    });
  }, []);

  return (
    <RemoteConfigContext.Provider value={config}>
      {children}
    </RemoteConfigContext.Provider>
  );
}

export function useRemoteConfig(): RemoteConfigType | null {
  return useContext(RemoteConfigContext);
}
