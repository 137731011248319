import * as DialogPrimitive from '@radix-ui/react-dialog';
import cn from 'clsx';
import { type ReactElement, type ReactNode } from 'react';

import styles from './Dialog.module.scss';
import { DialogHeader } from './DialogHeader/DialogHeader';

export function DialogContent({
  children,
  className,
  theme = 'light',
}: {
  children: ReactNode;
  className?: string;
  theme?: 'light' | 'dark';
}): ReactElement {
  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className={styles['dialog-overlay']} />

      <DialogPrimitive.Content
        className={cn(
          styles.dialog,
          styles[`dialog--theme-${theme}`],
          className,
        )}
      >
        <DialogHeader theme={theme} />

        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
}

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
