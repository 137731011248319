import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogTrigger,
} from '@radix-ui/react-alert-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import cn from 'clsx';
import {
  type FormEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { toast } from 'react-toastify';

import {
  AlertDialogActions,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  Button,
  Form,
} from '@/components';
import { ProfilePicture } from '@/features/account';
import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';

import {
  AccountSection,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionFooterNote,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionSpacer,
  AccountSectionTitle,
} from '../../../components/AccountSection/AccountSection';
import {
  useDeleteProfilePicture,
  useUploadProfilePicture,
} from '../../../services/profile-picture';
import styles from './ChangeProfilePicture.module.scss';

export function ChangeProfilePicture(): ReactElement {
  const { user } = useAppSelector(selectAuth);
  const {
    uploadProfilePicture,
    loading: uploadLoading,
    error: uploadError,
  } = useUploadProfilePicture();
  const {
    deleteProfilePicture,
    loading: deleteLoading,
    error: deleteError,
  } = useDeleteProfilePicture();

  const formRef = useRef<HTMLFormElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    const formData = new FormData(event.currentTarget);

    const profilePictureFile = formData.get('profile-picture');

    if (profilePictureFile instanceof File) {
      await uploadProfilePicture(profilePictureFile);
    }

    resetFileInput();
  };

  const resetFileInput = useCallback((): void => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  }, []);

  useEffect(() => {
    if (uploadError !== null) {
      toast.error(uploadError.message);

      resetFileInput();
    }
  }, [uploadError, resetFileInput]);

  useEffect(() => {
    if (deleteError !== null) {
      toast.error(deleteError.message);
    }
  }, [deleteError]);

  return (
    <AccountSection>
      <AccountSectionHeader className={styles['change-profile-picture-header']}>
        <AccountSectionHeaderContainer>
          <AccountSectionTitle>Change Profile Picture</AccountSectionTitle>
          <AccountSectionDescription>
            Upload a new photo to change your profile picture.
          </AccountSectionDescription>
        </AccountSectionHeaderContainer>

        <Form
          formRef={formRef}
          onSubmit={(event) => {
            void handleSubmit(event);
          }}
          className={styles['profile-picture-form']}
        >
          <label
            className={cn(styles['profile-picture-form__label'], {
              [styles['profile-picture-form__label--disabled']]:
                uploadLoading || deleteLoading,
            })}
          >
            <ProfilePicture
              className={cn(styles['profile-picture-form__profile-picture'], {
                [styles['profile-picture-form__profile-picture--loading']]:
                  uploadLoading || deleteLoading,
              })}
            />

            <VisuallyHidden asChild>
              <input
                ref={fileInputRef}
                onChange={() => {
                  formRef.current?.requestSubmit();
                }}
                name="profile-picture"
                type="file"
                aria-label="Upload Avatar"
                accept="image/png,image/jpeg"
                disabled={uploadLoading}
                required
              />
            </VisuallyHidden>
          </label>
        </Form>
      </AccountSectionHeader>

      <AccountSectionSpacer />

      <AccountSectionFooter variant="normal">
        <AccountSectionFooterNote>
          Pick a photo up to 4MB in size.
        </AccountSectionFooterNote>

        <AccountSectionFooterActions>
          {typeof user?.photoUrl === 'string' && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="destructive-outline"
                  size="small"
                  disabled={uploadLoading || deleteLoading}
                >
                  Remove Photo
                </Button>
              </AlertDialogTrigger>

              <AlertDialogContent>
                <Form
                  onSubmit={() => {
                    void deleteProfilePicture();
                  }}
                >
                  <AlertDialogTitle>
                    Remove your profile photo?
                  </AlertDialogTitle>

                  <AlertDialogDescription>
                    Are you sure you want to remove your profile photo? This
                    action cannot be undone.
                  </AlertDialogDescription>

                  <AlertDialogActions>
                    <AlertDialogCancel asChild>
                      <Button size="large" type="button" variant="secondary">
                        Cancel
                      </Button>
                    </AlertDialogCancel>

                    <AlertDialogAction asChild>
                      <Button size="large" type="submit" variant="destructive">
                        Yes, remove photo
                      </Button>
                    </AlertDialogAction>
                  </AlertDialogActions>
                </Form>
              </AlertDialogContent>
            </AlertDialog>
          )}

          <Button
            variant="primary"
            size="small"
            disabled={uploadLoading || deleteLoading}
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            Upload Photo
          </Button>
        </AccountSectionFooterActions>
      </AccountSectionFooter>
    </AccountSection>
  );
}
