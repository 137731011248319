import { zodResolver } from '@hookform/resolvers/zod';
import type { AuthError } from 'firebase/auth';
import { type ReactElement, useEffect } from 'react';
import { useUpdateProfile } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Button, Form, TextField } from '@/components';
import {
  createFormError,
  mapFirebaseUserToUser,
  selectAuth,
  setUser,
} from '@/features/auth';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { auth as firebaseAuth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionTitle,
} from '../../../components/AccountSection/AccountSection';
import styles from './ChangeDisplayName.module.scss';

interface ChangeDisplayNameForm {
  displayName: string;
}

export function ChangeDisplayName(): ReactElement {
  const { user } = useAppSelector(selectAuth);
  const [updateProfile, updating, updateProfileError] =
    useUpdateProfile(firebaseAuth);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    setValue,
    getValues,
    setError,
  } = useForm<ChangeDisplayNameForm>({
    defaultValues: {
      displayName: user?.displayName ?? '',
    },
    resolver: zodResolver(
      z.object({
        displayName: z
          .string()
          .trim()
          .min(1, "Display name can't be empty.")
          .max(100, "Display name can't be longer than 100 characters."),
      }),
    ),
  });

  const handleChangeDisplayName: SubmitHandler<ChangeDisplayNameForm> = async ({
    displayName,
  }) => {
    // This should never happen, but to comply with TypeScript, we need to check.
    if (firebaseAuth.currentUser === null) {
      return;
    }

    const success = await updateProfile({ displayName });

    if (!success) {
      return;
    }

    toast.success('Display name updated successfully.');

    const mappedUser = mapFirebaseUserToUser(firebaseAuth.currentUser);
    dispatch(setUser(mappedUser));

    setValue('displayName', getValues('displayName').trim());

    reset(
      {},
      {
        keepValues: true,
      },
    );
  };

  useEffect(() => {
    if (updateProfileError === undefined) {
      return;
    }

    const error = createFormError((updateProfileError as AuthError)?.code);

    if (error === null) {
      toast.error(updateProfileError.message);
      return;
    }

    toast.error(error.message);
  }, [updateProfileError, setError]);

  return (
    <Form onSubmit={handlePromiseEvent(handleSubmit(handleChangeDisplayName))}>
      <AccountSection>
        <AccountSectionHeader>
          <AccountSectionHeaderContainer>
            <AccountSectionTitle>Display Name</AccountSectionTitle>
            <AccountSectionDescription>
              Please input your name and surname.
            </AccountSectionDescription>
          </AccountSectionHeaderContainer>
        </AccountSectionHeader>

        <AccountSectionContent className={styles.form}>
          <TextField
            type="text"
            placeholder="Display Name"
            errorMessage={errors.displayName?.message}
            {...register('displayName')}
          />
        </AccountSectionContent>

        <AccountSectionFooter variant="normal">
          <AccountSectionFooterActions>
            <Button
              type="submit"
              variant="primary"
              size="small"
              disabled={dirtyFields.displayName === undefined}
              loading={updating}
            >
              Save
            </Button>
          </AccountSectionFooterActions>
        </AccountSectionFooter>
      </AccountSection>
    </Form>
  );
}
