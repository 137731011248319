export class RetriableClientError extends Error {}
export class ResponseError extends Error {
  status: number;
  code: number | string;
  message: string;
  retriable: boolean;

  constructor({
    status,
    code,
    message,
    retriable,
  }: {
    status: number;
    code: number | string;
    message: string;
    retriable?: boolean;
  }) {
    super();
    this.status = status;
    this.code = code;
    this.message = message;
    this.retriable = retriable ?? false;
  }
}

type ClientErrorType =
  | 'invalid-history'
  | 'empty-message'
  | 'unknown'
  | 'display-options';
export class ClientError extends Error {
  code?: string;
  type: ClientErrorType;
  message: string;

  constructor({
    code,
    type,
    message,
  }: {
    code?: string;
    type: ClientErrorType;
    message: string;
  }) {
    super();
    this.code = code;
    this.type = type;
    this.message = message;
  }
}
