import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import cn from 'clsx';
import { type ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './DialogHeader.module.scss';

export function DialogHeader({
  theme,
}: {
  theme: 'light' | 'dark';
}): ReactElement {
  return (
    <div className={cn(styles.header, styles[`header--theme-${theme}`])}>
      <DialogPrimitive.Close asChild>
        <button aria-label="Close" className={styles.header__close}>
          <Icon icon={faXmark} size="xl" />
        </button>
      </DialogPrimitive.Close>
    </div>
  );
}
