import { Slot } from '@radix-ui/react-slot';
import cn from 'clsx';
import type { ReactElement, ReactNode } from 'react';

import { type Breakpoint } from '@/types/styles';

import styles from './Typography.module.scss';

type TextSize = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

interface TypographyBaseProps {
  children: ReactNode;
  size: Record<'initial', TextSize> & Partial<Record<Breakpoint, TextSize>>;
  weight?: 'light' | 'regular' | 'medium' | 'semi-bold' | 'bold';
  className?: string;
}

type HTMLElementTagName = keyof HTMLElementTagNameMap;

interface TypographyAsProps<
  TagName extends HTMLElementTagName = HTMLElementTagName,
> {
  asChild?: never;
  as: TagName;
}

interface TypographyAsChildProps {
  as?: never;
  asChild: true;
}

export type CustomTypographyProps<TagName extends HTMLElementTagName> =
  TypographyBaseProps & TypographyAsProps<TagName>;

export function Typography({
  children,
  as: Tag,
  size,
  weight = 'regular',
  className,
  asChild,
}: TypographyBaseProps &
  (TypographyAsChildProps | TypographyAsProps)): ReactElement {
  const breakpointClasses = (() => {
    return Object.keys(size).reduce<string[]>((acc, value) => {
      if (value === 'initial') {
        return acc;
      }

      acc.push(
        styles[`typography--size-${size[value as Breakpoint]}-${value}`],
      );
      return acc;
    }, []);
  })();

  return (
    <Slot
      className={cn(
        styles.typography,
        styles[`typography--weight-${weight}`],
        styles[`typography--size-${size.initial}`],
        ...breakpointClasses,
        className,
      )}
    >
      {asChild === true ? children : <Tag>{children}</Tag>}
    </Slot>
  );
}
