import { zodResolver } from '@hookform/resolvers/zod';
import 'firebase/auth';
import type { AuthError } from 'firebase/auth';
import { type ReactElement, useEffect } from 'react';
import { useVerifyBeforeUpdateEmail } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Button, Form, PasswordField, TextField } from '@/components';
import {
  createFormError,
  selectAuth,
  useReauthenticateWithCredential,
} from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { appConfig } from '@/providers/config';
import { auth as firebaseAuth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionTitle,
} from '../../../components/AccountSection/AccountSection';
import styles from './ChangeEmail.module.scss';

interface ChangeEmailForm {
  email: string;
  password: string;
}

export function ChangeEmail(): ReactElement {
  const auth = useAppSelector(selectAuth);

  const [verifyBeforeUpdateEmail, updateEmailLoading, updateEmailError] =
    useVerifyBeforeUpdateEmail(firebaseAuth);
  const {
    reauthenticateWithCredential,
    loading: reauthenticateLoading,
    error: reauthenticateError,
  } = useReauthenticateWithCredential();

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
  } = useForm<ChangeEmailForm>({
    defaultValues: {
      email: auth.user?.email ?? '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(1, "Email can't be empty.")
          .email('Email is invalid'),
        password: z
          .string()
          .min(1, "Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      }),
    ),
  });

  const handleChangeEmail: SubmitHandler<ChangeEmailForm> = async ({
    email,
    password,
  }) => {
    const isSuccessfullyReauthenticated =
      await reauthenticateWithCredential(password);

    if (!isSuccessfullyReauthenticated) {
      return;
    }

    const success = await verifyBeforeUpdateEmail(email, null);

    if (success) {
      toast.info(
        `Please verify your email to complete the change. Follow the verification link sent to ${email}.`,
        {
          autoClose: false,
        },
      );
    }
  };

  useEffect(() => {
    if (updateEmailError === undefined) {
      return;
    }

    const error = createFormError((updateEmailError as AuthError)?.code);

    if (error === null) {
      toast.error(updateEmailError.message);
      return;
    }

    if (error.target === 'email' || error.target === 'password') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [updateEmailError, setError]);

  useEffect(() => {
    if (reauthenticateError === null) {
      return;
    }

    const error = createFormError((reauthenticateError as AuthError)?.code);

    if (error === null) {
      toast.error(reauthenticateError.message);
      return;
    }

    if (error.target === 'email' || error.target === 'password') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [reauthenticateError, setError]);

  return (
    <Form onSubmit={handlePromiseEvent(handleSubmit(handleChangeEmail))}>
      <AccountSection>
        <AccountSectionHeader>
          <AccountSectionHeaderContainer>
            <AccountSectionTitle>Change Email</AccountSectionTitle>
            <AccountSectionDescription>
              {`Kindly provide the email address you wish to use for logging in to ${appConfig.name}.`}
            </AccountSectionDescription>
          </AccountSectionHeaderContainer>
        </AccountSectionHeader>

        <AccountSectionContent className={styles.form}>
          <TextField
            type="email"
            placeholder="Email Address"
            errorMessage={errors.email?.message}
            {...register('email')}
          />

          <PasswordField
            placeholder="Current Password"
            errorMessage={errors.password?.message}
            {...register('password')}
          />
        </AccountSectionContent>

        <AccountSectionFooter variant="normal">
          <AccountSectionFooterActions>
            <Button
              type="submit"
              variant="primary"
              size="small"
              disabled={dirtyFields.email === undefined}
              loading={updateEmailLoading || reauthenticateLoading}
            >
              Save
            </Button>
          </AccountSectionFooterActions>
        </AccountSectionFooter>
      </AccountSection>
    </Form>
  );
}
