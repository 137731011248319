import { useAutoAnimate } from '@formkit/auto-animate/react';
import { type ReactElement } from 'react';

import { Heading } from '@/components';

import { type ConversationListType } from '../../../types/conversation';
import styles from './ConversationDateGroup.module.scss';
import { ConversationItem } from './ConversationItem/ConversationItem';

export function ConversationDateGroup({
  title,
  conversations,
}: {
  title: string;
  conversations: ConversationListType;
}): ReactElement {
  const [animationParent] = useAutoAnimate();

  const hasConversations = conversations.length > 0;

  return (
    <div ref={animationParent} className={styles['conversation-date-group']}>
      {hasConversations && (
        <Heading
          as="h3"
          size={{
            initial: '1',
          }}
          className={styles['conversation-date-group__title']}
          weight="semi-bold"
        >
          {title}
        </Heading>
      )}

      {conversations.map(({ id, title, engine }) => (
        <ConversationItem key={id} id={id} title={title} engine={engine} />
      ))}
    </div>
  );
}
