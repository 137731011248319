import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './ErrorMessage.module.scss';

export function ErrorMessage({ children }: { children: string }): ReactElement {
  return (
    <div className={styles['error-message']}>
      <Icon icon={faCircleExclamation} />
      <p className={styles['error-message__message']}>{children}</p>
    </div>
  );
}
