import { type ApiMessageItemType } from '@/features/chat';
import { api } from '@/providers/api';

interface SendMessageResponse {
  choices: Array<{
    message: ApiMessageItemType;
  }>;
}

type MessagePayload = ApiMessageItemType[];

const conversationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConversationTitle: builder.mutation<string, MessagePayload>({
      query: (messages) => ({
        url: `/chat/title`,
        method: 'POST',
        body: {
          messages,
        },
      }),
      transformResponse: (data: SendMessageResponse) =>
        data.choices[0].message.content,
    }),
  }),
});

export const { useGetConversationTitleMutation } = conversationApi;
