import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Arrow from '@/assets/arrow.svg?react';
import { Button, Icon, Logo } from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { getNames, selectAuth } from '@/features/auth';
import { useGetProductQuery } from '@/features/payment';
import { isFetchBaseQueryError } from '@/helpers/services';
import { useAppSelector } from '@/hooks';
import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import amexIcon from '../../assets/amex.svg';
import applePayIcon from '../../assets/apple-pay.svg';
import discoverIcon from '../../assets/discover.svg';
import googlePayIcon from '../../assets/google-pay.svg';
import maestroIcon from '../../assets/maestro.svg';
import masterCardIcon from '../../assets/mastercard.svg';
import paypalIcon from '../../assets/paypal.svg';
import visaIcon from '../../assets/visa.svg';
import { CountDown } from '../../containers/ChoosePlan/CountDown/CountDown';
import { LegalText } from '../../containers/ChoosePlan/LegalText/LegalText';
import { calculateSavingsPercentageBetweenPlans } from '../../utils/calculate-price-diff';
import styles from './PaywallPlans.module.scss';
import {
  PlanItem,
  type PlanItemAdornment,
  type PlanItemVariant,
} from './PlanItem/PlanItem';

interface Product {
  productId: string;
  name: string;
  amount: number;
  price: string;
  pricePerDay: string;
  adornment: PlanItemAdornment | undefined;
}

export function PaywallPlansWhite({
  compact,
  variant,
  checkoutPath = '/landing/checkout',
}: {
  compact?: boolean;
  variant?: PlanItemVariant;
  checkoutPath?: string;
}): ReactNode {
  const {
    data: products,
    error: productError,
    refetch: productRefetch,
  } = useGetProductQuery({
    productId: import.meta.env.VITE_PADDLE_PRODUCT_ID,
  });
  const [selectedPlan, setSelectedPlan] = useState<Product | null>(null);
  const auth = useAppSelector(selectAuth);
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const createNewTransactionCalled = useRef(false);
  const retryCount = useRef(0);
  const path = usePathType();

  useEffect(() => {
    if (createNewTransactionCalled.current) {
      return;
    }

    createNewTransactionCalled.current = true;
    logBigQueryEvent('lnd_paywall');
    logEvent('lnd_paywall', {
      paywallType: 'landing_white',
    });
  }, [logBigQueryEvent]);

  useEffect(() => {
    if (productError === undefined) return;

    if (isFetchBaseQueryError(productError)) {
      logEvent('paywall_error', {
        status: productError.status,
        error: 'error' in productError ? productError.error : undefined,
      });
      if (retryCount.current <= 5) {
        productRefetch().catch(() => {
          logEvent('paywall_error', {
            status: `${productError.status} (retry)`,
            error: 'error' in productError ? productError.error : undefined,
          });
        });
      }
      retryCount.current += 1;
    } else {
      logEvent('paywall_error', {
        status: productError.code,
        error: productError.message,
      });
    }
  }, [productError, productRefetch]);

  const plans = useMemo(() => {
    if (products === undefined) {
      return [];
    }

    const activeProducts = products.filter(
      (product) => product.status === 'active',
    );

    if (activeProducts.length === 0) {
      return [];
    }

    const savings = calculateSavingsPercentageBetweenPlans({
      plans: activeProducts,
    });

    const priceFormatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    const priceFormatterFractionless = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'usd',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const adornments = activeProducts.reduce<
      Record<string, PlanItemAdornment | undefined>
    >((acc, product, index) => {
      const saving = savings.find((s) => s.planId === product.id);
      const savingsPercentage = saving?.savingsPercentage ?? 0;
      const savingsAmount = saving?.priceDifference ?? 0;
      const savingsDiscount =
        savingsPercentage > 0 ? `Save %${savingsPercentage}` : undefined;
      let type: PlanItemAdornment['type'] = 'none';
      if (
        activeProducts.length > 2 &&
        index === Math.floor((activeProducts.length - 1) / 2)
      ) {
        type = 'most-popular';
      } else if (index === activeProducts.length - 1) {
        type = 'most-popular';
      }
      acc[product.id] = {
        type,
        value:
          savingsAmount > 0
            ? priceFormatterFractionless.format(savingsAmount)
            : undefined,
        discount: savingsDiscount,
      };

      return acc;
    }, {});

    const planDetails = activeProducts.reduce<Record<string, string>>(
      (acc, product) => {
        acc[product.id] = '';
        if (
          product.billingCycle.interval === 'month' &&
          product.billingCycle.frequency > 1
        ) {
          acc[product.id] = `${product.billingCycle.frequency} Months`;
        } else if (product.billingCycle.interval === 'month') {
          acc[product.id] = '1 Month';
        } else if (
          product.billingCycle.interval === 'week' &&
          product.billingCycle.frequency > 1
        ) {
          acc[product.id] = `${product.billingCycle.frequency} Weeks`;
        } else if (product.billingCycle.interval === 'week') {
          acc[product.id] = 'Weekly';
        } else if (product.billingCycle.interval === 'year') {
          acc[product.id] = '12 Months';
        }
        return acc;
      },
      {},
    );

    return activeProducts
      .map((product) => {
        const plan = product.id;
        const price = parseFloat(product.unitPrice.amount) / 100;
        const pricePerDay =
          product.billingCycle.interval === 'month'
            ? price / (30 * product.billingCycle.frequency)
            : price / (365 * product.billingCycle.frequency);

        const finalProduct: Product = {
          productId: plan,
          name: planDetails[plan],
          amount: parseFloat(product.unitPrice.amount),
          price: priceFormatter.format(price),
          pricePerDay: priceFormatter.format(pricePerDay),
          adornment: adornments[plan],
        };

        if (plan === activeProducts[activeProducts.length - 1].id) {
          setSelectedPlan(finalProduct);
        }

        return finalProduct;
      })
      .sort((a, b) => a.amount - b.amount);
  }, [products]);

  const handleContinue = (): void => {
    if (auth.user === null || selectedPlan?.productId === undefined) {
      return;
    }

    const eventData: Record<string, string | null> = {
      email: auth.user.email,
      external_id: auth.user.uid,
    };

    if (auth.user.displayName !== null) {
      const userNames = getNames(auth.user.displayName);

      if (userNames === null) {
        return;
      }
      const model = params.get('model');

      if (model !== null) {
        localStorage.setItem('model', model);
      }
      eventData.first_name = userNames.firstName;
      eventData.last_name = userNames.lastName;
    }

    if ('dataLayer' in window) {
      window.dataLayer.push(eventData);
    }

    logEvent('purchase_button_click');
    logBigQueryEvent('purchase_button_click');

    const searchParams = new URLSearchParams();
    searchParams.set('plan', selectedPlan?.productId);
    const finalCheckoutPath =
      path === 'landing-gpt4o' || path === 'landing-2'
        ? `/${path}/checkout`
        : checkoutPath;

    navigate(`${finalCheckoutPath}?${searchParams.toString()}`);
  };

  return (
    <div className={cn(styles['paywall-plans'], { [styles.compact]: compact })}>
      <div className={styles['logo-content']}>
        <Logo className={styles.logo} size={'medium'} />
      </div>
      <div className={styles['paywall-plans-content']}>
        <h1 className={styles['paywall-plans-content__title']}>
          Choose Your Plan
        </h1>
        <p className={styles['paywall-plans-content__subtitle']}>
          Unlock the full potential of {appConfig.name} ✨
        </p>
        <h4 className={styles['paywall-plans-content__countdown']}>
          75% DISCOUNT RESERVED FOR&nbsp;
          <CountDown className={styles.counter} />
          <Icon icon={faBolt} className={styles.lightning} />
        </h4>
      </div>

      <form className={styles['paywall-plans-form']}>
        {plans.map(
          ({ productId, name, price, pricePerDay, adornment }, index) => (
            <PlanItem
              key={index}
              lookupKey={productId}
              name={name}
              price={price}
              pricePerDay={pricePerDay}
              adornment={adornment}
              checked={selectedPlan?.productId === productId}
              compact={compact}
              variant={variant}
              onSelectPlan={() => {
                setSelectedPlan({
                  productId,
                  name,
                  price,
                  amount: parseFloat(price),
                  pricePerDay,
                  adornment: undefined,
                });
              }}
            />
          ),
        )}
      </form>

      <div className={styles['paywall-plans-button-container']}>
        <div className={styles['paywall-plans-button-content']}>
          <Button
            className={styles['paywall-plans-button']}
            variant={'primary'}
            size={'small'}
            onClick={handleContinue}
          >
            Continue
            <Arrow className={styles.arrow} />
          </Button>
          <div className={styles['privacy-terms-container']}>
            <div className={styles['privacy-terms']}>
              <Link
                to={`${appConfig.landing}/privacy`}
                target="_blank"
                className={styles['legal-text__link']}
              >
                Privacy
              </Link>
              <hr />
              <Link
                to={`${appConfig.landing}/terms`}
                target="_blank"
                className={styles['legal-text__link']}
              >
                Terms
              </Link>
              <hr />
              <Link
                to={`${appConfig.landing}/refund-policy`}
                target="_blank"
                className={styles['legal-text__link']}
              >
                Refund
              </Link>
            </div>
            <div className={styles['cancel-container']}>
              <p>Cancel Anytime</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <LegalText />
      </div>

      <span className={styles['pay-safe-secure']}>
        <Icon icon={faShieldCheck} /> Pay safe & secure
      </span>

      <div className={styles['payment-methods']}>
        <img src={paypalIcon} alt="paypal" />
        <img src={applePayIcon} alt="apple pay" />
        <img src={googlePayIcon} alt="google pay" />
        <img src={visaIcon} alt="visa" />
        <img src={masterCardIcon} alt="master card" />
        <img src={discoverIcon} alt="discover" />
        <img src={amexIcon} alt="amex" />
        <img src={maestroIcon} alt="maestro" />
      </div>
    </div>
  );
}
