import { type ReactNode, useState } from 'react';
import { toast } from 'react-toastify';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  Button,
  Form,
} from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { appConfig } from '@/providers/config';

import {
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
  usePaddle,
  usePaymentMethod,
} from '../..';

export function CancelSubscription({
  subscriptionID,
}: {
  subscriptionID: string;
}): ReactNode {
  const { loading: paymentMethodLoading } = usePaymentMethod();
  const {
    data: subscription,
    error: subscriptionError,
    refetch: subscriptionRefetch,
  } = useGetSubscriptionQuery('subscription');
  const [
    cancelSubscription,
    { isLoading: cancelLoading, isSuccess: cancelSuccess },
  ] = useCancelSubscriptionMutation();
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { paddleInstance } = usePaddle();

  const cancelSubscriptionAction = async (): Promise<void> => {
    try {
      await cancelSubscription('cancel-subscription').unwrap();

      logBigQueryEvent('payment_canceled');
      logEvent('payment_canceled');

      subscriptionRefetch().catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error while refetching subscription', error);
      });
      toast.success('Subscription canceled successfully');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while canceling subscription', error);
    }
  };

  // Will be enabled once the Paddle confirmed the feature
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCancelSubscription = async (): Promise<void> => {
    if (paddleInstance === null) {
      setDialogOpen(true);
      return;
    }

    try {
      await paddleInstance.Retain.initCancellationFlow({
        subscriptionId: subscriptionID,
      }).then((result) => {
        // This means the customer either aborted the flow (i.e.
        // they clicked on "never mind, I don't want to cancel"), or
        // accepted a salvage attempt or salvage offer.
        // Thus, do nothing since they won't cancel.
        if (result.status === 'retained' || result.status === 'aborted') {
          return;
        }

        // At this point, the customer ended deciding to cancel (i.e.
        // they rejected the salvage attempts and the salvage offer).
        // It could also be the case the widget couldn't be shown properly for
        // some reason (for which case, `result.status` will be 'error'), but that
        // shouldn't stop them from cancelling.
        // If there is an error on the retain, we should still proceed with the default cancellation.
        // Otherwise, retain will handle the cancellation.

        // The normal cancel flow goes here
        if (result.status === 'error') {
          setDialogOpen(true);
        }

        // The customer canceled the subscription
        if (result.status === 'chose_to_cancel') {
          subscriptionRefetch().catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error while refetching subscription', error);
          });
          toast.success('Subscription canceled successfully');
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while initializing cancellation flow', error);
      setDialogOpen(true);
    }
  };

  if (
    cancelSuccess ||
    paymentMethodLoading ||
    (subscription === undefined && subscriptionError === undefined)
  ) {
    return null;
  }

  return (
    <AlertDialog onOpenChange={setDialogOpen} open={dialogOpen}>
      <Button
        onClick={() => {
          if (appConfig.featureFlags.retainCancelFlow) {
            void onCancelSubscription();
          } else {
            setDialogOpen(true);
          }
        }}
        variant="destructive-outline"
        size="small"
        loading={cancelLoading}
      >
        Cancel Subscription
      </Button>

      <AlertDialogContent>
        <Form
          onSubmit={() => {
            void cancelSubscriptionAction();
          }}
        >
          <AlertDialogTitle>Cancel Your Subscription</AlertDialogTitle>

          <AlertDialogDescription>
            To proceed with the cancellation of your subscription, please click
            the &apos;Confirm Cancellation&apos; button below. <br />
            Your subscription will be promptly canceled, but you can continue to
            use our web app until the end of the billing period.
          </AlertDialogDescription>

          <AlertDialogActions>
            <AlertDialogCancel asChild>
              <Button size="large" type="button" variant="secondary">
                No, keep it.
              </Button>
            </AlertDialogCancel>

            <AlertDialogAction asChild>
              <Button size="large" type="submit" variant="destructive">
                Confirm Cancellation
              </Button>
            </AlertDialogAction>
          </AlertDialogActions>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
