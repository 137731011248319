import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { CheckoutEventsTimePeriodInterval } from '@paddle/paddle-js';
import { type ReactElement, useMemo } from 'react';

import { Icon } from '@/components';

import type { Pricing } from '../../../hooks/callback-handler';
import { PricingTable } from '../PricingTable/PricingTable';
import styles from './CheckoutDetails.module.scss';

export function CheckoutDetails({
  pricing,
  loading = false,
}: {
  pricing: Pricing;
  loading?: boolean;
}): ReactElement {
  const interval = useMemo(() => {
    switch (pricing.cycle.interval) {
      case CheckoutEventsTimePeriodInterval.MONTH:
        if (pricing.cycle.frequency > 1) {
          return `Every ${pricing.cycle.frequency} months`;
        }
        return 'Per month';
      case CheckoutEventsTimePeriodInterval.YEAR:
        return 'Per year';
    }
  }, [pricing.cycle]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Icon icon={faSpinnerThird} spin size="xl" />
      </div>
    );
  }

  return (
    <>
      <div>
        Your Plan <span className={styles['plan-badge']}>Pro</span>
      </div>
      <div className={styles.plan}>
        <span className={styles['plan-price']}>{pricing.recurringTotal}</span>{' '}
        <span className={styles['plan-divider']}>/</span>{' '}
        <span className={styles['plan-interval']}>{interval}</span>
      </div>
      <div className={styles['pricing-title']}>
        All prices in {pricing.currency}
      </div>
      <div className={styles['pricing-table']}>
        <PricingTable pricing={pricing} />
      </div>
    </>
  );
}
