import { GrowthBook } from '@growthbook/growthbook-react';

import { appConfig } from './config';

const { VITE_GROWTHBOOK_API_HOST, VITE_GROWTHBOOK_CLIENT_KEY } = import.meta
  .env;

export const gb = new GrowthBook({
  enabled: appConfig.growthbookEnabled,
  apiHost: VITE_GROWTHBOOK_API_HOST,
  clientKey: VITE_GROWTHBOOK_CLIENT_KEY,
  // Enable easier debugging during development
  enableDevMode: process.env.NODE_ENV !== 'production',
  // Update the instance in realtime as features change in GrowthBook
  subscribeToChanges: true,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    // TODO: Send this event to analytics provider
    // eslint-disable-next-line no-console
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});
