import cn from 'clsx';
import { type ReactNode, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { selectAuth } from '@/features/auth';
import { useExperiment } from '@/features/experiment';
import { PublicFooter, PublicHeader } from '@/features/layouts';
import { useAppSelector, useFirebaseIdToken } from '@/hooks';

import styles from './PublicLayout.module.scss';

export function PublicLayout({
  authRequired = false,
  fullscreen = false,
}: {
  authRequired?: boolean;
  fullscreen?: boolean;
}): ReactNode {
  const auth = useAppSelector(selectAuth);
  const firebaseToken = useFirebaseIdToken();
  const { experiment } = useExperiment();
  const alternateFlow = experiment === 'alternative-flow';
  const landingFlow = experiment === 'landing-flow';
  const path =
    localStorage.getItem('path') !== null
      ? localStorage.getItem('path')
      : 'landing';
  const loggedIn = auth.user !== null;

  if (authRequired && landingFlow && !loggedIn) {
    return <Navigate to={`/${path}/login`} />;
  } else if (authRequired && !loggedIn) {
    return <Navigate to="/login" replace />;
  } else if (loggedIn && firebaseToken?.userToken === null) {
    return null;
  }

  return (
    <div
      className={cn(
        styles['public-layout'],
        alternateFlow ? styles.alternate : null,
      )}
    >
      {alternateFlow && <PublicHeader />}
      {fullscreen ? (
        <Suspense>
          <Outlet />
        </Suspense>
      ) : (
        <main className={styles.main}>
          <Suspense>
            <Outlet />
          </Suspense>
        </main>
      )}
      {alternateFlow && <PublicFooter />}
    </div>
  );
}
