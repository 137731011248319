import {
  CheckoutEventNames,
  type CheckoutEventsTimePeriod,
  CheckoutEventsTimePeriodInterval,
  type CurrencyCode,
  type PaddleEventData,
} from '@paddle/paddle-js';
import { useEffect, useMemo, useRef, useState } from 'react';

import { logEvent, useLogBigQueryEvent } from '@/features/analytics';

import { usePaddle } from '../contexts/paddle';

export interface Pricing {
  subtotal: string | null;
  tax: string | null;
  total: string | null;
  recurringTotal: string | null;
  cycle: CheckoutEventsTimePeriod;
  currency: CurrencyCode;
  plan: string | null;
  rawTotalAmount: number | null;
}

export const defaultPricing: Pricing = {
  subtotal: null,
  tax: null,
  total: null,
  recurringTotal: null,
  cycle: {
    frequency: 1,
    interval: CheckoutEventsTimePeriodInterval.MONTH,
  },
  currency: 'USD',
  plan: null,
  rawTotalAmount: null,
};

export function usePaddleCallbackHandler(): {
  onEventCallback: (event: PaddleEventData) => void;
  resetPricing: () => void;
  pricing: Pricing;
  checkoutCompleted: PaddleEventData | undefined;
  interval: string;
} {
  const [pricing, setPricing] = useState<Pricing>(defaultPricing);
  const [checkoutCompleted, setCheckoutCompleted] = useState<PaddleEventData>();
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const { paddleInstance } = usePaddle();
  const checkoutEventSentRef = useRef(false);

  const onEventCallback = (event: PaddleEventData): void => {
    const priceFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: event.data?.currency_code ?? 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    switch (event.name) {
      case CheckoutEventNames.CHECKOUT_LOADED:
      case CheckoutEventNames.CHECKOUT_CUSTOMER_CREATED:
      case CheckoutEventNames.CHECKOUT_CUSTOMER_UPDATED:
        if (event.data !== undefined) {
          setPricing({
            subtotal: priceFormatter.format(event.data.totals.subtotal),
            tax: priceFormatter.format(event.data.totals.tax),
            total: priceFormatter.format(event.data.totals.total),
            recurringTotal:
              event.data.recurring_totals?.total !== undefined
                ? priceFormatter.format(event.data.recurring_totals?.total)
                : '',
            cycle: event.data.items[0].billing_cycle ?? defaultPricing.cycle,
            currency: event.data.currency_code,
            plan: event.data.items[0].product.id,
            rawTotalAmount: event.data.totals.total,
          });
        }
        break;
      case CheckoutEventNames.CHECKOUT_COMPLETED:
        setCheckoutCompleted(event);
        break;
    }
  };

  useEffect(() => {
    if (paddleInstance === null) {
      return;
    }

    paddleInstance.Update({
      eventCallback: onEventCallback,
    });
  }, [paddleInstance]);

  useEffect(() => {
    if (checkoutCompleted === undefined || checkoutEventSentRef.current) {
      return;
    }

    checkoutEventSentRef.current = true;

    logBigQueryEvent('paddle_success');
    logEvent('paddle_success');
  }, [checkoutCompleted, logBigQueryEvent]);

  const resetPricing = (): void => {
    setPricing(defaultPricing);
  };

  const interval = useMemo(() => {
    switch (pricing.cycle.interval) {
      case CheckoutEventsTimePeriodInterval.MONTH:
        if (pricing.cycle.frequency > 1) {
          return `${pricing.cycle.frequency} months`;
        }
        return 'month';
      case CheckoutEventsTimePeriodInterval.YEAR:
        return 'year';
      default:
        return '';
    }
  }, [pricing.cycle]);

  return {
    onEventCallback,
    resetPricing,
    pricing,
    checkoutCompleted,
    interval,
  };
}
