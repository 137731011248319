import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getGoogleAnalyticsClientId } from '@/features/analytics';
import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';

import { useCreateTransactionMutation } from '../services/transaction';

export function useCreateTransaction({ path }: { path: string }): void {
  const auth = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [createTransaction] = useCreateTransactionMutation();
  const createNewTransactionCalled = useRef(false);

  const transaction = searchParams.get('transaction');
  const plan = searchParams.get('plan');

  const createNewTransaction = useCallback(
    async (plan: string): Promise<string> => {
      if (auth.user?.email === undefined || auth.user?.email === null) {
        throw new Error('User email is undefined.');
      }
      const createdTransaction = await createTransaction({
        priceId: plan,
        trackingId: getGoogleAnalyticsClientId(),
      }).unwrap();
      return createdTransaction.id;
    },
    [auth.user?.email, createTransaction],
  );

  useEffect(() => {
    if (
      !createNewTransactionCalled.current &&
      transaction === null &&
      plan !== null
    ) {
      createNewTransactionCalled.current = true;
      void createNewTransaction(plan).then((transaction) => {
        navigate(`${path}/?transaction=${transaction}`, { replace: true });
      });
    }
  }, [createNewTransaction, navigate, path, plan, transaction]);
}
