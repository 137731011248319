import Cookies from 'js-cookie';
import { type ReactElement, useEffect, useState } from 'react';

export function CountDown({ className }: { className?: string }): ReactElement {
  const [countdown, setCountdown] = useState<number>();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        const count = (prevCountdown ?? 600) - 1;
        if (count <= 0) {
          clearInterval(timer);
          return 0;
        }
        return count;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  if (countdown === undefined) {
    const campaignCookie = Cookies.get('campaign_duration');
    if (campaignCookie !== undefined) {
      setCountdown(parseInt(campaignCookie, 10));
    } else {
      setCountdown(600);
      Cookies.set('campaign_duration', '600', { expires: 1 });
    }
    return <span className={className}>10:00</span>;
  } else {
    Cookies.set('campaign_duration', countdown.toString(), { expires: 1 });
  }

  return <span className={className}>{formatTime(countdown)}</span>;
}
