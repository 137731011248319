import { useEffect, useState } from 'react';

import { auth as firebaseAuth } from '@/providers/firebase';

export function useFirebaseIdToken(): { userToken: string | null } {
  const [userToken, setUserToken] = useState<string | null>(null);

  const firebaseUser = firebaseAuth.currentUser;

  useEffect(() => {
    (async () => {
      if (firebaseUser === null) {
        return;
      }

      const idToken = await firebaseUser.getIdToken();

      setUserToken(idToken);

      // eslint-disable-next-line no-console
    })().catch(console.error);
  }, [firebaseUser]);

  return { userToken };
}
