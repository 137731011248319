import type { ReactElement } from 'react';

import Audio from '@/assets/superbot-popup-audio.svg?react';
import Document from '@/assets/superbot-popup-document.svg?react';
import Logo from '@/assets/superbot-popup-logo.svg?react';
import Search from '@/assets/superbot-popup-search.svg?react';
import { Button, DialogContent } from '@/components';

import styles from './SuperBotPopup.module.scss';

export function SuperbotPopup({
  onClick,
}: {
  onClick: () => void;
}): ReactElement {
  const card = [
    {
      icon: <Search />,
      title: 'Image and Video Insights',
      description:
        'Upload a picture from your hike or a video of your pet, and get fun facts and detailed insights instantly',
    },
    {
      icon: <Document />,
      title: 'PDF and Document Handling',
      description:
        'Upload school papers, work documents, or e-books, and get easy-to-read summaries and key points',
    },
    {
      icon: <Audio />,
      title: 'Audio Analysis',
      description:
        'Record a lecture, a meeting, or a personal note, and let Superbot transcribe and highlight the essentials',
    },
  ];
  return (
    <DialogContent className={styles.container}>
      <div className={styles.top__content}>
        <Logo />
        <h1>All-in-One Media Assistant</h1>
        <p>
          Transform how you interact with photos, videos, documents, and audio
        </p>
      </div>
      <div className={styles['card-content']}>
        {card.map((item, index) => (
          <div key={index} className={styles['card-content__item']}>
            <div className={styles['card-content__icon']}>{item.icon}</div>
            <div>
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.button}>
        <Button variant="primary" size="small" onClick={onClick}>
          Get Started
        </Button>
      </div>
    </DialogContent>
  );
}
