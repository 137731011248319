import cn from 'clsx';
import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type ForwardedRef,
  type ReactElement,
  forwardRef,
  useId,
} from 'react';

import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import styles from './TextField.module.scss';

interface TextFieldProps {
  type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search';
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder: string;
  value?: string;
  className?: string;
  name?: string;
  errorMessage?: string;
  endAdornment?: ReactElement;
  backgroundColor?: string;
  variant?: string;
}

function TextFieldComponent(
  {
    name,
    type,
    value,
    onChange,
    placeholder,
    className,
    onBlur,
    errorMessage,
    endAdornment,
    backgroundColor = 'var(--color-zinc-50)',
    variant = '',
  }: TextFieldProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
): ReactElement {
  const id = useId();

  const hasError = errorMessage !== undefined;
  const showPlaceholder = !(
    variant === '-white' &&
    value !== undefined &&
    value?.length > 0
  );

  return (
    <div
      className={cn(
        styles[`text-field${variant}`],
        {
          [styles['text-field--error']]: hasError,
        },
        className,
      )}
    >
      <div
        className={styles[`text-field${variant}__inner`]}
        style={{ backgroundColor }}
      >
        <input
          id={id}
          ref={forwardedRef}
          name={name}
          className={styles[`text-field${variant}__input`]}
          placeholder=""
          value={value}
          onChange={onChange}
          type={type}
          onBlur={onBlur}
        />

        {showPlaceholder && (
          <label htmlFor={id} className={styles[`text-field${variant}__label`]}>
            {placeholder}
          </label>
        )}

        {endAdornment !== undefined && (
          <div className={styles['text-field__end-adornment']}>
            {endAdornment}
          </div>
        )}

        <div className={styles['text-field__outline']}></div>
      </div>

      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}

export const TextField = forwardRef(TextFieldComponent);
