import { useMediaQuery } from '@/hooks/media-query';

export type DrawerMode = 'static' | 'dialog';

export function useDrawerMode(): DrawerMode {
  const drawerStaticModeBreakpoint = useMediaQuery('(min-width: 1280px)'); // @media (--xl-n-above)
  const drawerMode: DrawerMode = drawerStaticModeBreakpoint
    ? 'static'
    : 'dialog';

  return drawerMode;
}
