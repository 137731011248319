import type { ReactElement } from 'react';

import CheckIcon from '../../../../assets/check-icon.svg?react';
import styles from './FormValidate.module.scss';

interface FormValidateType {
  isValid: boolean | null;
  text: string;
}

export function FormValidate({
  isValid,
  text,
}: FormValidateType): ReactElement {
  const getClassName = (): string => {
    if (isValid === null) return styles.default;
    return isValid ? styles.success : styles.error;
  };

  return (
    <div className={`${styles.container} ${getClassName()}`}>
      <CheckIcon className={styles.icon} />
      <div className={styles.text}>{text}</div>
    </div>
  );
}
