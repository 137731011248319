import type {
  ApiMessageItemType,
  MessageItemType,
  MessageListType,
} from '../types/message';

export const convertItemToApiMessage = ({
  role,
  content,
  documents,
}: MessageItemType): ApiMessageItemType => ({
  role,
  content: content ?? '-',
  ...(documents !== undefined && { documents }),
});

export function calculateHistoryToken(messages: MessageListType): number {
  return messages.reduce((acc, { token }) => {
    return acc + (token ?? 0);
  }, 0);
}

export function limitMessageHistoryByToken({
  tokenLimit,
  messages,
  regenerate = false,
}: {
  tokenLimit: number;
  messages: MessageListType;
  regenerate?: boolean;
}): ApiMessageItemType[] {
  const totalToken = calculateHistoryToken(messages);
  const initialState = {
    exceededToken: totalToken - tokenLimit,
    lastUserMessage: null as ApiMessageItemType | null,
    filteredMessages: [] as ApiMessageItemType[],
  };

  const limitedMessages = messages.reduce((acc, message) => {
    const currentToken = message?.token ?? 0;

    // Handle token exceeding case
    if (currentToken > 0 && acc.exceededToken > 0) {
      acc.exceededToken -= currentToken;

      // If it's an assistant, we also remove its paired user message
      if (message.role === 'assistant') {
        acc.lastUserMessage = null;
      }

      // Skip adding the current assistant message
      return acc;
    }

    // For user messages, store the message temporarily in lastUserMessage
    if (message.role === 'user') {
      acc.lastUserMessage = convertItemToApiMessage(message);
      return acc; // Don't add the user message yet
    }

    // For assistant messages, add both the assistant and stored user message
    if (message.role === 'assistant' && acc.lastUserMessage !== null) {
      acc.filteredMessages.push(
        acc.lastUserMessage, // Add paired user message first
        convertItemToApiMessage(message),
      );
      acc.lastUserMessage = null; // Reset the stored user message
    }

    return acc;
  }, initialState).filteredMessages;

  const lastMessageIndex = limitedMessages.length - 1;
  if (regenerate && limitedMessages[lastMessageIndex]?.role === 'assistant') {
    return limitedMessages.slice(0, lastMessageIndex);
  }

  return limitedMessages;
}
