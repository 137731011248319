import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type RootState } from '@/providers/store';

import { type User } from '../types/user';

interface AuthStore {
  user: User | null;
}

const initialState: AuthStore = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<NonNullable<AuthStore['user']>>) {
      state.user = payload;
    },
    logOut() {
      // This is handled by `rootReducer`.
    },
    setPhotoUrl(
      state,
      { payload }: PayloadAction<NonNullable<AuthStore['user']>['photoUrl']>,
    ) {
      if (state.user !== null) {
        state.user.photoUrl = payload;
      }
    },
  },
});

export const { setUser, logOut, setPhotoUrl } = authSlice.actions;
export const authReducer = authSlice.reducer;

export const selectAuth = (state: RootState): AuthStore => state.auth;
