import { type ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import { useExperiment } from '@/features/experiment';
import { appConfig } from '@/providers/config';

import { AltLoginForm } from '../containers/AltLoginForm/AltLoginForm';
import { LoginForm } from '../containers/LoginForm/LoginForm';

export function LoginRoute(): ReactElement {
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const { experiment } = useExperiment();

  useEffect(() => {
    logBigQueryEvent('lnd_login');
  }, [logBigQueryEvent]);

  useEffect(() => {
    logEvent('lnd_login');
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - {appConfig.name}</title>
      </Helmet>

      {experiment === 'current-flow' ? <LoginForm /> : <AltLoginForm />}
    </>
  );
}
