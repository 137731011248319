import cn from 'clsx';
import { type ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './AccountNavItem.module.scss';

export function AccountNavItem({
  to,
  title,
}: {
  to: string;
  title: string;
}): ReactElement {
  const location = useLocation();

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(styles['nav-item'], {
          [styles['nav-item--selected']]:
            isActive ||
            (to === '/account/settings' && location.pathname === '/account'),
        })
      }
    >
      {title}
    </NavLink>
  );
}
