import { zodResolver } from '@hookform/resolvers/zod';
import { type ReactElement, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Form, TextField } from '@/components';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import styles from './RequestEmail.module.scss';

interface PasswordlessLoginFormType {
  email: string;
}

export function RequestEmail({
  onEmailSent,
}: {
  onEmailSent?: (email: string) => Promise<void> | void;
}): ReactElement {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordlessLoginFormType>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(0, "Email can't be empty.")
          .email('Email is invalid'),
      }),
    ),
  });

  const handleSignInEmail: SubmitHandler<PasswordlessLoginFormType> = async ({
    email,
  }) => {
    setLoading(true);
    if (onEmailSent !== undefined) {
      await onEmailSent(email);
    }
    setLoading(false);
  };

  return (
    <div className={styles['request-email']}>
      <p className={styles['request-email__description']}>
        Please provide your email for confirmation
      </p>
      <Form
        className={styles.form}
        onSubmit={handlePromiseEvent(handleSubmit(handleSignInEmail))}
      >
        <TextField
          type="email"
          placeholder="Email"
          errorMessage={errors.email?.message}
          {...register('email')}
        />

        <Button loading={loading} size="large" variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </div>
  );
}
