import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
// TODO: `regenerator-runtime` dependency needs to be removed once
// the issue is resolved in react-speech-recognition library.
// See https://github.com/JamesBrill/react-speech-recognition/issues/196
import 'regenerator-runtime/runtime';

import { App } from './routes/_app';

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
