import { type ReactElement } from 'react';

import { LandingWhiteLayout } from '@/layouts/LandingWhiteLayout/LandingWhiteLayout';

import { LandingWhiteCommentsAnimation } from '../../components/LandingWhiteComments/LandingWhiteComments';
import { LandingWhiteForgotPasswordForm } from '../../components/LandingWhiteForgotPassword/LandingWhiteForgotPasswordForm';
import styles from './LandingForgotPassword.module.scss';

export function LandingWhiteForgotPassword(): ReactElement {
  return (
    <LandingWhiteLayout
      leftChild={<LandingWhiteCommentsAnimation />}
      rightChild={
        <div className={styles.container}>
          <div className={styles.content}>
            <LandingWhiteForgotPasswordForm />
          </div>
        </div>
      }
    />
  );
}
