import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox } from '@radix-ui/themes';
import { type ReactElement, useEffect } from 'react';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import Arrow from '@/assets/arrow.svg?react';
import {
  Button,
  Form,
  InlineLink,
  PasswordField,
  TextField,
} from '@/components';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import usePathType from '@/hooks/path-type';
import { auth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { createFormError } from '../../utils/create-form-error';
import styles from './LandingLoginForm.module.scss';

interface LoginFormType {
  email: string;
  password: string;
}

// TODO: Refactor this to EmailLogin.
export function LandingWhiteLoginForm(): ReactElement {
  const [signInWithEmailAndPassword, , loginLoading, loginError] =
    useSignInWithEmailAndPassword(auth);
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const path = usePathType();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(0, "Email can't be empty.")
          .email('Email is invalid'),
        password: z
          .string()
          .min(0, "Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      }),
    ),
  });

  const handleLogin: SubmitHandler<LoginFormType> = async ({
    email,
    password,
  }) => {
    logBigQueryEvent('btn_login_continue_email');
    logEvent('btn_login_continue_email');

    await signInWithEmailAndPassword(email, password);

    // Force sign-out if email is not verified.
    if (auth.currentUser?.emailVerified === false) {
      await auth.signOut();

      setError('email', {
        type: 'custom',
        message: 'Please verify your email address.',
      });
    }
  };

  useEffect(() => {
    if (loginError === undefined) {
      return;
    }

    const error = createFormError(loginError.code);

    if (error === null) {
      toast.error(loginError.message);
      return;
    }

    if (error.target === 'email' || error.target === 'password') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [loginError, setError]);

  const emailValue = watch('email', '');
  const passwordValue = watch('password', '');

  return (
    <div className={styles['login-form']}>
      <Form
        className={styles.form}
        onSubmit={handlePromiseEvent(handleSubmit(handleLogin))}
      >
        <div>
          <p className={styles.description}> Email</p>
          <TextField
            variant="-white"
            backgroundColor="#fff"
            type="email"
            placeholder="Enter your email"
            errorMessage={errors.email?.message}
            value={emailValue}
            {...register('email')}
          />
        </div>
        <div>
          <p className={styles.description}>Password</p>
          <PasswordField
            variant="-white"
            backgroundColor="#fff"
            placeholder="Enter your password"
            errorMessage={errors.password?.message}
            value={passwordValue}
            {...register('password')}
          />
        </div>
        <div className={styles['forgot-password']}>
          <div className={styles.checkbox}>
            <Checkbox />
            <p className={styles.remember}> Remember me for 30 days</p>
          </div>
          <InlineLink href={`/${path}/forgot-password`}>
            Forgot Password
          </InlineLink>
        </div>
        <Button
          className={styles.button}
          size="large"
          variant="primary"
          loading={loginLoading}
          type="submit"
        >
          <b>Sign in</b>
          <Arrow className={styles.arrow} />
        </Button>
      </Form>

      <div className={styles['sign-up-info']}>
        <span>Don&apos;t have an account?</span>{' '}
        <InlineLink href={`/${path}/register`}>Sign up</InlineLink>
      </div>
    </div>
  );
}
