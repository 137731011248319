import { paymentApi } from '@/providers/api';

interface CustomerPayload {
  email: string;
}

interface CreateCustomerResponse {
  success: string;
}

const customerApi = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    createCustomer: builder.mutation<CreateCustomerResponse, CustomerPayload>({
      query: (payload) => ({
        url: `/customer/create`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useCreateCustomerMutation } = customerApi;
