import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Tabs from '@radix-ui/react-tabs';
import { type ReactElement, useMemo, useState } from 'react';

import { Icon } from '@/components';
import type { PaymentMethod, SubscriptionStatus } from '@/features/payment';
import { useMediaQuery } from '@/hooks/media-query';

import styles from './MyPlanTabs.module.scss';

interface MyPlanTabsProps {
  subscriptionAvailable: boolean;
  status: SubscriptionStatus;
  paymentMethod: PaymentMethod | null;
}

type TabType = 'plan' | 'billing' | 'invoice';

const tabs = {
  plan: 'Subscription History',
  billing: 'Billing Details',
  invoice: 'Invoices',
};

export function MyPlanTabs({
  subscriptionAvailable,
  status,
  paymentMethod,
}: MyPlanTabsProps): ReactElement {
  const tabBreakpoint = useMediaQuery('(min-width: 768px)');

  const tabList = useMemo(() => {
    const list: TabType[] = ['plan'];

    if (subscriptionAvailable && status === 'active') {
      list.push('billing');
    }

    if (subscriptionAvailable && paymentMethod === 'paddle') {
      list.push('invoice');
    }

    return list;
  }, [subscriptionAvailable, status, paymentMethod]);
  const [tab, setTab] = useState<TabType>('plan');

  if (tabBreakpoint) {
    return (
      <Tabs.List className={styles['tabs-list']}>
        {tabList.map((tabItem, index) => (
          <Tabs.Trigger
            key={index}
            className={styles['tabs-trigger']}
            value={tabItem}
            onClick={() => {
              setTab(tabItem);
            }}
          >
            {tabs[tabItem]}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
    );
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className={styles['dropdown-trigger']}>
          {tabs[tab]}{' '}
          <span className={styles.arrow}>
            <Icon icon={faChevronDown} />
          </span>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles['dropdown-content']}
          sideOffset={10}
        >
          <Tabs.List>
            {tabList.map((tabItem, index) => (
              <DropdownMenu.Item
                key={index}
                onClick={() => {
                  setTab(tabItem);
                }}
              >
                <Tabs.Trigger className={styles.item} value={tabItem}>
                  {tabs[tabItem]}
                </Tabs.Trigger>
              </DropdownMenu.Item>
            ))}
          </Tabs.List>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
