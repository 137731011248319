import { paymentApi } from '@/providers/api';

import type { SubscriptionType } from '../types/subscription';

export interface SubscriptionResponse {
  success: boolean;
  data: SubscriptionType;
}

const transactionApi = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    cancelSubscription: builder.mutation<SubscriptionType, string>({
      query: () => ({
        url: `/subscription/cancel`,
        method: 'GET',
      }),
      transformResponse: (response: SubscriptionResponse) => response.data,
    }),
    getSubscription: builder.query<SubscriptionType, string>({
      query: () => ({
        url: `/subscription/get`,
        method: 'GET',
      }),
      transformResponse: (response: SubscriptionResponse) => response.data,
    }),
    updateSubscription: builder.mutation<SubscriptionType, string>({
      query: () => ({
        url: `/subscription/update`,
        method: 'GET',
      }),
      transformResponse: (response: SubscriptionResponse) => response.data,
    }),
  }),
});

export const {
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
  useUpdateSubscriptionMutation,
} = transactionApi;
