import LogoAiAppComponent from '@/assets/logo-aiapp.svg?react';
import LogoAiAppAiComponent from '@/assets/logo-aiappai.svg?react';
import AltLogoComponent from '@/assets/logo-alt.svg?react';
import LogoComponent from '@/assets/logo-chatbot.svg?react';
import LogoNovaComponent from '@/assets/logo-nova.svg?react';

const config = {
  chatbotapp: {
    name: 'Chatbot App',
    logo: LogoComponent,
    altLogo: AltLogoComponent,
    landing: 'https://chatbotapp.ai',
    supportMail: 'support@chatbotapp.ai',
    xEventEnabled: true,
    firebaseEventEnabled: true,
    growthbookEnabled: true,
    engineName: 'chatbotapp',
    featureFlags: {
      webBrowsing: true,
      retainCancelFlow: true,
    },
  },
  novaapp: {
    name: 'Nova',
    logo: LogoNovaComponent,
    altLogo: AltLogoComponent,
    landing: 'https://novaapp.ai',
    supportMail: 'websupport@novaapp.ai',
    xEventEnabled: false,
    firebaseEventEnabled: true,
    growthbookEnabled: false,
    engineName: 'nova',
    featureFlags: {
      webBrowsing: true,
      retainCancelFlow: true,
    },
  },
  aiapp: {
    name: 'AI App',
    logo: LogoAiAppComponent,
    altLogo: AltLogoComponent,
    landing: 'https://aiapp.org',
    supportMail: 'support@aiapp.org',
    xEventEnabled: true,
    firebaseEventEnabled: true,
    growthbookEnabled: false,
    engineName: 'aiapp',
    featureFlags: {
      webBrowsing: true,
      retainCancelFlow: true,
    },
  },
  aiappai: {
    name: 'AI App',
    logo: LogoAiAppAiComponent,
    altLogo: AltLogoComponent,
    landing: 'https://aiapp.ai',
    supportMail: 'support@aiapp.ai',
    xEventEnabled: false,
    firebaseEventEnabled: true,
    growthbookEnabled: false,
    engineName: 'aiappai',
    featureFlags: {
      webBrowsing: true,
      retainCancelFlow: true,
    },
  },
};

export const appConfig = config[import.meta.env.VITE_APP_NAME];
