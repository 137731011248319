import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import { auth } from './firebase';
import { type RootState } from './store';

export const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_BASE_URL}/api`,
  headers: {},
  prepareHeaders: async (headers, { getState }) => {
    const user = (getState() as RootState).auth.user;
    const firebaseUser = auth.currentUser;

    if (user !== null && firebaseUser !== null) {
      const idToken = await firebaseUser.getIdToken();

      headers.set('X_User_Id', user.uid);
      headers.set('X_Token', idToken);
      headers.set('X_Platform', 'web');
    }

    return headers;
  },
});

export const eventQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_FIREBASE_EVENT_API_URL}`,
  headers: {
    'X-App': 'chatbotapp',
    X_Platform: 'web',
  },
});

export const paymentQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_PAYMENT_API_URL}/api`,
  headers: {},
  prepareHeaders: async (headers, { getState }) => {
    const user = (getState() as RootState).auth.user;
    const firebaseUser = auth.currentUser;

    if (user !== null && firebaseUser !== null) {
      const idToken = await firebaseUser.getIdToken();

      headers.set('X-Token', idToken);
      headers.set('X-Platform', 'web');
    }

    return headers;
  },
});
