import { faCheck, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import cn from 'clsx';
import {
  type ForwardedRef,
  type MouseEventHandler,
  type ReactElement,
  forwardRef,
} from 'react';

import { Icon, Text } from '@/components';
import { usePremium } from '@/features/account';
import type { ConversationModel } from '@/features/conversations';

import { ModelBadge } from '../../../../components/ModelBadge/ModelBadge';
import { useSelectedModel } from '../../../../contexts/selected-model';
import styles from './ModelSelectItem.module.scss';

function ModelSelectItemComponent(
  {
    id,
    title,
    subtitle,
    onClick,
    icon: IconComponent,
    premium,
    standalone,
    upcoming,
    onChangeModel,
  }: {
    id: ConversationModel;
    title: string;
    subtitle: string;
    onClick?: MouseEventHandler<HTMLLabelElement>;
    icon: SvgComponent;
    premium: boolean;
    standalone?: boolean;
    upcoming?: boolean;
    onChangeModel: () => void;
  },
  forwardedRef: ForwardedRef<HTMLLabelElement>,
): ReactElement {
  const { selectedModel, setSelectedModel } = useSelectedModel();
  const { data: isUserPremium } = usePremium();

  const isActive = selectedModel === id;
  const isStandalone = standalone ?? false;

  const badgeType = (() => {
    if (!isStandalone) {
      return null;
    }

    if (upcoming === true) {
      return 'upcoming';
    }

    if (isUserPremium === false && premium) {
      return 'pro';
    }

    return null;
  })();

  const isModelLocked = isStandalone && upcoming === true;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <label
      ref={forwardedRef}
      key={id}
      onClick={onClick}
      className={cn(styles['model-select-item'], {
        [styles['model-select-item--active']]: isActive,
        [styles['model-select-item--standalone']]: standalone,
      })}
    >
      <input
        type="radio"
        className={cn(styles['model-select-item__input'])}
        checked={isActive}
        onChange={() => {
          setSelectedModel(id);
          onChangeModel();
        }}
      />

      <IconComponent className={styles['model-select-item__model-icon']} />

      <div className={styles['model-select-item__title']}>
        <Text as="span" size={{ initial: '1' }} weight="medium">
          {title}

          {isModelLocked && (
            <Icon
              icon={faLockKeyhole}
              className={styles['model-select-item__lock-icon']}
            />
          )}
        </Text>

        <Text
          as="span"
          size={{ initial: '1' }}
          weight="medium"
          className={styles['model-select-item__subtitle']}
        >
          {subtitle}
        </Text>
      </div>

      {badgeType !== null && (
        <ModelBadge
          type={badgeType}
          className={styles['model-select-item__model-badge']}
        />
      )}

      {isActive && !isStandalone && (
        <Icon
          icon={faCheck}
          className={cn(
            styles['model-select-item__check-icon'],
            styles['model-select-item__check-icon--checked'],
          )}
        />
      )}
    </label>
  );
}

export const ModelSelectItem = forwardRef(ModelSelectItemComponent);
