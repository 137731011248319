import { zodResolver } from '@hookform/resolvers/zod';
import type { AuthError } from 'firebase/auth';
import { type ReactElement, useEffect, useState } from 'react';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Button, Form, InlineLink, TextField } from '@/components';
import usePathType from '@/hooks/path-type';
import { auth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { createFormError } from '../../utils/create-form-error';
import styles from './LandingForgotPasswordForm.module.scss';

interface ForgotPasswordFormType {
  email: string;
}

export function LandingForgotPasswordForm(): ReactElement {
  const [emailSent, setEmailSent] = useState(false);
  const [sendPasswordResetEmail, sendPasswordLoading, sendPasswordError] =
    useSendPasswordResetEmail(auth);

  const path = usePathType();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ForgotPasswordFormType>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .trim()
          .min(0, "Email can't be empty.")
          .email('Email is invalid'),
      }),
    ),
  });

  useEffect(() => {
    if (sendPasswordError === undefined) {
      return;
    }

    const error = createFormError((sendPasswordError as AuthError)?.code);

    if (error === null) {
      toast.error(sendPasswordError.message);
      return;
    }

    if (error.target === 'email') {
      setError(error.target, {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [sendPasswordError, setError]);

  const handleForgotPassword: SubmitHandler<ForgotPasswordFormType> = async ({
    email,
  }) => {
    const success = await sendPasswordResetEmail(email);

    setEmailSent(success);
  };

  return (
    <div className={styles['forgot-password-form']}>
      {emailSent && (
        <p>Check your email for instructions to reset your password.</p>
      )}

      {!emailSent && (
        <Form
          className={styles.form}
          onSubmit={handlePromiseEvent(handleSubmit(handleForgotPassword))}
        >
          <TextField
            type="email"
            placeholder="Email"
            errorMessage={errors.email?.message}
            {...register('email')}
          />

          <Button
            size="large"
            variant="primary"
            loading={sendPasswordLoading}
            type="submit"
          >
            <b>Continue</b>
          </Button>
        </Form>
      )}

      <div className={styles['login-info']}>
        <InlineLink href={`/${path}/login`}>Back to Login</InlineLink>
      </div>
    </div>
  );
}
