import { type ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import { LandingWrapper } from '../../account/components/LandingWrapper/LandingWrapper';
import { LandingGpt4oLogin } from '../containers/LandingGpt4oLogin/LandingGpt4oLogin';
import { LandingLogin } from '../containers/LandingLogin/LandingLogin';
import { LandingWhiteLogin } from '../containers/LandingWhiteLogin/LandingWhiteLogin';

export function LandingLoginRoute(): ReactElement {
  const { logBigQueryEvent } = useLogBigQueryEvent();

  const path = usePathType();

  useEffect(() => {
    logBigQueryEvent('lnd_login');
  }, [logBigQueryEvent]);

  useEffect(() => {
    logEvent('lnd_login');
  }, []);

  let renderComponent;
  if (path === 'landing') {
    renderComponent = <LandingLogin />;
  } else if (
    path === 'landing-gpt4o' ||
    path === 'landing-3' ||
    path === 'landing-image' ||
    path === 'landing-pdf' ||
    path === 'landing-aiwriting'
  ) {
    renderComponent = <LandingGpt4oLogin />;
  } else {
    renderComponent = (
      <LandingWrapper
        VariantA={LandingGpt4oLogin}
        VariantB={LandingWhiteLogin}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Login - {appConfig.name}</title>
      </Helmet>
      {renderComponent}
    </>
  );
}
