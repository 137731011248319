import { updateProfile } from 'firebase/auth';
import {
  deleteObject,
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from 'firebase/storage';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { selectAuth, setPhotoUrl } from '@/features/auth';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { auth as firebaseAuth, storage } from '@/providers/firebase';

export function useDeleteProfilePicture(): {
  deleteProfilePicture: () => Promise<void>;
  loading: boolean;
  error: Error | null;
} {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteProfilePicture = async (): Promise<void> => {
    if (
      user === null ||
      firebaseAuth.currentUser === null ||
      user.photoUrl === null
    ) {
      return;
    }

    setError(null);
    setLoading(true);

    const photoUrl = new URL(user.photoUrl);
    const photoUrlParts = decodeURIComponent(photoUrl.pathname).split('/');
    const fileName = photoUrlParts[photoUrlParts.length - 1];

    const profilePictureFolderRef = storageRef(
      storage,
      `/user-avatars/${user.uid}/${fileName}`,
    );

    try {
      // Try to remove the object and silent errors
      // eslint-disable-next-line no-console
      await deleteObject(profilePictureFolderRef).catch(console.error);

      await updateProfile(firebaseAuth.currentUser, {
        photoURL: '',
      });

      dispatch(setPhotoUrl(null));
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    deleteProfilePicture,
    loading,
    error,
  };
}

export function useUploadProfilePicture(): {
  uploadProfilePicture: (file: File) => Promise<void>;
  loading: boolean;
  error: Error | null;
} {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const uploadProfilePicture = async (file: File): Promise<void> => {
    if (user === null || firebaseAuth.currentUser === null) {
      return;
    }

    // Reset error state
    setError(null);

    const maxFileSize = 4 * 1024 * 1024; // 4MiB

    if (file.size > maxFileSize) {
      setError(new Error('File size cannot be larger than 4MB.'));
      return;
    }

    setLoading(true);

    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1];
    const fileName = `${uuidv4()}.${fileExtension}`;

    const profilePictureRef = storageRef(
      storage,
      `/user-avatars/${user.uid}/${fileName}`,
    );

    const currentPhotoUrl = user.photoUrl;

    try {
      const uploadTask = await uploadBytes(profilePictureRef, file);
      const downloadUrl = await getDownloadURL(uploadTask.ref);

      await updateProfile(firebaseAuth.currentUser, {
        photoURL: downloadUrl,
      });

      dispatch(setPhotoUrl(downloadUrl));

      // Remove previous user photo from storage
      if (currentPhotoUrl !== null) {
        const photoUrl = new URL(currentPhotoUrl);
        const photoUrlParts = decodeURIComponent(photoUrl.pathname).split('/');
        const oldFileName = photoUrlParts[photoUrlParts.length - 1];

        const oldProfilePictureRef = storageRef(
          storage,
          `/user-avatars/${user.uid}/${oldFileName}`,
        );

        // Try to remove the object and silent errors
        // eslint-disable-next-line no-console
        deleteObject(oldProfilePictureRef).catch(console.error);
      }
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadProfilePicture,
    loading,
    error,
  };
}
