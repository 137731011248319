import { ref as databaseRef } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { database } from '@/providers/firebase';

export function usePremium(): {
  data: boolean | null;
  loading: boolean;
  error: Error | undefined;
} {
  const auth = useAppSelector(selectAuth);

  const [value, loading, error] = useObjectVal<boolean>(
    databaseRef(
      database,
      auth.user !== null ? `${auth.user.uid}/premium` : undefined,
    ),
  );

  let transformedValue: boolean | null;

  // NOTE: Following logic is necessary because you can't enforce field types
  // in Firebase Realtime Database. Validation rules only cover client side.
  // We need to handle cases like value is `null` or `undefined` or of a
  // different type or not a set at all.
  if (value === null) {
    // User has no field of `premium` in database. That means user
    // is in free plan and backend has not yet set the value.
    transformedValue = false;
  } else {
    // Resolve to `null` if value is `undefined` which could mean it hasn't
    // loaded yet. This is to standardize return value of this hook.
    if (value === undefined) {
      transformedValue = null;
      // Resolve to `false` if value is not a boolean value. Otherwise
      // use the value as is.
    } else if (typeof value === 'boolean') {
      transformedValue = value;
    } else {
      transformedValue = false;
    }
  }

  return {
    data: transformedValue,
    loading,
    error,
  };
}
