import type { ReactElement } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ResetPassword } from './ResetPassword/ResetPassword';
import { VerifyEmail } from './VerifyEmail/VerifyEmail';

export interface HandlerProps {
  oobCode: string;
}

type ActionMode = 'resetPassword' | 'verifyEmail' | 'recoverEmail';

export function ActionHandler(): ReactElement {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get('mode') as ActionMode | null;
  const oobCode = searchParams.get('oobCode');

  if (mode === null || oobCode === null) return <Navigate to="/" replace />;

  switch (mode) {
    case 'resetPassword':
      return <ResetPassword oobCode={oobCode} />;
    case 'verifyEmail':
      return <VerifyEmail oobCode={oobCode} />;
    default:
      return <Navigate to="/" replace />;
  }
}
