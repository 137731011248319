import {
  ref as databaseRef,
  update as databaseUpdate,
} from 'firebase/database';

import { database } from '@/providers/firebase';
import { store } from '@/providers/store';

export async function removeAllConversations(): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  await databaseUpdate(databaseRef(database), {
    [`${user.uid}/chats`]: null,
    [`${user.uid}/messages`]: null,
  });
}

export async function removeConversation(id: string): Promise<void> {
  const { auth } = store.getState();
  const user = auth.user;

  if (user === null) {
    return;
  }

  await databaseUpdate(databaseRef(database), {
    [`${user.uid}/chats/${id}`]: null,
    [`${user.uid}/messages/${id}`]: null,
  });
}
