import {
  type ReactElement,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  type ConversationModel,
  type UpcomingConversationModel,
  defaultModel,
  supportedModels,
  upcomingModels,
} from '@/features/conversations';

interface SelectedModelContextType {
  selectedModel: ConversationModel;
  setSelectedModel: (model: ConversationModel) => void;
}

const SelectedModelContext = createContext<SelectedModelContextType | null>(
  null,
);

function validateModelParam(
  modelParam: unknown,
): modelParam is ConversationModel {
  return (
    modelParam !== null &&
    (supportedModels.includes(modelParam as ConversationModel) ||
      upcomingModels.includes(modelParam as UpcomingConversationModel))
  );
}

export function SelectedModelProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModelChanged, setIsModelChanged] = useState(false);

  // Reset chat input value when model changes.
  useEffect(() => {
    setIsModelChanged((prevIsModelChanged) => !prevIsModelChanged);
  }, [searchParams]);

  if (isModelChanged) {
    const messageFieldInput = document.querySelector('#message-field-input');

    if (messageFieldInput !== null) {
      // Clear chat input value.
      (messageFieldInput as HTMLInputElement).value = '';
      // Focus chat input.
      (messageFieldInput as HTMLInputElement).focus();
    }
  }

  const location = useLocation();

  const modelParam = searchParams.get('model');
  const isModelValid = validateModelParam(modelParam);
  const selectedModel = isModelValid ? modelParam : defaultModel;

  const setSelectedModel = useCallback(
    (model: ConversationModel): void => {
      searchParams.set('model', model);
      setSearchParams(searchParams, {
        replace: true,
      });
    },
    [setSearchParams, searchParams],
  );

  useEffect(() => {
    const isNewChatRoute = location.pathname === '/';
    const modelParam = searchParams.get('model');

    if (isNewChatRoute && modelParam === null) {
      setSelectedModel(defaultModel);
    }
  }, [location.pathname, searchParams, setSelectedModel]);

  useEffect(() => {
    const isModelValid = validateModelParam(modelParam);

    if (!isModelValid) {
      setSelectedModel(defaultModel);
    }
  }, [modelParam, setSelectedModel]);

  return (
    <SelectedModelContext.Provider value={{ selectedModel, setSelectedModel }}>
      {children}
    </SelectedModelContext.Provider>
  );
}

export function useSelectedModel(): SelectedModelContextType {
  return useContext(SelectedModelContext) as SelectedModelContextType;
}
