import { type ReactElement, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { logEvent, useLogBigQueryEvent } from '@/features/analytics';
import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import { LandingWrapper } from '../../account/components/LandingWrapper/LandingWrapper';
import { LandingGpt4oSignUp } from '../containers/LandingGpt4oSignUp/LandingGpt4oSignUp';
import { LandingSignUp } from '../containers/LandingSignUp/LandingSignUp';
import { LandingWhiteSignUp } from '../containers/LandingWhiteSignUp/LandingWhiteSignUp';

export function LandingRegisterRoute(): ReactElement {
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const path = usePathType();

  useEffect(() => {
    logBigQueryEvent('lnd_register');
  }, [logBigQueryEvent]);

  useEffect(() => {
    logEvent('lnd_register');
  }, []);

  let renderComponent;
  if (path === 'landing') {
    renderComponent = <LandingSignUp />;
  } else if (
    path === 'landing-gpt4o' ||
    path === 'landing-3' ||
    path === 'landing-image' ||
    path === 'landing-pdf' ||
    path === 'landing-aiwriting'
  ) {
    renderComponent = <LandingGpt4oSignUp />;
  } else {
    renderComponent = (
      <LandingWrapper
        VariantA={LandingGpt4oSignUp}
        VariantB={LandingWhiteSignUp}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Sign Up - {appConfig.name}</title>
      </Helmet>
      {renderComponent}
    </>
  );
}
