import { useAutoAnimate } from '@formkit/auto-animate/react';
import { isWithinInterval } from 'date-fns';
import { type ReactElement } from 'react';

import { useRemoteConfig } from '@/contexts';
import { usePremium } from '@/features/account';
import { useAppDispatch, useAppSelector } from '@/hooks';

import {
  markAnnouncementAsRead,
  selectAnnouncements,
} from '../../store/announcements';
import { Announcement } from './Announcement/Announcement';
import styles from './Announcements.module.scss';

export function Announcements(): ReactElement | null {
  const remoteConfig = useRemoteConfig();
  const { data: isPremium } = usePremium();
  const { readAnnouncementIds } = useAppSelector(selectAnnouncements);
  const dispatch = useAppDispatch();
  const [animationParentRef] = useAutoAnimate();

  type Announcement = NonNullable<typeof remoteConfig>['announcements'][number];

  const getAvailableAnnouncement = (): Announcement | null => {
    if (isPremium === null || remoteConfig === null) {
      return null;
    }

    const acceptableSegmentations: Array<Announcement['userSegmentation']> = [
      'all',
      isPremium ? 'pro' : 'free',
    ];

    const firstAvailableAnnouncement = remoteConfig.announcements.find(
      ({ id, startDate, endDate, platforms, userSegmentation }) => {
        return (
          platforms.includes('web') &&
          acceptableSegmentations.includes(userSegmentation) &&
          !readAnnouncementIds.includes(id) &&
          isWithinInterval(new Date(), {
            start: new Date(startDate),
            end: new Date(endDate),
          })
        );
      },
    );

    return firstAvailableAnnouncement ?? null;
  };

  const availableAnnouncement = getAvailableAnnouncement();

  return (
    <div className={styles.announcements} ref={animationParentRef}>
      {availableAnnouncement !== null && (
        <Announcement
          key={availableAnnouncement.id}
          onDismiss={() => {
            dispatch(markAnnouncementAsRead(availableAnnouncement.id));
          }}
        >
          {availableAnnouncement.message}
        </Announcement>
      )}
    </div>
  );
}
