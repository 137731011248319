import { type ReactElement } from 'react';

import { DialogContent, PaywallFeatures } from '@/components';
import { useGetProductQuery } from '@/features/payment';

import { PaywallPlans } from '../../components/PaywallPlans/PaywallPlans';
import styles from './PaywallDialog.module.scss';

export function PaywallDialog(): ReactElement {
  const { isLoading } = useGetProductQuery({
    productId: import.meta.env.VITE_PADDLE_PRODUCT_ID,
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <DialogContent className={styles['paywall-dialog']}>
      <div className={styles['left-section']}>
        <div className={styles.wrapper}>
          <PaywallFeatures compact />
        </div>
      </div>
      <div className={styles['right-section']}>
        <div className={styles.wrapper}>
          <PaywallPlans compact variant="black" checkoutPath="/checkout" />
        </div>
      </div>
    </DialogContent>
  );
}
