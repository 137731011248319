import { ref as databaseRef } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { database } from '@/providers/firebase';

import { type Platform, PlatformSchema } from '../types/account';

const validatePlatform = (platform: unknown): Platform | null => {
  const parsedPlatform = PlatformSchema.safeParse(platform);

  if (!parsedPlatform.success) {
    return null;
  }

  return parsedPlatform.data;
};

export function usePlatform(): {
  data: Platform | null;
  loading: boolean;
  error: Error | undefined;
} {
  const auth = useAppSelector(selectAuth);

  const [value, loading, error] = useObjectVal<string>(
    databaseRef(
      database,
      auth.user !== null ? `${auth.user.uid}/platform` : undefined,
    ),
  );

  const transformedValue: Platform | null = validatePlatform(value);

  return {
    data: transformedValue,
    loading,
    error,
  };
}
