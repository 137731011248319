export function getGoogleAnalyticsClientId(): string | null {
  const glaCookie = document.cookie.match(/_ga=(.+?);/);

  if (glaCookie === null) {
    return null;
  }

  const clientId = glaCookie[1].split('.').slice(-2).join('.');
  return clientId;
}
