import { type ReactElement } from 'react';

import { Logo } from './Logo/Logo';
import { NavMenu } from './NavMenu/NavMenu';
import styles from './PublicHeader.module.scss';

export function PublicHeader(): ReactElement {
  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <div className={styles['header__static-container']}>
          <Logo />
        </div>

        <NavMenu />
      </div>
    </header>
  );
}
