import { faEdit, faMessage } from '@fortawesome/pro-regular-svg-icons';
import cn from 'clsx';
import { type ReactElement, useEffect, useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';

import { Icon } from '@/components';
import { removeConversation } from '@/features/conversations';
import { useDrawerDialog } from '@/features/layouts';

import { type ConversationItemType } from '../../../../types/conversation';
import { ClearConversationButton } from './ClearConversationButton/ClearConversationButton';
import { ConversationActionButton } from './ConversationActionButton/ConversationActionButton';
import styles from './ConversationItem.module.scss';
import { ConversationTitleEditForm } from './ConversationTitleEditForm/ConversationTitleEditForm';

export function ConversationItem({
  id,
  title,
  engine,
}: Pick<ConversationItemType, 'id' | 'title' | 'engine'>): ReactElement {
  const drawer = useDrawerDialog();
  const itemPath =
    engine === undefined ? `/chats/${id}` : `/chats/${id}?model=${engine}`;
  const match = useMatch(`/chats/${id}`);
  const [isEditing, setIsEditing] = useState(false);

  const isActive = match !== null;

  useEffect(() => {
    if (!isActive) {
      setIsEditing(false);
    }
  }, [isActive]);

  return (
    <div
      className={cn(styles['conversation-item'], {
        [styles['conversation-item--selected']]: isActive,
      })}
    >
      {!isEditing ? (
        <>
          <NavLink
            to={itemPath}
            className={styles['conversation-item__link']}
            onClick={() => {
              drawer?.toggle(false);
            }}
          >
            <Icon icon={faMessage} />

            <p className={styles['conversation-item__title']}>{title}</p>
          </NavLink>

          <div className={styles['conversation-item__outline']} />

          {isActive && (
            <>
              <ConversationActionButton
                icon={faEdit}
                onClick={() => {
                  setIsEditing(true);
                }}
              />

              <ClearConversationButton
                title={title}
                onDelete={() => {
                  // eslint-disable-next-line no-console
                  removeConversation(id).catch(console.error);
                }}
              />
            </>
          )}
        </>
      ) : (
        <ConversationTitleEditForm
          id={id}
          title={title}
          onCancel={() => {
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
}
