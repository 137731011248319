import type { ReactElement } from 'react';

import type { Pricing } from '../../../hooks/callback-handler';
import styles from './PricingTable.module.scss';

export function PricingTable({ pricing }: { pricing: Pricing }): ReactElement {
  return (
    <dl className={styles.pricing}>
      <div>
        <dt>Subtotal</dt>
        <dd>{pricing.subtotal}</dd>
      </div>
      <div>
        <dt>Taxes</dt>
        <dd>{pricing.tax ?? 'Calculated at next step'}</dd>
      </div>
      <div>
        <dt className={styles['pricing-total']}>Total price</dt>
        <dd>{pricing.total}</dd>
      </div>
    </dl>
  );
}
