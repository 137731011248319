export async function downloadImage({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}): Promise<void> {
  const response = await fetch(url, {
    referrerPolicy: 'no-referrer',
  });
  const blobImage = await response.blob();

  const href = URL.createObjectURL(blobImage);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = fileName;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  URL.revokeObjectURL(href);
}
