import { zodResolver } from '@hookform/resolvers/zod';
import type { AuthError } from 'firebase/auth';
import { type ReactElement, useEffect } from 'react';
import { useUpdatePassword } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Button, Form, PasswordField } from '@/components';
import {
  createFormError,
  useReauthenticateWithCredential,
} from '@/features/auth';
import { auth as firebaseAuth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionHeader,
  AccountSectionTitle,
} from '../../../components/AccountSection/AccountSection';
import styles from './ChangePassword.module.scss';

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
}

export function ChangePassword(): ReactElement {
  const [updatePassword, updatePasswordLoading, updatePasswordError] =
    useUpdatePassword(firebaseAuth);
  const {
    reauthenticateWithCredential,
    loading: reauthenticateLoading,
    error: reauthenticateError,
  } = useReauthenticateWithCredential();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
    },
    resolver: zodResolver(
      z.object({
        currentPassword: z
          .string()
          .min(1, "Current password can't be empty")
          .min(6, 'Current password needs to be at least 6 characters long.'),
        newPassword: z
          .string()
          .min(1, "New password can't be empty")
          .min(6, 'New password needs to be at least 6 characters long.'),
      }),
    ),
  });

  const handleChangePassword: SubmitHandler<ChangePasswordForm> = async ({
    currentPassword,
    newPassword,
  }) => {
    const isSuccessfullyReauthenticated =
      await reauthenticateWithCredential(currentPassword);

    if (!isSuccessfullyReauthenticated) {
      return;
    }

    const success = await updatePassword(newPassword);

    if (success) {
      toast.success('Your password has been changed.');
    }
  };

  useEffect(() => {
    if (updatePasswordError === undefined) {
      return;
    }

    const error = createFormError((updatePasswordError as AuthError).code);

    if (error === null) {
      toast.error(updatePasswordError.message);
      return;
    }

    if (error.target === 'password') {
      setError('newPassword', {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [updatePasswordError, setError]);

  useEffect(() => {
    if (reauthenticateError === null) {
      return;
    }

    const error = createFormError((reauthenticateError as AuthError)?.code);

    if (error === null) {
      toast.error(reauthenticateError.message);
      return;
    }

    if (error.target === 'password') {
      setError('currentPassword', {
        type: 'custom',
        message: error.message,
      });
    } else {
      toast.error(error.message);
    }
  }, [reauthenticateError, setError]);

  return (
    <Form onSubmit={handlePromiseEvent(handleSubmit(handleChangePassword))}>
      <AccountSection>
        <AccountSectionHeader>
          <AccountSectionTitle>Change Password</AccountSectionTitle>
          <AccountSectionDescription>
            Enter a new password for your account.
          </AccountSectionDescription>
        </AccountSectionHeader>

        <AccountSectionContent className={styles.form}>
          <PasswordField
            placeholder="Current Password"
            errorMessage={errors.currentPassword?.message}
            {...register('currentPassword')}
          />

          <PasswordField
            placeholder="New Password"
            errorMessage={errors.newPassword?.message}
            {...register('newPassword')}
          />
        </AccountSectionContent>

        <AccountSectionFooter variant="normal">
          <AccountSectionFooterActions>
            <Button
              type="submit"
              variant="primary"
              size="small"
              loading={updatePasswordLoading || reauthenticateLoading}
            >
              Change Password
            </Button>
          </AccountSectionFooterActions>
        </AccountSectionFooter>
      </AccountSection>
    </Form>
  );
}
