import { type ReactElement, useState } from 'react';
import { useSendSignInLinkToEmail } from 'react-firebase-hooks/auth';

import { Button } from '@/components';
import { auth as firebaseAuth } from '@/providers/firebase';

import { RequestEmail } from '../RequestEmail/RequestEmail';
import styles from './ResendLink.module.scss';

export function ResendLink({ email }: { email: string | null }): ReactElement {
  const [linkSent, setLinkSent] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<string | null>(email);

  const [
    sendSignInLinkToEmail,
    sendSignInLinkToEmailLoading,
    sendSignInLinkToEmailError,
  ] = useSendSignInLinkToEmail(firebaseAuth);

  const handleResendEmail = (email: string): void => {
    const actionCodeSettings = {
      url: import.meta.env.VITE_FIREBASE_PASSWORDLESS_LOGIN_URL,
      // This must be true.
      handleCodeInApp: true,
    };

    sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        setLinkSent(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  if (linkSent) {
    return (
      <p className={styles.description}>
        Check your email for the sign-in link.
      </p>
    );
  }

  return (
    <div className={styles['resend-link']}>
      <p>The verify link is expired.</p>
      {sendSignInLinkToEmailError !== undefined && (
        <p>{sendSignInLinkToEmailError.message}</p>
      )}
      {currentEmail === null ? (
        <RequestEmail
          onEmailSent={(receivedEmail) => {
            setCurrentEmail(receivedEmail);
          }}
        />
      ) : (
        <Button
          size="large"
          variant="primary"
          loading={sendSignInLinkToEmailLoading}
          onClick={() => {
            handleResendEmail(currentEmail);
          }}
        >
          Resend Email
        </Button>
      )}
    </div>
  );
}
