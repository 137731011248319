import { paymentApi } from '@/providers/api';

import type { ApiPaymentProductType } from '../types/product';

interface GetProductPayload {
  productId: string;
}

interface GetProductResponse {
  success: string;
  data: ApiPaymentProductType[];
}

const productApi = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query<GetProductResponse['data'], GetProductPayload>({
      query: (productId) => ({
        url: `/product/get`,
        method: 'POST',
        body: productId,
      }),
      transformResponse: (response: GetProductResponse) => response.data,
    }),
  }),
});

export const { useGetProductQuery } = productApi;
