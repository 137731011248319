import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import * as Tabs from '@radix-ui/react-tabs';
import { type ReactNode } from 'react';

import { Button, Dialog, DialogTrigger, Icon, Text } from '@/components';
import {
  BillingDetails,
  CancelSubscription,
  Invoices,
  ReactivateSubscription,
  useGetSubscriptionQuery,
  usePaymentMethod,
} from '@/features/payment';
import { StripeForm } from '@/features/plans';
import { appConfig } from '@/providers/config';
import { getTimeDifference } from '@/utils/time-difference';

import { AccountPageContainer } from '../../components/AccountPageContainer/AccountPageContainer';
import { AccountPageHeader } from '../../components/AccountPageHeader/AccountPageHeader';
import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionFooterNote,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionTitle,
} from '../../components/AccountSection/AccountSection';
import { PaywallDialog } from '../../containers/PaywallDialog/PaywallDialog';
import { usePlatform } from '../../services/platform';
import { usePremium } from '../../services/pro';
import styles from './MyPlan.module.scss';
import { MyPlanBadge } from './MyPlanBadge/MyPlanBadge';
import { MyPlanDetail } from './MyPlanDetail/MyPlanDetail';
import { MyPlanTabs } from './MyPlanTabs/MyPlanTabs';

type PlanName = 'free' | 'pro';

interface PlanDetail {
  title: string;
  description: string;
  features: ReactNode[];
}

const plans: Record<PlanName, PlanDetail> = {
  free: {
    title: 'Free Plan',
    description: 'Features included in free plan:',
    features: [
      'Standart features',
      'Normal response times',
      'Customer support',
      'Limited chat history (up to 10)',
      'Limited message length (up to 2000 characters)',
    ],
  },
  pro: {
    title: 'Pro Plan',
    description: 'Features included in pro plan:',
    features: [
      <>
        <strong>Premium AI Models:</strong> Access to cutting-edge language
        models including GPT-4o, Gemini, and Claude.
      </>,
      <>
        <strong>Advanced Tools Suite:</strong> Advanced features like PDF
        processing, link analysis, and enhanced web search.
      </>,
      <>
        <strong>Extended Messages:</strong> Enjoy longer message capabilities
        for detailed exchanges.
      </>,
      <>
        <strong>Fast Responses:</strong> Prioritized, quicker replies for
        efficient communication.
      </>,
      <>
        <strong>Unlimited History:</strong> No limits on chat history access.
      </>,
    ],
  },
};

export function MyPlan(): ReactNode {
  const { data: isPremium, loading: premiumLoading } = usePremium();
  const { data: platform, loading: platformLoading } = usePlatform();
  const { data: paymentMethod, loading: paymentMethodLoading } =
    usePaymentMethod();
  const { data: subscription, error: subscriptionError } =
    useGetSubscriptionQuery('subscription');

  if (
    isPremium === null ||
    premiumLoading ||
    platformLoading ||
    paymentMethodLoading ||
    (subscription === undefined && subscriptionError === undefined)
  ) {
    return null;
  }

  const planName: PlanName =
    ((subscription?.status === 'active' ||
      subscription?.status === 'past_due') &&
      isPremium) ||
    (isPremium && paymentMethod === null)
      ? 'pro'
      : 'free';
  const isActive =
    subscription?.scheduledChange === null &&
    (subscription?.status === 'active' || subscription?.status === 'past_due');
  const plan = plans[planName];

  const showFooterActions =
    !isPremium || platform === 'web' || platform === null;

  const subscriptionAvailable =
    subscription !== undefined && subscription !== null;
  const accountCreatedInLimit =
    subscription?.createdAt !== undefined
      ? getTimeDifference(subscription?.createdAt) < 3
      : false;

  return (
    <AccountPageContainer>
      <AccountPageHeader>My Plan</AccountPageHeader>

      <Tabs.Root defaultValue="plan">
        <MyPlanTabs
          subscriptionAvailable={subscriptionAvailable}
          status={subscription?.status ?? 'paused'}
          paymentMethod={paymentMethod}
        />
        <Tabs.Content className={styles['tabs-content']} value="plan">
          <AccountSection>
            <AccountSectionHeader>
              <AccountSectionHeaderContainer>
                <AccountSectionTitle>
                  {plan.title}
                  <MyPlanBadge
                    plan={
                      subscription?.status === 'past_due'
                        ? 'past_due'
                        : planName
                    }
                  />
                </AccountSectionTitle>

                <AccountSectionDescription>
                  {plan.description}
                </AccountSectionDescription>
              </AccountSectionHeaderContainer>
            </AccountSectionHeader>

            <AccountSectionContent>
              <ul className={styles['feature-list']}>
                {plan.features.map((feature, index) => (
                  <li key={index} className={styles['feature-item']}>
                    <Icon
                      icon={faCheck}
                      fixedWidth
                      className={styles['feature-item__icon']}
                    />

                    <Text
                      as="span"
                      size={{ initial: '1' }}
                      className={styles['feature-item__text']}
                    >
                      {feature}
                    </Text>
                  </li>
                ))}
              </ul>
              {subscriptionAvailable && (
                <>
                  <div className={styles.divider}></div>
                  <MyPlanDetail subscription={subscription} />
                </>
              )}
            </AccountSectionContent>

            {showFooterActions && (
              <AccountSectionFooter variant="normal">
                {planName === 'free' && (
                  <AccountSectionFooterNote>
                    Check out the{' '}
                    <Dialog>
                      <DialogTrigger asChild>
                        <span className={styles['note-emphasized-text']}>
                          PRO
                        </span>
                      </DialogTrigger>

                      <PaywallDialog />
                    </Dialog>{' '}
                    plan for more features
                  </AccountSectionFooterNote>
                )}

                {accountCreatedInLimit ? (
                  <div className={styles['subscription-info']}>
                    <div>
                      <div className={styles['subscription-info__title']}>
                        Your subscription info is updating
                      </div>
                      <div className={styles['subscription-info__description']}>
                        Setting up your new plan may take up to three hours
                        after purchase or switch. If you have any questions,{' '}
                        <a
                          href={`mailto:${
                            appConfig.supportMail
                          }?subject=${encodeURIComponent(
                            appConfig.name,
                          )}%20My%20Plan`}
                        >
                          contact us.
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}

                <AccountSectionFooterActions>
                  {isPremium &&
                    paymentMethod === null &&
                    platform === 'web' && (
                      <StripeForm action="portal" returnUrl="/account/plan">
                        <Button type="submit" variant="secondary" size="small">
                          Manage Subscription
                        </Button>
                      </StripeForm>
                    )}
                  {isActive &&
                    isPremium &&
                    paymentMethod === 'paddle' &&
                    !accountCreatedInLimit && (
                      <CancelSubscription subscriptionID={subscription.id} />
                    )}
                  {!isActive && isPremium && paymentMethod === 'paddle' && (
                    <ReactivateSubscription />
                  )}

                  {planName === 'free' && (
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="primary" size="small">
                          Upgrade Plan
                        </Button>
                      </DialogTrigger>

                      <PaywallDialog />
                    </Dialog>
                  )}
                </AccountSectionFooterActions>
              </AccountSectionFooter>
            )}
          </AccountSection>
        </Tabs.Content>
        <Tabs.Content className={styles['tabs-content']} value="billing">
          <BillingDetails />
        </Tabs.Content>
        <Tabs.Content className={styles['tabs-content']} value="invoice">
          <Invoices />
        </Tabs.Content>
      </Tabs.Root>
    </AccountPageContainer>
  );
}
