import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import cn from 'clsx';
import { type ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './FileDropzone.module.scss';

export function FileDropzone({ visible }: { visible: boolean }): ReactElement {
  return (
    <div
      className={cn(styles['file-dropzone'], {
        [styles['file-dropzone--visible']]: visible,
      })}
    >
      <div className={styles['file-dropzone__icon']}>
        <Icon icon={faArrowDown} fixedWidth />
      </div>

      <p className={styles['file-dropzone__title']}>Add Anything</p>
      <p className={styles['file-dropzone__subtitle']}>
        Drop pdf file here to add it to the conversation
      </p>
    </div>
  );
}
