import type { User as FirebaseUser } from 'firebase/auth';

import { type ProviderId, type User, supportedProviders } from '../types/user';

export function mapFirebaseUserToUser(user: FirebaseUser): User {
  const currentUser = {
    email: user.email,
    uid: user.uid,
    displayName: user.displayName,
    photoUrl: user.photoURL,
    providers: user.providerData
      .filter(({ providerId }) =>
        (supportedProviders as readonly string[]).includes(providerId),
      )
      .map(({ providerId, email }) => ({
        id: providerId as ProviderId,
        email,
      })),
  };

  return currentUser;
}
