import { type ReactElement } from 'react';

import { ProfilePicture } from '@/features/account';
import { type ConversationModel } from '@/features/conversations';

import { type MessageItemType } from '../../../../../types/message';
import { models } from '../../../../../types/models';
import styles from './MessageAvatar.module.scss';

export function MessageAvatar({
  role,
  conversationModel,
  loading = false,
}: {
  role: Pick<MessageItemType, 'role'>['role'];
  conversationModel: ConversationModel;
  loading?: boolean;
}): ReactElement {
  const AssistantLogo = models[conversationModel].icon;

  if (loading) {
    return <div className={styles['message-avatar']} />;
  }

  return (
    <div className={styles['message-avatar']}>
      {role === 'assistant' && <AssistantLogo />}
      {role === 'user' && <ProfilePicture />}
    </div>
  );
}
