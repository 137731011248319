import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import { AlertDialog, Icon, Logo } from '@/components';
import { usePremium } from '@/features/account';

import checkoutBg from '../../assets/checkout-bg.svg';
import { PriceCollapsible } from '../../components/PriceCollapsible/PriceCollapsible';
import { usePaddle } from '../../contexts/paddle';
import { usePaddleCallbackHandler } from '../../hooks/callback-handler';
import { useCreateTransaction } from '../../hooks/create-transaction';
import { useOpenCheckout } from '../../hooks/open-checkout';
import styles from './Checkout.module.scss';
import { CheckoutDetails } from './CheckoutDetails/CheckoutDetails';
import { PaymentForm } from './PaymentForm/PaymentForm';
import { ProcessDialog } from './ProcessDialog/ProcessDialog';

export function Checkout(): ReactElement {
  const { data: isPremium } = usePremium();
  const [searchParams] = useSearchParams();
  const { paddleInstance } = usePaddle();
  const { pricing, checkoutCompleted } = usePaddleCallbackHandler();
  useCreateTransaction({ path: '/checkout' });
  useOpenCheckout();
  const loading = pricing.total === null || paddleInstance === null;
  const checkoutProcessCompleted =
    checkoutCompleted !== undefined && isPremium === true;

  const transaction = searchParams.get('transaction');
  const plan = searchParams.get('plan');

  if (transaction === null && plan === null) {
    <Navigate to="/" />;
  }

  return (
    <div className={styles.checkout}>
      <div className={styles['checkout-payment']}>
        <Link to="/" className={styles['checkout-logo']}>
          <Logo size="small" />
        </Link>
        <PaymentForm />
      </div>
      <div className={styles['checkout-details']}>
        <div className={styles['checkout-details-wrapper']}>
          {!loading && (
            <Link to="/" className={styles['checkout-back']}>
              <Icon icon={faChevronLeft} />
              Back
            </Link>
          )}
          <CheckoutDetails loading={loading} pricing={pricing} />
        </div>
        <img
          src={checkoutBg}
          alt="Checkout"
          className={styles['checkout-image']}
        />
      </div>
      <PriceCollapsible loading={loading} pricing={pricing} />
      <AlertDialog open={checkoutCompleted !== undefined}>
        <ProcessDialog pricing={pricing} complete={checkoutProcessCompleted} />
      </AlertDialog>
    </div>
  );
}
