import { zodResolver } from '@hookform/resolvers/zod';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { type ReactElement, useEffect, useRef, useState } from 'react';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Button, Form, PasswordField } from '@/components';
import { auth as firebaseAuth } from '@/providers/firebase';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { createFormError } from '../../../utils/create-form-error';
import type { HandlerProps } from '../ActionHandler';
import styles from './ResetPassword.module.scss';

interface ResetPasswordFormType {
  password: string;
}

type ResetProcess = 'loading' | 'expired' | 'password' | 'success' | 'error';

export function ResetPassword({ oobCode }: HandlerProps): ReactElement {
  const [process, setProcess] = useState<ResetProcess>('loading');
  const [email, setEmail] = useState<string | null>(null);
  const [signInWithEmailAndPassword, , loginLoading, loginError] =
    useSignInWithEmailAndPassword(firebaseAuth);
  const verifyCode = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    defaultValues: {
      password: '',
    },
    resolver: zodResolver(
      z.object({
        password: z
          .string()
          .min(0, "Password can't be empty")
          .min(6, 'Password needs to be at least 6 characters long.'),
      }),
    ),
  });

  useEffect(() => {
    if (verifyCode.current) return;
    verifyCode.current = true;
    setProcess('loading');
    verifyPasswordResetCode(firebaseAuth, oobCode)
      .then((email) => {
        setEmail(email);
        setProcess('password');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setProcess('expired');
      });
  }, [oobCode]);

  const handlePasswordReset: SubmitHandler<ResetPasswordFormType> = ({
    password,
  }) => {
    confirmPasswordReset(firebaseAuth, oobCode, password)
      .then((resp) => {
        setProcess('success');

        if (email !== null) {
          void signInWithEmailAndPassword(email, password);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setProcess('error');
      });
  };

  useEffect(() => {
    if (loginError === undefined) {
      return;
    }

    const error = createFormError(loginError.code);

    if (error === null) {
      toast.error(loginError.message);
    } else {
      toast.error(error.message);
    }
  }, [loginError]);

  switch (process) {
    case 'loading':
      return <div>Loading...</div>;
    case 'expired':
      return (
        <div className={styles['link-expired']}>
          <h1 className={styles['link-expired__title']}>
            Try resetting your password again
          </h1>
          <p>
            Your request to reset your password has expired or the link has
            already been used
          </p>
        </div>
      );
    case 'success':
      return (
        <div>
          <h1>Password changed</h1>
          <p>You can now sign in with your new password</p>
          {loginLoading && <p>Redirecting...</p>}
        </div>
      );
    case 'error':
      return <div>Something went wrong</div>;
    case 'password':
      return (
        <div className={styles['reset-password']}>
          <h1>Reset Password</h1>
          <p>
            for <strong>{email}</strong>
          </p>
          <Form
            onSubmit={handlePromiseEvent(handleSubmit(handlePasswordReset))}
          >
            <PasswordField
              placeholder="Password"
              errorMessage={errors.password?.message}
              {...register('password')}
            />

            <Button
              className={styles['reset-password__button']}
              size="large"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Form>
        </div>
      );
    default:
      return <Navigate to="/" replace />;
  }
}
