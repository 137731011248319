import cn from 'clsx';
import { type ReactElement } from 'react';

import styles from './ModelBadge.module.scss';

export function ModelBadge({
  type,
  className,
}: {
  type: 'upcoming' | 'pro';
  className?: string;
}): ReactElement {
  const types: Record<typeof type, { text: string }> = {
    upcoming: {
      text: 'Coming Soon',
    },
    pro: {
      text: 'PRO',
    },
  };

  return (
    <span
      className={cn(
        styles['model-badge'],
        styles[`model-badge--type-${type}`],
        className,
      )}
    >
      {types[type].text}
    </span>
  );
}
