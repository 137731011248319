import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { LoginConnections } from '../../containers/LoginConnections/LoginConnections';

export function LoginConnectionsRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Login Connections - {appConfig.name}</title>
      </Helmet>

      <LoginConnections />
    </>
  );
}
