import { CheckoutEventNames, type PaddleEventData } from '@paddle/paddle-js';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

import { Button, Dialog, DialogTrigger } from '@/components';
import {
  Loader,
  PaymentMethodSection,
  useGetTransactionListQuery,
  usePaddle,
} from '@/features/payment';

import { PaymentMethodDialog } from '../../PaymentMethodDialog/PaymentMethodDialog';
import styles from './PaymentMethod.module.scss';

export function PaymentMethod(): ReactElement {
  const { paddleInstance } = usePaddle();
  const [methodDialogOpen, setMethodDialogOpen] = useState<boolean>(false);
  const { data: transactions } = useGetTransactionListQuery({
    statusList: ['completed'],
    refetch: methodDialogOpen,
  });

  const paymentMethod = useMemo(() => {
    if (transactions === undefined) {
      return null;
    }

    const activeTransactions = transactions.flatMap((transaction) =>
      transaction.payments
        .filter(
          (payment) =>
            payment.status === 'captured' || payment.status === 'authorized',
        )
        .map((payment) => payment.methodDetails),
    );

    if (activeTransactions.length === 0) {
      return null;
    }

    return activeTransactions[0];
  }, [transactions]);

  useEffect(() => {
    if (paddleInstance === null) {
      return;
    }

    const onEventCallback = (event: PaddleEventData): void => {
      if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
        setTimeout(() => {
          setMethodDialogOpen(false);
        }, 1000);
      }
    };

    paddleInstance.Update({
      eventCallback: onEventCallback,
    });

    return () => {
      paddleInstance.Update({
        eventCallback: undefined,
      });
    };
  }, [paddleInstance]);

  if (transactions === undefined) {
    return <Loader />;
  }

  return (
    <div className={styles['payment-method']}>
      {paymentMethod !== null && (
        <PaymentMethodSection method={paymentMethod} />
      )}
      <div className={styles.divider}></div>
      <Dialog onOpenChange={setMethodDialogOpen} open={methodDialogOpen}>
        <DialogTrigger asChild>
          <Button variant="primary" size="small">
            Change Payment Method
          </Button>
        </DialogTrigger>

        <PaymentMethodDialog />
      </Dialog>
    </div>
  );
}
