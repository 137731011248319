import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { Checkout } from '../../containers/Checkout/Checkout';

export function CheckoutRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Account Settings - {appConfig.name}</title>
      </Helmet>

      <Checkout />
    </>
  );
}
