import { z } from 'zod';

export const CurrencyCodeSchema = z.enum([
  'USD',
  'EUR',
  'GBP',
  'JPY',
  'AUD',
  'CAD',
  'CHF',
  'HKD',
  'SGD',
  'SEK',
  'ARS',
  'BRL',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'HUF',
  'ILS',
  'INR',
  'KRW',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'RUB',
  'THB',
  'TRY',
  'TWD',
  'UAH',
  'ZAR',
]);

export type CurrencyCodeType = z.infer<typeof CurrencyCodeSchema>;
