import type { ReactElement } from 'react';

import { DialogContent } from '@/components';

import { PaymentMethod } from './PaymentMethod/PaymentMethod';
import styles from './PaymentMethodDialog.module.scss';

export function PaymentMethodDialog(): ReactElement {
  return (
    <DialogContent className={styles['payment-method-dialog']}>
      <PaymentMethod />
    </DialogContent>
  );
}
