import * as Tooltip from '@radix-ui/react-tooltip';
import cn from 'clsx';
import { type ForwardedRef, type ReactElement, forwardRef } from 'react';

import { Text } from '@/components';

import styles from './DictationTooltip.module.scss';

function DictationTooltipComponent(
  {
    variant,
  }: {
    variant: 'default' | 'listening' | 'not-available';
  },
  forwardedRef: ForwardedRef<HTMLDivElement>,
): ReactElement {
  const contents: Record<typeof variant, string> = {
    default: 'Click to dictate',
    listening: 'Listening...',
    'not-available': 'Dictation is not available',
  };

  return (
    <Tooltip.Content
      ref={forwardedRef}
      sideOffset={8}
      className={cn(
        styles['dictation-tooltip'],
        styles[`dictation-tooltip--variant-${variant}`],
      )}
    >
      <Text
        as="p"
        weight="semi-bold"
        size={{
          initial: '0',
        }}
      >
        {contents[variant]}
      </Text>

      <Tooltip.Arrow className={styles['dictation-tooltip__arrow']} />
    </Tooltip.Content>
  );
}

export const DictationTooltip = forwardRef(DictationTooltipComponent);
