import cn from 'clsx';
import type { ReactElement, ReactNode } from 'react';

import { Heading, Text } from '@/components';

import styles from './AccountSectionHeader.module.scss';

export function AccountSectionHeader({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement {
  return (
    <div className={cn(styles['account-section-header'], className)}>
      {children}
    </div>
  );
}

export function AccountSectionHeaderContainer({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className={styles['account-section-header-container']}>{children}</div>
  );
}

export function AccountSectionTitle({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <Heading
      as="h2"
      size={{
        initial: '3',
        md: '4',
      }}
      weight="semi-bold"
      className={styles['account-section-title']}
    >
      {children}
    </Heading>
  );
}

export function AccountSectionDescription({
  children,
}: {
  children: string;
}): ReactElement {
  return (
    <Text
      as="p"
      size={{
        initial: '1',
        md: '2',
      }}
      className={styles['account-section-description']}
    >
      {children}
    </Text>
  );
}
