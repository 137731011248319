export type ProviderId = 'google.com' | 'apple.com' | 'password';
export type SocialProviderId = Exclude<ProviderId, 'password'>;

export const supportedProviders: ProviderId[] = [
  'google.com',
  'apple.com',
  'password',
];

type Email = string | null;

export interface Provider {
  id: ProviderId;
  email: Email;
}

export interface SocialProvider {
  id: SocialProviderId;
  email: Email;
}

export interface User {
  uid: string;
  email: Email;
  displayName: string | null;
  photoUrl: string | null;
  providers: Provider[];
}
