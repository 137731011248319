import {
  faArrowDownToLine,
  faCheck,
  faDownload,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCopy,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { type ReactElement, useEffect, useState } from 'react';
import { PhotoView } from 'react-photo-view';

import { Button, Icon, Text } from '@/components';
import { storage } from '@/providers/firebase';

import {
  ImageState,
  type MessageImageType,
} from '../../../../../../../types/message';
import styles from './ImageItem.module.scss';

export function ImageItem({
  url,
  prompt,
  state,
  onClickRetry,
  onClickDownload,
}: MessageImageType & {
  onClickRetry: () => void;
  onClickDownload: () => void;
}): ReactElement {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (url === null || state !== ImageState.Success) {
      setImageUrl(null);
      return;
    }

    void (async () => {
      const ref = storageRef(storage, url);
      const imageUrl = await getDownloadURL(ref);

      setImageUrl(imageUrl);
    })();
  }, [url, state]);

  const isError = state === ImageState.Error;

  const handleCopyPrompt = (): void => {
    if (prompt.length > 0) {
      void navigator.clipboard.writeText(prompt).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      });
    }
  };

  return (
    <div className={styles['image-item']}>
      {isError && (
        <div className={styles['image-item-error']}>
          <div className={styles['image-item-error__icon']}>
            <Icon icon={faTriangleExclamation} />
          </div>

          <Text
            as="p"
            size={{ initial: '0', sm: '1', lg: '2' }}
            className={styles['image-item-error__text']}
          >
            Image generation error
          </Text>

          <Button
            variant="white-outline"
            size="small"
            onClick={() => {
              onClickRetry();
            }}
          >
            <Icon icon={faRotateRight} />
            Retry
          </Button>
        </div>
      )}
      {imageUrl !== null && (
        <PhotoView
          render={() => (
            <div className={styles['photo-view-container']}>
              <div></div>
              <img
                className={styles['photo-view-container__image']}
                src={imageUrl}
                alt={`${prompt}`}
              />

              <div className={styles['photo-view-caption']}>
                <div className={styles['photo-view-caption__container']}>
                  <h4>Prompt</h4>
                  <p className={styles['photo-view-caption__prompt']}>
                    {prompt}
                  </p>
                  <div className={styles['photo-view-caption__buttons']}>
                    <Button
                      size="small"
                      variant="secondary"
                      onClick={handleCopyPrompt}
                    >
                      <Icon
                        size="lg"
                        icon={isCopied ? faCheck : faCopy}
                        fixedWidth
                      />{' '}
                      Copy Prompt
                    </Button>
                    <Button
                      size="small"
                      variant="primary"
                      onClick={onClickDownload}
                    >
                      <Icon icon={faArrowDownToLine} fixedWidth size="lg" />
                      Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        >
          <img
            src={imageUrl}
            alt={prompt}
            className={styles['image-item-image']}
          />
        </PhotoView>
      )}

      {imageUrl !== null && (
        <button
          className={styles['image-item__download']}
          aria-label="Download"
          onClick={onClickDownload}
        >
          <Icon icon={faDownload} fixedWidth />
        </button>
      )}
    </div>
  );
}
