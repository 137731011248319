import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { AccountSettings } from '../../containers/AccountSettings/AccountSettings';

export function AccountSettingsRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Account Settings - {appConfig.name}</title>
      </Helmet>

      <AccountSettings mode="settings" />
    </>
  );
}
