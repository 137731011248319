import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import { LandingWrapper } from '../../account/components/LandingWrapper/LandingWrapper';
import { LandingForgotPassword } from '../containers/LandingForgotPassword/LandingForgotPassword';
import { LandingWhiteForgotPassword } from '../containers/landingWhiteForgotPassword/LandingWhiteForgotPassword';

export function LandingForgotPasswordRoute(): ReactElement {
  const path = usePathType();

  let renderComponent;
  if (
    path === 'landing' ||
    path === 'landing-gpt4o' ||
    path === 'landing-3' ||
    path === 'landing-image' ||
    path === 'landing-pdf' ||
    path === 'landing-aiwriting'
  ) {
    renderComponent = <LandingForgotPassword />;
  } else {
    renderComponent = (
      <LandingWrapper
        VariantA={LandingForgotPassword}
        VariantB={LandingWhiteForgotPassword}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password - {appConfig.name}</title>
      </Helmet>
      {renderComponent}
    </>
  );
}
