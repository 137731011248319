import { z } from 'zod';

import { api } from '@/providers/api';

export interface RecurringType {
  interval: 'week' | 'month' | 'year';
  intervalCount: number;
}

type GetProductsType = Array<{
  lookupKey: string;
  amount: number;
  recurring: RecurringType;
}>;

const ProductSchema = z.object({
  lookup_key: z.string(),
  unit_amount: z.number(),
  recurring: z.object({
    interval: z.enum(['week', 'month', 'year']),
    interval_count: z.number(),
  }),
});

const ProductsResponseSchema = z.object({
  data: z.object({
    data: z.array(ProductSchema),
  }),
});

type ProductsResponseSchemaType = z.infer<typeof ProductsResponseSchema>;

const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<
      GetProductsType,
      {
        type: 'in-app-purchase' | 'discount';
      }
    >({
      query: ({ type }) => ({
        url: `/payment/products`,
        params: {
          type,
        },
        responseHandler: async (response) =>
          await ProductsResponseSchema.parseAsync(await response.json()),
      }),
      transformResponse: ({ data: { data } }: ProductsResponseSchemaType) => {
        return data
          .map((product) => ({
            lookupKey: product.lookup_key,
            amount: product.unit_amount,
            recurring: {
              interval: product.recurring.interval,
              intervalCount: product.recurring.interval_count,
            },
          }))
          .sort((a, b) => (a.amount > b.amount ? 1 : -1));
      },
    }),
  }),
});

export const { useGetProductsQuery } = productsApi;
