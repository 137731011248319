import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type RootState } from '@/providers/store';

interface AnnouncementsStore {
  readAnnouncementIds: number[];
}

const initialState: AnnouncementsStore = {
  readAnnouncementIds: [],
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    markAnnouncementAsRead(
      state,
      { payload: announcementId }: PayloadAction<number>,
    ) {
      state.readAnnouncementIds.push(announcementId);
    },
  },
});

export const { markAnnouncementAsRead } = announcementsSlice.actions;
export const announcementsReducer = announcementsSlice.reducer;

export const selectAnnouncements = (state: RootState): AnnouncementsStore =>
  state.announcements;
