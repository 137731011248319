import * as Dialog from '@radix-ui/react-dialog';
import { type ReactElement, type ReactNode } from 'react';

import { useDrawerDialog } from '../../../contexts/drawer-dialog';
import { Header } from '../../Header/Header';
import styles from './DrawerDialog.module.scss';

export function DrawerDialog({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const drawer = useDrawerDialog();

  return (
    <Dialog.Root open={drawer?.open} onOpenChange={drawer?.toggle}>
      <Header mode="dialog" />

      <Dialog.Portal>
        <Dialog.Overlay className={styles['drawer-dialog-overlay']} />

        <Dialog.Content
          className={styles['drawer-dialog-content']}
          onCloseAutoFocus={(event) => {
            if (drawer?.preventAutoFocus === true) {
              event.preventDefault();
            }
          }}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
