import { ref as databaseRef, set as databaseSet } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { database } from '@/providers/firebase';

export function useEventStatus(
  eventName:
    | 'firstMessageSent'
    | 'firstSuccessfulResponseReceived'
    | 'premiumSuccesfullyGranted',
): [boolean | null, (newValue: boolean) => Promise<void>] {
  const auth = useAppSelector(selectAuth);

  const [value] = useObjectVal<boolean>(
    databaseRef(
      database,
      auth.user !== null ? `${auth.user.uid}/events/${eventName}` : undefined,
    ),
  );

  let transformedValue: boolean | null;

  // NOTE: Following logic is necessary because you can't enforce field types
  // in Firebase Realtime Database. Validation rules only cover client side.
  // We need to handle cases like value is `null` or `undefined` or of a
  // different type or not a set at all.
  if (value === null) {
    // User has no field of `premium` in database. That means user
    // is in free plan and backend has not yet set the value.
    transformedValue = false;
  } else {
    // Resolve to `null` if value is `undefined`. This is to standardize
    // return value of this hook.
    if (value === undefined) {
      transformedValue = null;
      // Resolve to `false` if value is not a boolean value. Otherwise
      // use the value as is.
    } else if (typeof value === 'boolean') {
      transformedValue = value;
    } else {
      transformedValue = false;
    }
  }

  async function setEventStatus(newValue: boolean): Promise<void> {
    if (auth.user === null) {
      return;
    }

    const firstSuccessfulResponseReceivedRef = databaseRef(
      database,
      `${auth.user.uid}/events/${eventName}`,
    );

    await databaseSet(firstSuccessfulResponseReceivedRef, newValue);
  }

  return [transformedValue, setEventStatus];
}
