import type { ReactElement } from 'react';
import { toast } from 'react-toastify';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Form,
} from '@/components';
import { removeAllConversations } from '@/features/conversations';

import {
  AccountSection,
  AccountSectionDescription,
  AccountSectionFooter,
  AccountSectionFooterActions,
  AccountSectionFooterNote,
  AccountSectionHeader,
  AccountSectionHeaderContainer,
  AccountSectionSpacer,
  AccountSectionTitle,
} from '../../../components/AccountSection/AccountSection';

export function ClearAllConversations(): ReactElement {
  const clearAllConversations = async (): Promise<void> => {
    await removeAllConversations();
    toast.success('Your chat history has been deleted.');
  };

  return (
    <AccountSection>
      <AccountSectionHeader>
        <AccountSectionHeaderContainer>
          <AccountSectionTitle>Clear All Chats</AccountSectionTitle>
          <AccountSectionDescription>
            This will permanently delete your chat history.
          </AccountSectionDescription>
        </AccountSectionHeaderContainer>
      </AccountSectionHeader>

      <AccountSectionSpacer />

      <AccountSectionFooter variant="destructive">
        <AccountSectionFooterNote>
          This action is irreversible
        </AccountSectionFooterNote>

        <AccountSectionFooterActions>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="destructive" size="small">
                Clear All Chats
              </Button>
            </AlertDialogTrigger>

            <AlertDialogContent>
              <Form
                onSubmit={() => {
                  // eslint-disable-next-line no-console
                  clearAllConversations().catch(console.error);
                }}
              >
                <AlertDialogTitle>
                  Are you sure to clear all chats?
                </AlertDialogTitle>

                <AlertDialogDescription>
                  Are you sure you want to clear all chats? This action cannot
                  be undone.
                </AlertDialogDescription>

                <AlertDialogActions>
                  <AlertDialogCancel asChild>
                    <Button size="large" type="button" variant="secondary">
                      Cancel
                    </Button>
                  </AlertDialogCancel>

                  <AlertDialogAction asChild>
                    <Button size="large" type="submit" variant="destructive">
                      Yes, delete all chats
                    </Button>
                  </AlertDialogAction>
                </AlertDialogActions>
              </Form>
            </AlertDialogContent>
          </AlertDialog>
        </AccountSectionFooterActions>
      </AccountSectionFooter>
    </AccountSection>
  );
}
