import Cookies from 'js-cookie';
import { type ReactElement, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { usePremium } from '@/features/account';
import { selectAuth } from '@/features/auth';
import { useExperiment } from '@/features/experiment';
import {
  ChatDrawerContent,
  DrawerDialog,
  DrawerDialogProvider,
  DrawerStatic,
  Header,
  useDrawerMode,
} from '@/features/layouts';
import { useAppSelector, useFirebaseIdToken } from '@/hooks';

import styles from './UserLayout.module.scss';

export function UserLayout(): ReactElement {
  const auth = useAppSelector(selectAuth);
  const firebaseToken = useFirebaseIdToken();
  const location = useLocation();
  const drawerMode = useDrawerMode();
  const { data: isPremium, loading: premiumLoading } = usePremium();
  const { experiment } = useExperiment();

  const loggedIn = auth.user !== null;
  const alternateFlow = experiment === 'alternative-flow';
  const landingFlow = experiment === 'landing-flow';
  const landingSkip = Cookies.get('landing_skip') === 'true';
  const path =
    localStorage.getItem('path') !== null
      ? localStorage.getItem('path')
      : 'landing';

  if (premiumLoading) {
    return <></>;
  }

  if (
    alternateFlow &&
    loggedIn &&
    (isPremium === false || isPremium === null)
  ) {
    return <Navigate to="/choose-plan" />;
  } else if (
    landingFlow &&
    loggedIn &&
    !landingSkip &&
    (isPremium === false || isPremium === null)
  ) {
    return <Navigate to={`/${path}/plan`} />;
  } else if (landingFlow && !loggedIn) {
    return <Navigate to={`/${path}/login`} />;
  } else if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (firebaseToken?.userToken === null) {
    return <></>;
  }

  return (
    <div className={styles['user-layout']}>
      {drawerMode === 'dialog' && (
        <DrawerDialogProvider>
          <DrawerDialog>
            <ChatDrawerContent mode="dialog" />
          </DrawerDialog>
        </DrawerDialogProvider>
      )}

      {drawerMode === 'static' && <Header mode="static" />}

      <div className={styles['user-layout__wrapper']}>
        {drawerMode === 'static' && (
          <DrawerStatic>
            <ChatDrawerContent mode="static" />
          </DrawerStatic>
        )}

        <main className={styles.main}>
          <Suspense>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  );
}
