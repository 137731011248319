import { type ReactElement, useMemo } from 'react';

import { Button, Table } from '@/components';
import {
  AccountSection,
  AccountSectionContent,
  AccountSectionDescription,
  AccountSectionHeader,
  AccountSectionSpacer,
  AccountSectionTitle,
} from '@/features/account';
import {
  Loader,
  useGetTransactionInvoiceMutation,
  useGetTransactionListQuery,
} from '@/features/payment';

import styles from './Invoices.module.scss';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export function Invoices(): ReactElement {
  const { data: transactions } = useGetTransactionListQuery({
    statusList: ['billed', 'completed'],
  });
  const [getTransactionInvoice, { isLoading: invoiceLoading }] =
    useGetTransactionInvoiceMutation();

  const invoicedTransactions = useMemo(() => {
    if (transactions === undefined) {
      return undefined;
    }

    return transactions?.filter(
      (transaction) => transaction.invoiceNumber !== null,
    );
  }, [transactions]);

  const getInvoice = (transactionId: string, invoiceNumber: string): void => {
    try {
      void getTransactionInvoice({ transactionId })
        .unwrap()
        .then((response) => {
          const link = document.createElement('a');
          link.href = response;
          link.setAttribute('download', `${invoiceNumber}.pdf`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to download invoice', error);
    }
  };

  if (invoicedTransactions === undefined) {
    return <Loader />;
  }

  return (
    <>
      <AccountSectionTitle>Invoices</AccountSectionTitle>
      <AccountSectionDescription>
        Itemized statements of service charges
      </AccountSectionDescription>
      <AccountSectionSpacer />
      <AccountSection>
        <AccountSectionHeader>
          <AccountSectionTitle>Paid Invoice Receipts</AccountSectionTitle>
        </AccountSectionHeader>

        <AccountSectionContent>
          <Table>
            <>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {invoicedTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>#{transaction.invoiceNumber}</td>
                    <td>
                      {dateFormatter.format(
                        new Date(transaction.billedAt ?? ''),
                      )}
                    </td>
                    <td className={styles['table-actions']}>
                      <Button
                        loading={invoiceLoading}
                        onClick={() => {
                          getInvoice(
                            transaction.id,
                            transaction.invoiceNumber ?? 'invoice',
                          );
                        }}
                        variant={'white-outline'}
                        size="small"
                      >
                        Download
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          </Table>
        </AccountSectionContent>
      </AccountSection>
    </>
  );
}
