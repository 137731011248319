import {
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/pro-solid-svg-icons';
import * as Tooltip from '@radix-ui/react-tooltip';
import cn from 'clsx';
import {
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import { Icon } from '@/components';

import styles from './DictationButton.module.scss';
import { DictationTooltip } from './DictationTooltip/DictationTooltip';

export function DictationButton({
  onReceiveTranscript,
  onListeningStart,
  onListeningEnd,
}: {
  onListeningStart: () => void;
  onListeningEnd: () => void;
  onReceiveTranscript: (transcript: string) => void;
}): ReactElement {
  const { transcript, listening, isMicrophoneAvailable, resetTranscript } =
    useSpeechRecognition();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (listening) {
      onReceiveTranscript(transcript);
    }
  }, [onReceiveTranscript, transcript, listening, resetTranscript]);

  const startListening = async (): Promise<void> => {
    if (!isMicrophoneAvailable) {
      return;
    }

    onListeningStart();
    buttonRef.current?.focus();
    await SpeechRecognition.startListening({
      continuous: true,
    });
  };

  const stopListening = useCallback(async () => {
    await SpeechRecognition.stopListening();
    resetTranscript();
    onListeningEnd();
  }, [resetTranscript, onListeningEnd]);

  const currentVariant = (() => {
    if (!isMicrophoneAvailable) {
      return 'not-available';
    } else if (listening) {
      return 'listening';
    }

    return 'default';
  })();

  return (
    <Tooltip.Root open={tooltipOpen || listening}>
      <Tooltip.Trigger asChild>
        <button
          ref={buttonRef}
          type="button"
          onMouseEnter={() => {
            setTooltipOpen(true);
          }}
          onMouseLeave={() => {
            setTooltipOpen(false);
          }}
          onFocus={() => {
            setTooltipOpen(true);
          }}
          onBlur={() => {
            setTooltipOpen(false);

            if (listening && isMicrophoneAvailable) {
              void stopListening();
            }
          }}
          onMouseDown={(event) => {
            // 'mousedown' will cause a blur event by default, we need
            // to prevent it to let the 'click' handler do its job.
            event.preventDefault();
          }}
          onClick={(event) => {
            event.stopPropagation();

            if (!listening) {
              void startListening();

              // Focus if it's not focused.
              if (document.activeElement !== event.currentTarget) {
                event.currentTarget.focus();
              }
            } else {
              setTooltipOpen(false);
              void stopListening();
            }
          }}
          onKeyUp={(event) => {
            if (event.key === 'Escape') {
              void stopListening();
            }
          }}
          className={cn(
            styles['dictation-button'],
            styles[`dictation-button--variant-${currentVariant}`],
          )}
        >
          <Icon
            icon={isMicrophoneAvailable ? faMicrophone : faMicrophoneSlash}
            fixedWidth
          />
        </button>
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <DictationTooltip variant={currentVariant} />
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}
