import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

export function NotFoundRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Not Found - {appConfig.name}</title>
      </Helmet>

      <div>404</div>
    </>
  );
}
