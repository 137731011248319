import type { ReactNode } from 'react';

import Image from '../../../../../assets/phone.png';
import styles from './Phone.module.scss';

export function Phone(): ReactNode {
  return (
    <div className={styles.container}>
      <div>
        <h3>Power of AI in your Pocket</h3>
        <img src={Image} alt="phone" />
      </div>
    </div>
  );
}
