import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import cn from 'clsx';
import {
  type ButtonHTMLAttributes,
  type ForwardedRef,
  type ReactElement,
  forwardRef,
} from 'react';

import { Icon } from '@/components';

import styles from './ConversationActionButton.module.scss';

function ConversationActionButtonComponent(
  {
    icon,
    onClick,
    type = 'button',
    disabled = false,
  }: {
    icon: IconProp;
    onClick?: () => void;
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
    disabled?: boolean;
  },
  forwardedRef: ForwardedRef<HTMLButtonElement>,
): ReactElement {
  return (
    <button
      // `tabIndex` is needed for the blur event to work properly.
      // See https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
      tabIndex={0}
      type={type}
      className={cn(styles['conversation-action-button'], {
        [styles['conversation-action-button--disabled']]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
      ref={forwardedRef}
    >
      <Icon icon={icon} />
    </button>
  );
}

export const ConversationActionButton = forwardRef(
  ConversationActionButtonComponent,
);
