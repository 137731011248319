import type { ReactElement, ReactNode } from 'react';

import styles from './AccountSectionFooterActions.module.scss';

export function AccountSectionFooterActions({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className={styles['account-section-footer-actions']}>{children}</div>
  );
}
