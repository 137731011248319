import { useSearchParams } from 'react-router-dom';

export function useWelcomePro(): {
  isPaymentSuccessful: boolean;
  clearPaymentSuccessfulParam: () => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();

  let isPaymentSuccessful = false;

  if (searchParams.get('success') === 'true') {
    isPaymentSuccessful = true;
  }

  const clearPaymentSuccessfulParam = (): void => {
    searchParams.delete('success');
    setSearchParams(searchParams);
  };

  return {
    isPaymentSuccessful,
    clearPaymentSuccessfulParam,
  };
}
