import { type ReactElement, type ReactNode } from 'react';

import styles from './LandingLayout.module.scss';

export function LandingLayout({
  leftChild,
  rightChild,
}: {
  leftChild: ReactNode;
  rightChild: ReactNode;
}): ReactElement {
  return (
    <div className={styles.layout}>
      <div className={styles.left__section}>{leftChild}</div>
      <div className={styles.right__section}>{rightChild}</div>
    </div>
  );
}
