import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'clsx';
import {
  type ComponentProps,
  type ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';
import ScrollToBottom from 'react-scroll-to-bottom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { Dialog, Form } from '@/components';
import { PaywallDialog, usePremium } from '@/features/account';
import {
  logEvent,
  useEventStatus,
  useLogBigQueryEvent,
} from '@/features/analytics';
import { Announcements } from '@/features/announcements';
import {
  type ConversationModel,
  type UpcomingConversationModel,
  defaultModel,
  upcomingModels,
  updateConversationModel,
} from '@/features/conversations';
import { useAppParams } from '@/hooks';
import { handlePromiseEvent } from '@/utils/handle-promise-event';

import { useSelectedModel } from '../../contexts/selected-model';
import useModelSelection from '../../helpers/model-selection';
import { useFileUpload } from '../../services/file-upload';
import {
  ImageState,
  MessageDocumentSchema,
  type MessageDocumentType,
  type MessageItemType,
  documentsModel,
  maximumAllowedDocumentSize,
} from '../../types/message';
import { models } from '../../types/models';
import styles from './Chat.module.scss';
import { ChatActions } from './ChatActions/ChatActions';
import { FileDropzone } from './FileDropzone/FileDropzone';
import { MessageField } from './MessageField/MessageField';
import { RedirectedMessage } from './MessageField/RedirectedMessage/RedirectedMessage';
import { MessageList } from './MessageList/MessageList';
import { ModelSelect } from './ModelSelect/ModelSelect';
import { contents } from './ModelSelect/contents';

interface ChatFormType {
  input: string;
  file?: MessageDocumentType;
}

export function Chat({
  sending,
  answering,
  disabled,
  regenerateDisabled,
  chatLimitReached,
  messages,
  onSendMessage,
  onRegenerateResponse,
  onStopGenerating,
  onRetryImageGeneration,
}: {
  sending: boolean;
  answering: boolean;
  disabled: boolean;
  regenerateDisabled: boolean;
  chatLimitReached: boolean;
  messages: MessageItemType[];
  onSendMessage: ({
    content,
    documents,
    model,
    update,
  }: {
    content: string;
    documents?: MessageDocumentType[];
    model: ConversationModel;
    update?: boolean;
  }) => void;
  onRegenerateResponse: (message?: string) => void;
  onStopGenerating: () => void;
  onRetryImageGeneration: ComponentProps<typeof MessageList>['onClickRetry'];
}): ReactElement {
  const [firstMessageSent, setFirstMessageSent] =
    useEventStatus('firstMessageSent');
  const { logBigQueryEvent } = useLogBigQueryEvent();
  const [isPaywallOpen, setPaywallOpen] = useState(false);
  const [showText, setShowText] = useState<boolean>(false);

  const {
    uploadFile,
    selectedFile,
    selectedFiles,
    removeFile,
    fileUploadState,
    resetSelectedFile,
  } = useFileUpload();

  const messageFieldRef = useRef<HTMLTextAreaElement | null>(null);
  const { data: isPremium } = usePremium();
  const messageDataRef = useRef({
    startPortion: '',
    endPortion: '',
  });

  const { selectedModel, setSelectedModel } = useSelectedModel();

  const [dragging, setDragging] = useState(false);

  const params = useAppParams(
    z.object({
      id: z.string(),
    }),
  );

  const {
    register,
    handleSubmit,
    reset: resetForm,
    setValue,
    getValues,
    setFocus,
    formState,
    trigger,
  } = useForm<ChatFormType>({
    defaultValues: {
      input: '',
    },
    resolver: zodResolver(
      z
        .object({
          input: z.string().trim().optional(),
          file: MessageDocumentSchema,
        })
        .or(z.object({ input: z.string().trim().min(1) })),
    ),
  });

  const loading = sending || answering;

  const updatedModel = useModelSelection(selectedModel, selectedFiles);

  const modelRef = useRef(selectedModel);

  const handleSendMessage: SubmitHandler<ChatFormType> = ({ input }) => {
    // Disable form submission in Safari.
    if (loading) return;

    if (models[selectedModel].premium) {
      // Disable form submission if user premium information has not been loaded
      if (isPremium === null) {
        return;
      }

      if (!isPremium) {
        setPaywallOpen(true);
        return;
      }
    }
    if (updatedModel !== selectedModel) {
      modelRef.current = selectedModel;
    }

    if (lastMessage?.error?.type === 'display-options') {
      onRegenerateResponse(input);
    } else if (models[selectedModel].filesUploadSupport) {
      if (params?.id !== undefined && selectedFiles.length > 0) {
        void updateConversationModel({
          model: updatedModel,
          conversationId: params.id,
        });
      }
      setSelectedModel(selectedFiles.length > 0 ? updatedModel : selectedModel);

      setShowText(
        updatedModel !== 'gemini' && updatedModel !== selectedModel && true,
      );
      onSendMessage({
        update: selectedFiles.length > 0,
        content: input,
        model: selectedFiles.length > 0 ? updatedModel : selectedModel,
        ...(selectedFiles.length > 0 && {
          documents: selectedFiles.filter(
            (file) => file !== null && 'url' in file,
          ) as MessageDocumentType[],
        }),
      });
    } else {
      onSendMessage({
        content: input,
        model: selectedModel,
        ...(selectedFile !== null &&
          'url' in selectedFile && { documents: [selectedFile] }),
      });
    }

    resetForm();
    resetSelectedFile();

    logBigQueryEvent('btn_send', {
      modelTypeDisplayed: selectedModel,
    });

    logEvent('btn_send', {
      is_premium: isPremium,
      modelTypeDisplayed: selectedModel,
    });

    if (firstMessageSent === false) {
      logBigQueryEvent('web_first_message_sent');
      logEvent('web_first_message_sent');

      void setFirstMessageSent(true);
    }
  };

  useEffect(() => {
    if (
      !loading &&
      messages.length === 0 &&
      selectedModel === 'superbot' &&
      params?.id === undefined
    ) {
      setSelectedModel(defaultModel);
    }

    if (selectedModel !== updatedModel) {
      setShowText(false);
    }
  }, [
    loading,
    messages.length,
    params?.id,
    selectedModel,
    setSelectedModel,
    updatedModel,
  ]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowText(false);
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectedModel]);

  const lastMessage = messages[messages.length - 1];

  // If the last message is sent from the assistant, this means it's users' turn to reply.
  const userTurn = lastMessage?.role === 'assistant';

  const lastMessageHasError =
    lastMessage?.error !== undefined &&
    lastMessage.error.type !== 'display-options';
  const hasMessages = messages.length > 0;

  const { ref: registerRef, ...restOfRegisterProps } = register('input');

  const updateInputHeight = (): void => {
    // Manually trigger the input event to update the text-area's height.
    messageFieldRef.current?.dispatchEvent(
      new Event('input', {
        bubbles: true,
      }),
    );
  };

  const handleListeningStart = (): void => {
    const messageFieldElement = messageFieldRef.current;

    if (messageFieldElement === null) {
      return;
    }

    const { selectionStart, selectionEnd } = messageFieldElement;
    const currentText = getValues('input');
    messageDataRef.current.startPortion = currentText.slice(0, selectionStart);
    messageDataRef.current.endPortion = currentText.slice(selectionEnd);
  };

  const handleListeningEnd = (): void => {
    const cursorIndex = getValues('input').length;
    const endPortionLength = messageDataRef.current.endPortion.length;
    const newCursorPosition = cursorIndex - endPortionLength;

    setFocus('input');

    if (messageFieldRef.current !== null) {
      messageFieldRef.current.setSelectionRange(
        newCursorPosition,
        newCursorPosition,
      );
    }

    messageDataRef.current.startPortion = '';
    messageDataRef.current.endPortion = '';

    // Manually trigger input validation.
    // eslint-disable-next-line no-console
    trigger('input').catch(console.error);
  };

  const handleReceiveTranscript = (transcript: string): void => {
    const startPortion = messageDataRef.current.startPortion;
    const endPortion = messageDataRef.current.endPortion;

    const newText = startPortion + transcript + endPortion;
    setValue('input', newText);

    updateInputHeight();
  };

  useEffect(() => {
    if (selectedFile === null) {
      selectedFiles.forEach((file) => {
        if (file !== null && 'url' in file) {
          setValue('file', file, { shouldValidate: true });
        } else {
          setValue('file', undefined, { shouldValidate: true });
        }
      });
    } else if ('url' in selectedFile) {
      setValue('file', selectedFile, { shouldValidate: true });
    }
  }, [selectedFile, selectedFiles, setValue]);

  const prevModelRef = useRef(selectedModel);

  useEffect(() => {
    if (prevModelRef.current !== selectedModel) {
      removeFile();
      prevModelRef.current = selectedModel;
    }
  }, [selectedModel, removeFile]);

  const focusMessageFieldInput = (): void => {
    const messageFieldInput = document.querySelector('#message-field-input');

    if (messageFieldInput !== null) {
      // Focus chat input.
      (messageFieldInput as HTMLInputElement).focus();
    }
  };

  const isModelImageGenerator = selectedModel === 'image-generator';
  const lastMessageImages = messages[messages.length - 1]?.images ?? [];

  const isImageGenerationInProgress =
    isModelImageGenerator &&
    lastMessageImages.some(({ state }) => state === ImageState.Loading);

  const isModelAvailable = !upcomingModels.includes(
    selectedModel as UpcomingConversationModel,
  );

  const isMessageFieldDisabled = (() => {
    if (lastMessage?.error?.type === 'display-options') {
      return !formState.isValid;
    }

    if (selectedModel !== 'document') {
      if (models[selectedModel].filesUploadSupport === true) {
        const isFilesSelected = selectedFiles.every(
          (item) => item?.fileUploadState === 'success',
        );

        return !isFilesSelected;
      } else {
        return disabled;
      }
    }
    const isFileSelected = selectedFile !== null;
    const isFileUploadSuccessful = fileUploadState === 'success';

    const isMessageFieldDisabledForDocument =
      (isFileSelected && !isFileUploadSuccessful) ||
      (!isFileSelected && !formState.isValid) ||
      (!isFileSelected && messages.length === 0);

    return isMessageFieldDisabledForDocument || disabled;
  })();

  const shouldPreventFileDrop =
    (!models[selectedModel].filesUploadSupport &&
      models[selectedModel].fileUploadSupport !== true) ||
    lastMessage?.error?.type === 'display-options' ||
    isPremium === false;

  const totalNumberOfDocuments = messages.reduce(
    (acc, message) =>
      acc + (message.documents !== undefined ? message.documents.length : 0),
    0,
  );

  const onClickOption = (message: string): void => {
    onRegenerateResponse(message);
  };

  const canAddDocumentFile =
    totalNumberOfDocuments < 10 &&
    lastMessage?.error?.type !== 'display-options';

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <section
      className={styles.chat}
      onDragOver={(event) => {
        if (shouldPreventFileDrop) {
          return;
        }

        setDragging(true);

        // Prevent the default action to open the file in the browser.
        event.preventDefault();
      }}
      onDragLeave={() => {
        if (shouldPreventFileDrop) {
          return;
        }

        setDragging(false);
      }}
      onDrop={(event) => {
        if (shouldPreventFileDrop) {
          return;
        }

        setDragging(false);

        // Prevent the default action to open the file in the browser.
        event.preventDefault();

        if (!canAddDocumentFile) {
          toast.error('Maximum of 10 documents can be added.');
          return;
        }

        const file = event.dataTransfer.files[0];
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (file.size > maximumAllowedDocumentSize) {
          toast.error('File size should be less than 20MB.');
          return;
        }

        if (models[selectedModel].filesUploadSupport) {
          if (
            documentsModel.some((model) => model.mimeType === file.type) ||
            fileExtension === 'xlsx'
          ) {
            void uploadFile(file);
            focusMessageFieldInput();
          } else {
            toast.error('Unsupported file type.');
          }
        } else {
          if (file.type === 'application/pdf') {
            void uploadFile(file);
            focusMessageFieldInput();
          } else {
            toast.error('Only PDF files are supported.');
          }
        }
      }}
    >
      {hasMessages && (
        <ScrollToBottom
          className={styles['chat-scroller']}
          scrollViewClassName={styles['chat-scroller__scroll']}
          initialScrollBehavior="auto"
        >
          <div className={styles['chat-messages']}>
            <div className={styles['chat-messages__inner']}>
              <MessageList
                messages={messages}
                onClickRetry={onRetryImageGeneration}
                onClickOption={onClickOption}
              />
            </div>
          </div>

          <div className={styles['chat-field-placeholder']} />
        </ScrollToBottom>
      )}

      <div
        className={cn({
          [styles['chat-information-wrapper']]: !hasMessages && params === null,
        })}
      >
        {!hasMessages && params === null && (
          <div className={styles['chat-information']}>
            <div className={styles['chat-information__scroll']}>
              <Announcements />

              <ModelSelect
                onClickExample={(message) => {
                  setValue('input', message);
                  setFocus('input');

                  // Manually trigger input validation.
                  // eslint-disable-next-line no-console
                  trigger('input').catch(console.error);

                  updateInputHeight();
                }}
                onChangeModel={() => {
                  // If model changes and the prompt text field is empty,
                  // then reset the form.
                  if (getValues('input') !== '') {
                    resetForm();
                  }
                }}
                onSelectFile={(file: File) => {
                  void uploadFile(file);
                  focusMessageFieldInput();
                }}
                onRequestPaywall={() => {
                  setPaywallOpen(true);
                }}
              />

              <div className={styles['chat-field-placeholder']} />
            </div>
          </div>
        )}
      </div>

      {isModelAvailable && (
        <div className={styles['chat-field']}>
          <div className={styles['chat-field__inner']}>
            {hasMessages && (
              <ChatActions
                showRegenerateButton={
                  userTurn &&
                  !loading &&
                  !isImageGenerationInProgress &&
                  !regenerateDisabled &&
                  !chatLimitReached
                }
                showStopGeneratingButton={answering && !isModelImageGenerator}
                onClickRegenerateResponse={onRegenerateResponse}
                onClickStopGenerating={onStopGenerating}
                hasError={lastMessageHasError}
              />
            )}
            {showText && (
              <RedirectedMessage
                currentModel={contents[modelRef.current].title}
                newModel={contents[updatedModel].title}
                onClick={() => {
                  setShowText(false);
                }}
              />
            )}

            {!lastMessageHasError && !chatLimitReached && (
              <Form
                onSubmit={handlePromiseEvent(handleSubmit(handleSendMessage))}
                className={styles['chat-field__form']}
              >
                <MessageField
                  onListeningStart={handleListeningStart}
                  onListeningEnd={handleListeningEnd}
                  onReceiveTranscript={handleReceiveTranscript}
                  loading={loading}
                  disabled={isMessageFieldDisabled}
                  fileUploadDisabled={
                    lastMessage?.error?.type === 'display-options'
                  }
                  ref={mergeRefs([registerRef, messageFieldRef])}
                  selectedFiles={selectedFiles}
                  selectedFile={selectedFile}
                  onSelectFile={(file: File) => {
                    void uploadFile(file);
                    focusMessageFieldInput();
                  }}
                  onClickRemoveSelectedDocument={(fileName?: string) => {
                    removeFile(fileName);
                  }}
                  fileUploadState={fileUploadState}
                  canAddDocumentFile={canAddDocumentFile}
                  onRequestPaywall={() => {
                    setPaywallOpen(true);
                  }}
                  {...restOfRegisterProps}
                />
              </Form>
            )}
          </div>
        </div>
      )}
      {(selectedModel === 'document' ||
        models[selectedModel].filesUploadSupport) &&
        lastMessage?.error?.type !== 'display-options' && (
          <FileDropzone visible={dragging} />
        )}

      <Dialog
        open={isPaywallOpen}
        onOpenChange={(open) => {
          setPaywallOpen(open);

          if (open) {
            logBigQueryEvent('lnd_paywall');
            logEvent('lnd_paywall', {
              paywallType: 'in_app',
            });
          }
        }}
      >
        <PaywallDialog />
      </Dialog>
    </section>
  );
}
