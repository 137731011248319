import type { ReactElement, ReactNode } from 'react';

import Check from '@/assets/check.svg?react';
import LoadingSvg from '@/assets/circle.svg?react';

import styles from './WebMessage.module.scss';

export default function WebMessage({
  loading,
  showDone,
  animating,
  showMessageContent,
  messageContent,
}: {
  loading: boolean;
  showDone: boolean;
  animating: boolean;
  showMessageContent: boolean;
  messageContent: ReactNode;
}): ReactElement {
  if (loading) {
    return (
      <div className={styles.spinner__container}>
        <LoadingSvg className={styles.circle} />
        <span className={styles.circle__text}>Searching</span>
      </div>
    );
  }

  if (showDone) {
    if (animating) {
      return <Check className={styles.check} />;
    } else {
      return <></>;
    }
  }

  if (showMessageContent) {
    return <>{messageContent}</>;
  }

  return <></>;
}
