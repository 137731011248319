import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { type ReactElement } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { AlertDialog, Icon } from '@/components';
import { usePremium } from '@/features/account';

import { usePaddleCallbackHandler } from '../../hooks/callback-handler';
import { useCreateTransaction } from '../../hooks/create-transaction';
import { useOpenCheckout } from '../../hooks/open-checkout';
import { PaymentForm } from '../Checkout/PaymentForm/PaymentForm';
import { ProcessDialog } from '../Checkout/ProcessDialog/ProcessDialog';
import styles from './AltCheckout.module.scss';

export function AltCheckout(): ReactElement {
  const { data: isPremium } = usePremium();
  const [searchParams] = useSearchParams();
  const { pricing, checkoutCompleted, interval } = usePaddleCallbackHandler();
  useCreateTransaction({ path: '/choose-plan/checkout' });
  const { loading } = useOpenCheckout();
  const checkoutProcessCompleted =
    checkoutCompleted !== undefined && isPremium === true;

  const transaction = searchParams.get('transaction');
  const plan = searchParams.get('plan');

  if (transaction === null && plan === null) {
    <Navigate to="/choose-plan" />;
  }

  return (
    <div className={styles.checkout}>
      <div className={styles['checkout-payment']}>
        <h1>Activate your personalised AI</h1>
        <h2>
          {pricing.total === null ? (
            <span className={styles['checkout-payment__loading']}></span>
          ) : (
            <>
              {pricing.total} / {interval}
            </>
          )}
        </h2>

        <div className={styles['checkout-form']}>
          {loading ? (
            <div className={styles['checkout-form__loading']}>
              <Icon icon={faSpinnerThird} spin size="2x" />
            </div>
          ) : null}
          <PaymentForm />
        </div>
      </div>
      <AlertDialog open={checkoutCompleted !== undefined}>
        <ProcessDialog pricing={pricing} complete={checkoutProcessCompleted} />
      </AlertDialog>
    </div>
  );
}
