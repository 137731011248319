import type { ReactNode } from 'react';

import { PlanBadge, ProfilePicture, usePremium } from '@/features/account';
import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';

import styles from './AccountPopoverContent.module.scss';
import { Actions } from './Actions/Actions';

export function AccountPopoverContent(): ReactNode {
  const { user } = useAppSelector(selectAuth);
  const { data: isPremium } = usePremium();

  return (
    <div className={styles['account-popover-content']}>
      <div className={styles.account}>
        <div className={styles.account__avatar}>
          <ProfilePicture />
        </div>

        <div>
          <div className={styles.account__name}>
            {user?.displayName ?? 'You'}
          </div>

          {isPremium !== null && (
            <PlanBadge plan={isPremium ? 'pro' : 'free'} />
          )}
        </div>
      </div>

      <Actions />
    </div>
  );
}
