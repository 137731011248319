import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { type ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './Announcement.module.scss';

export function Announcement({
  children,
  onDismiss,
}: {
  children: string;
  onDismiss: () => void;
}): ReactElement {
  return (
    <div className={styles.announcement}>
      <p>{children}</p>

      <button
        aria-label="Close"
        className={styles['announcement__close-button']}
        onClick={onDismiss}
      >
        <Icon icon={faXmark} size="lg" />
      </button>
    </div>
  );
}
