import { useLocation } from 'react-router-dom';

const usePathType = (): string => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  return path;
};

export default usePathType;
