import { type ReactElement, Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { selectAuth } from '@/features/auth';
import { useAppSelector, useFirebaseIdToken } from '@/hooks';

export function PaymentLayout(): ReactElement {
  const auth = useAppSelector(selectAuth);
  const location = useLocation();
  const firebaseToken = useFirebaseIdToken();

  const loggedIn = auth.user !== null;

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (firebaseToken?.userToken === null) {
    return <></>;
  }

  return (
    <div>
      <div>
        <main>
          <Suspense>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  );
}
