import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { useRef, useState } from 'react';
import { z } from 'zod';

import { usePremium } from '@/features/account';
import {
  getGoogleAnalyticsClientId,
  logEvent,
  useEventStatus,
  useLogBigQueryEvent,
} from '@/features/analytics';
import { selectAuth } from '@/features/auth';
import type { ConversationModel } from '@/features/conversations';
import { useAppSelector } from '@/hooks';
import { auth as firebaseAuth } from '@/providers/firebase';

import { ResponseError } from '../types/errors';
import { type ApiMessageItemType } from '../types/message';
import { models } from '../types/models';

const eventStreamEndMarker = '[DONE]';

type StreamSupportedModel = Extract<
  ConversationModel,
  | 'gpt-3.5'
  | 'gpt-4'
  | 'gpt-4o'
  | 'webSearch'
  | 'chatbotapp'
  | 'nova'
  | 'aiapp'
  | 'gemini'
  | 'superbot'
  | 'claude'
  | 'document'
>;

export function useSendMessageToAssistant(): [
  ({
    messages,
    model,
    onUpdate,
    onAbort,
    historyId,
    onHistoryIdUpdate,
  }: {
    messages: ApiMessageItemType[];
    model: StreamSupportedModel;
    onUpdate: (chunk: string) => void;
    onAbort?: () => void;
    historyId?: string;
    onHistoryIdUpdate?: (historyId: string) => void;
  }) => Promise<void>,
  {
    isLoading: boolean;
    isAnswering: boolean;
    isError: boolean;
    abortMessageRequest: () => void;
  },
] {
  const cancelTokenRef = useRef<AbortController | null>(null);
  const auth = useAppSelector(selectAuth);
  const firebaseUser = firebaseAuth.currentUser;

  const { logBigQueryEvent } = useLogBigQueryEvent();

  const [firstSuccessfulResponseReceived, setFirstSuccessfulResponseReceived] =
    useEventStatus('firstSuccessfulResponseReceived');

  const { data: isPremium } = usePremium();

  const [isLoading, setLoading] = useState(false);
  const [isAnswering, setAnswering] = useState(false);

  const generateMessage = async ({
    historyId,
    messages,
    model,
    onHistoryIdUpdate,
    onUpdate,
    onAbort,
  }: {
    historyId?: string;
    messages: ApiMessageItemType[];
    model: StreamSupportedModel;
    onHistoryIdUpdate?: (historyId: string) => void;
    onUpdate: (chunk: string) => void;
    onAbort?: () => void;
  }): Promise<void> => {
    if (auth.user === null || firebaseUser === null) {
      return;
    }

    setLoading(true);
    cancelTokenRef.current = new AbortController();

    const idToken = await firebaseUser.getIdToken();

    const isDocumentSupport =
      model === 'gemini' ||
      model === 'gpt-4o' ||
      model === 'superbot' ||
      model === 'document';

    // Filter out empty messages and remove assistant messages that are connected
    let body: BodyInit = JSON.stringify({
      messages: messages.reduce((acc: ApiMessageItemType[], message) => {
        const { content, role } = message;
        if (content.trim().length > 0) {
          acc.push(message);
        } else if (role === 'assistant') {
          const prevMessage = messages[messages.indexOf(message) - 1];
          if (prevMessage !== undefined && prevMessage.role === 'user') {
            acc.pop();
          }
        }
        return acc;
      }, []),
    });
    const parsedBody = JSON.parse(body) as {
      messages: ApiMessageItemType[];
    };

    if (isDocumentSupport) {
      const transformedMessages = parsedBody.messages.map(
        ({ content, role, documents }) => {
          if (documents === undefined || documents.length === 0) {
            return { content, role };
          }

          const documentItems = documents.map((document) => ({
            fileUrl: `/${document.url}`,
            mimeType: document.mimeType,
            type:
              model === 'document'
                ? 'pdf'
                : document.mimeType?.split('/').shift(),
          }));

          return {
            content: [
              {
                type: 'text',
                text: content,
              },
              ...documentItems,
            ],
            role,
          };
        },
      );

      body = JSON.stringify({
        messages: transformedMessages,
      });
    }

    // TODO: Refactor this into its own function including `models` variable.
    await fetchEventSource(`${import.meta.env.VITE_API_BASE_URL}/api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        X_User_Id: auth.user.uid,
        X_Token: idToken,
        X_Platform: 'web',
        X_Stream: 'true',
        X_Pr: isPremium === true ? 'true' : 'false',
        X_Model: String(models[model].modelId),
        X_Version: '2',
        ...(historyId !== undefined &&
          messages.length > 1 && { X_History_Id: historyId }),
        ...(model === 'document' && { X_CROSS_PDF_MODEL: 'true' }),
      },

      body,
      openWhenHidden: true,
      signal: cancelTokenRef.current.signal,
      // Needs to return a promise.
      // eslint-disable-next-line @typescript-eslint/require-await
      async onopen(response) {
        const historyIdHeader = response.headers.get('x_history_id');

        if (historyIdHeader !== null && historyId !== historyIdHeader) {
          onHistoryIdUpdate?.(historyIdHeader);
        }

        if (response.ok) {
          logBigQueryEvent('api_responded', {
            source: 'success',
            modelTypeDisplayed: model,
          });

          logEvent('api_responded', {
            source: 'success',
            modelTypeDisplayed: model,
          });
          if (response.headers.get('content-type') === EventStreamContentType) {
            setAnswering(true);

            if (firstSuccessfulResponseReceived !== true) {
              logBigQueryEvent('web_first_successful_response_received');
              logEvent('web_first_successful_response_received');

              void setFirstSuccessfulResponseReceived(true);
            }
          }
          return;
        }

        const data: unknown = await response.json();

        const ErrorSchema = z.object({
          errorCode: z.number(),
        });

        const parsedError = ErrorSchema.safeParse(data);

        const errorCode: number = parsedError.success
          ? parsedError.data.errorCode
          : response.status;

        logBigQueryEvent('api_responded', {
          source: 'fail',
          errorCode,
          modelTypeDisplayed: model,
        });

        logEvent('api_responded', {
          source: 'fail',
          error_code: errorCode,
          modelTypeDisplayed: model,
          userId: getGoogleAnalyticsClientId(),
        });

        throw new ResponseError({
          status: response.status,
          code: errorCode,
          message: `FatalError: ${response.status}`,
          retriable:
            response.status === 429 ||
            response.status >= 500 ||
            errorCode === 5002,
        });
      },
      onmessage(message) {
        if (message.data === eventStreamEndMarker) {
          setLoading(false);
          setAnswering(false);
          return;
        }

        // if the server emits an error message, throw an exception
        // so it gets handled by the onerror callback below:
        if (message.event === 'FatalError') {
          throw new ResponseError({
            status: 500, // We can't get the exact status code from the server.
            code: 500,
            message: message.data,
          });
        }

        // Parse the data from the update.
        let payload: unknown;

        try {
          payload = JSON.parse(message.data);
        } catch (e) {
          payload = undefined;
        }

        const PayloadSchema = z.object({
          choices: z.array(
            z.object({
              delta: z.object({
                content: z.string(),
              }),
            }),
          ),
        });

        const parsedPayload = PayloadSchema.safeParse(payload);

        if (parsedPayload.success) {
          const chunk = parsedPayload.data.choices[0].delta.content;
          onUpdate(chunk);
        }
      },
      onerror(err) {
        setLoading(false);
        throw err;
      },
    });

    // Request is aborted
    if (cancelTokenRef.current.signal.aborted) {
      cancelTokenRef.current = null;
      setLoading(false);
      setAnswering(false);
      onAbort?.();
    }
  };

  const abortMessageRequest = (): void => {
    if (cancelTokenRef.current !== null) {
      cancelTokenRef.current.abort();
    }
  };

  return [
    generateMessage,
    {
      isLoading,
      isError: false,
      isAnswering,
      abortMessageRequest,
    },
  ];
}
