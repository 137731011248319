import {
  type User as FirebaseUser,
  GoogleAuthProvider,
  OAuthProvider,
  linkWithPopup,
  reauthenticateWithPopup,
  unlink,
} from 'firebase/auth';

import { auth as firebaseAuth } from '@/providers/firebase';

import type { ProviderId } from '../types/user';

export const linkGoogleAccount = async (): Promise<FirebaseUser | null> => {
  if (firebaseAuth.currentUser === null) {
    return null;
  }

  const provider = new GoogleAuthProvider();

  const result = await linkWithPopup(firebaseAuth.currentUser, provider);
  const user = result.user;

  return user;
};

export const linkAppleAccount = async (): Promise<FirebaseUser | null> => {
  if (firebaseAuth.currentUser === null) {
    return null;
  }

  const provider = new OAuthProvider('apple.com');

  const result = await reauthenticateWithPopup(
    firebaseAuth.currentUser,
    provider,
  );
  const user = result.user;

  return user;
};

export const unlinkAccount = async (
  providerId: ProviderId,
): Promise<FirebaseUser | null> => {
  if (firebaseAuth.currentUser === null) {
    return null;
  }

  const user = await unlink(firebaseAuth.currentUser, providerId);

  return user;
};
