import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import usePathType from '@/hooks/path-type';
import { appConfig } from '@/providers/config';

import { LandingWrapper } from '../../../account/components/LandingWrapper/LandingWrapper';
import { LandingCheckout } from '../../containers/LandingCheckout/LandingCheckout';
import { LandingCheckoutWhite } from '../../containers/LandingCheckoutWhite/LandingCheckoutWhite';

export function LandingCheckoutRoute(): ReactElement {
  const path = usePathType();

  let renderComponent;
  if (
    path === 'landing' ||
    path === 'landing-gpt4o' ||
    path === 'landing-3' ||
    path === 'landing-image' ||
    path === 'landing-pdf' ||
    path === 'landing-aiwriting'
  ) {
    renderComponent = <LandingCheckout />;
  } else {
    renderComponent = (
      <LandingWrapper
        VariantA={LandingCheckout}
        VariantB={LandingCheckoutWhite}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Checkout - {appConfig.name}</title>
      </Helmet>
      {renderComponent}
    </>
  );
}
