import { ref as databaseRef, set as databaseSet } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';
import { database } from '@/providers/firebase';

type PopupData = Record<string, boolean>;

export function usePopupStatus(
  model: string,
): [boolean | null, (newValue: boolean) => Promise<void>] {
  const auth = useAppSelector(selectAuth);
  const popupRef = databaseRef(database, `${auth.user?.uid}/popup`);

  const [popupData, loading] = useObjectVal<PopupData>(popupRef);

  const setPopupStatus = async (newValue: boolean): Promise<void> => {
    if (auth.user === null) return;

    const updatedData = { ...popupData, [model]: newValue };
    await databaseSet(popupRef, updatedData);
  };

  let transformedValue: boolean | null;

  if (loading) {
    transformedValue = null;
  } else if (popupData === null || popupData?.[model] === undefined) {
    transformedValue = true;
  } else if (typeof popupData[model] === 'boolean') {
    transformedValue = popupData[model];
  } else {
    transformedValue = false;
  }

  return [transformedValue, setPopupStatus];
}
