import { eventApi } from '@/providers/api';

import type { EventName } from '../types/events';

interface EventParams {
  eventValue: Record<string, unknown>;
  deploymentId: string;
  userId: string | null;
  source: string;
  authId: string;
  modelTypeDisplayed: string;
  errorCode: number;
  experimentId: string | undefined;
  variationId: string | undefined;
}

type EventData = {
  eventName: EventName;
} & Partial<EventParams>;

export const eventsApi = eventApi.injectEndpoints({
  endpoints: (builder) => ({
    logBigQueryEvent: builder.mutation<unknown, EventData>({
      query: (event) => ({
        url: `/api/web/event`,
        method: 'POST',
        body: event,
      }),
    }),
  }),
});

export const { useLogBigQueryEventMutation } = eventsApi;
