import { type ComponentProps, type ReactElement } from 'react';
import { z } from 'zod';

import { selectAuth } from '@/features/auth';
import {
  type ConversationModel,
  useConversation,
} from '@/features/conversations';
import { useAppParams, useAppSelector } from '@/hooks';

import { useSelectedModel } from '../../../../contexts/selected-model';
import { useTypewriterAnimation } from '../../../../contexts/typewriter-animation';
import { type MessageItemType } from '../../../../types/message';
import { contents } from '../../ModelSelect/contents';
import { MessageAvatar } from './MessageAvatar/MessageAvatar';
import { CopyButton } from './MessageContent/CopyButton/CopyButton';
import { MessageContent } from './MessageContent/MessageContent';
import styles from './MessageItem.module.scss';

export function MessageItem({
  id,
  role,
  content,
  error,
  images,
  documents,
  onClickRetry,
  onClickOption,
}: Pick<
  MessageItemType,
  'id' | 'role' | 'content' | 'error' | 'images' | 'documents'
> & {
  onClickRetry: ComponentProps<typeof MessageContent>['onClickRetry'];
  onClickOption: (option: string) => void;
}): ReactElement {
  const auth = useAppSelector(selectAuth);
  const params = useAppParams(
    z.object({
      id: z.string(),
    }),
  );
  const { animatingMessageId } = useTypewriterAnimation();
  const { data: conversation } = useConversation(params?.id);
  const { selectedModel } = useSelectedModel();

  const conversationModel: ConversationModel =
    (conversation?.engine as ConversationModel | undefined) ?? selectedModel;

  const assistantName = contents[conversationModel].title;
  const userDisplayName = auth.user?.displayName ?? 'You';
  const name = role === 'assistant' ? assistantName : userDisplayName;

  const animating = id === animatingMessageId && typeof content === 'string';
  const loading = content === null;
  const modelLoading = conversation === undefined && params?.id !== undefined;

  return (
    <div className={styles['message-item']}>
      <MessageAvatar
        conversationModel={conversationModel}
        role={role}
        loading={modelLoading}
      />

      <div className={styles['message-item__content']}>
        <div className={styles['message-item__title-container']}>
          <span className={styles['message-item__title']}>
            {modelLoading ? <span>&nbsp;</span> : name}
          </span>

          {role === 'assistant' &&
            error === undefined &&
            !loading &&
            !animating &&
            content != null && (
              <div className={styles['message-item__actions']}>
                <CopyButton
                  onClick={() => {
                    if (typeof content === 'string') {
                      (async () => {
                        await navigator.clipboard.writeText(content);
                        // eslint-disable-next-line no-console
                      })().catch(console.error);
                    }
                  }}
                />
              </div>
            )}
        </div>

        <MessageContent
          id={id}
          role={role}
          loading={loading}
          content={content}
          error={error}
          images={images}
          documents={documents}
          onClickRetry={onClickRetry}
          onClickOption={onClickOption}
        />
      </div>
    </div>
  );
}
