import type { ReactElement, ReactNode } from 'react';

import styles from './AccountSection.module.scss';

export function AccountSection({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return <section className={styles['account-section']}>{children}</section>;
}

export * from './AccountSectionHeader/AccountSectionHeader';
export * from './AccountSectionContent/AccountSectionContent';
export * from './AccountSectionSpacer/AccountSectionSpacer';
export * from './AccountSectionFooter/AccountSectionFooter';
export * from './AccountSectionFooterNote/AccountSectionFooterNote';
export * from './AccountSectionFooterActions/AccountSectionFooterActions';
