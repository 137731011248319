import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import styles from './NavLink.module.scss';

export function NavLink({
  name,
  href,
}: {
  name: string;
  href: string;
}): ReactElement {
  return (
    <Link to={href} target="_blank" className={styles['nav-link']}>
      {name}
    </Link>
  );
}
