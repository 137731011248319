import { faBars, faPlus } from '@fortawesome/pro-regular-svg-icons';
import type { ReactElement } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Button, Dialog, DialogTrigger, Icon, Logo } from '@/components';
import { PaywallDialog, usePremium } from '@/features/account';
import { logEvent, useLogBigQueryEvent } from '@/features/analytics';

import { type DrawerMode } from '../../hooks/drawer-mode';
import { AccountPopover } from './AccountPopover/AccountPopover';
import styles from './Header.module.scss';
import { HeaderButton } from './HeaderButton/HeaderButton';

export function Header({ mode }: { mode: DrawerMode }): ReactElement {
  const { logBigQueryEvent } = useLogBigQueryEvent();

  const { data: isPremium } = usePremium();

  const handleClickNewChat = (): void => {
    // Run at the end of the current event loop iteration.
    setTimeout(() => {
      const messageFieldInput = document.querySelector('#message-field-input');

      if (messageFieldInput !== null) {
        // Focus chat input.
        (messageFieldInput as HTMLInputElement).focus();
      }
    }, 100); // Number of milliseconds to wait to render the text field.
  };

  return (
    <header className={styles.header}>
      {mode === 'dialog' && (
        <DialogTrigger asChild>
          <HeaderButton variant="transparent" className={styles.header__button}>
            <Icon icon={faBars} />
          </HeaderButton>
        </DialogTrigger>
      )}

      {mode === 'static' && (
        <NavLink to="/?new-chat=true">
          <Logo size="x-small" />
        </NavLink>
      )}

      <div className={styles['header-actions']}>
        {mode === 'dialog' && (
          <HeaderButton variant="transparent" asChild>
            <Link to="/?new-chat=true" onClick={handleClickNewChat}>
              <Icon icon={faPlus} />
            </Link>
          </HeaderButton>
        )}

        {mode === 'static' && isPremium === false && (
          <Dialog
            onOpenChange={(open) => {
              if (open) {
                logBigQueryEvent('lnd_paywall');
                logEvent('lnd_paywall', {
                  paywallType: 'in_app',
                });
              }
            }}
          >
            <DialogTrigger asChild>
              <Button
                size="small"
                variant="warning"
                className={styles['header-actions__go-pro-button']}
              >
                Go Pro
              </Button>
            </DialogTrigger>

            <PaywallDialog />
          </Dialog>
        )}

        <AccountPopover />
      </div>
    </header>
  );
}

export { HeaderButton };
