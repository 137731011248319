import cn from 'clsx';
import type { ReactElement, ReactNode } from 'react';

import styles from './AccountSectionFooter.module.scss';

export function AccountSectionFooter({
  variant,
  children,
}: {
  variant: 'normal' | 'destructive';
  children: ReactNode;
}): ReactElement {
  return (
    <div
      className={cn(
        styles['account-section-footer'],
        styles[`account-section-footer--variant-${variant}`],
      )}
    >
      {children}
    </div>
  );
}
