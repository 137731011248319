import { type AuthErrorCodes } from 'firebase/auth';

interface ErrorMessageType {
  target?: string;
  message: string;
}

const errorMessages: Partial<
  Record<(typeof AuthErrorCodes)[keyof typeof AuthErrorCodes], ErrorMessageType>
> = {
  'auth/invalid-email': {
    target: 'email',
    message: 'Email is invalid.',
  },
  'auth/user-not-found': {
    target: 'email',
    message: 'This account does not exist.',
  },
  'auth/wrong-password': {
    target: 'password',
    message: 'Password is incorrect.',
  },
  'auth/already-initialized': {
    target: 'email',
    message: 'This account has already been initialized.',
  },
  'auth/email-already-in-use': {
    target: 'email',
    message: 'This email is already in use.',
  },
  'auth/weak-password': {
    target: 'password',
    message: 'The password must be 6 characters long or more.',
  },
  'auth/internal-error': { message: 'Internal error.' },
  'auth/invalid-credential': {
    message: 'Your password is incorrect or this account does not exist.',
  },
  'auth/too-many-requests': {
    message: 'Number of requests exceeds the maximum allowed.',
  },
  'auth/network-request-failed': {
    message:
      'Network request failed. Please check your internet connection and try again.',
  },
};

export function createFormError(errorCode: string): ErrorMessageType | null {
  const error = errorMessages[errorCode as keyof typeof errorMessages];

  return error ?? null;
}
