export function waitForCookie(
  cookieChecker: () => string | null,
  {
    timeout,
    onFound,
    onTimeout,
  }: {
    timeout?: number;
    onFound: (cookieValue: string) => void;
    onTimeout?: () => void;
  },
): NodeJS.Timeout | null {
  const intervalDuration = 100;
  const timeoutDuration = timeout ?? 1000;

  let elapsedTime = 0;

  let timeoutId: NodeJS.Timeout | null = null;

  const checkCookie = (): void => {
    const cookieValue = cookieChecker();

    if (cookieValue !== null) {
      onFound(cookieValue);
    } else if (elapsedTime >= timeoutDuration) {
      onTimeout?.();
    } else {
      elapsedTime += intervalDuration;
      timeoutId = setTimeout(checkCookie, intervalDuration);
    }
  };

  checkCookie();

  return timeoutId;
}
