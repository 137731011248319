import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { PopoverClose } from '@radix-ui/react-popover';
import { type ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@/components';
import { usePremium } from '@/features/account';
import { logOut, selectAuth } from '@/features/auth';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { appConfig } from '@/providers/config';
import { auth as firebaseAuth } from '@/providers/firebase';

import { ActionButton } from './ActionButton/ActionButton';
import styles from './Actions.module.scss';
import { UpgradeToPro } from './UpgradeToPro/UpgradeToPro';

export function Actions(): ReactElement {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const { data: isPremium } = usePremium();

  return (
    <div className={styles.actions}>
      <ActionButton asChild>
        <PopoverClose asChild>
          <NavLink to="/account">My Account</NavLink>
        </PopoverClose>
      </ActionButton>

      {isPremium === false && <UpgradeToPro />}

      <ActionButton asChild>
        <NavLink
          target="_blank"
          to={(() => {
            let mailtoString = `mailto:${
              appConfig.supportMail
            }?subject=${encodeURIComponent(appConfig.name)}`;

            if (auth.user?.uid !== undefined) {
              mailtoString = `${mailtoString}&body=%0D%0A%0D%0A---%0D%0A%0D%0AUser%20ID%3A%20${auth.user.uid}`;
            }

            return mailtoString;
          })()}
        >
          Support
          <Icon icon={faExternalLink} />
        </NavLink>
      </ActionButton>

      <ActionButton
        onClick={async () => {
          await firebaseAuth.signOut();
          dispatch(logOut());
        }}
      >
        Log out
      </ActionButton>
    </div>
  );
}
