import { type ReactElement, type ReactNode } from 'react';

import styles from './DrawerStatic.module.scss';

export function DrawerStatic({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className={styles['drawer-static']}>
      <div className={styles['drawer-static__content']}>{children}</div>
    </div>
  );
}
