import { type ErrorDetail, errorList } from './error-list';

export function getErrorDetail(code: number | string): ErrorDetail | null {
  let errorDetail: ErrorDetail | null = null;
  const err = errorList.find((err) => {
    if (typeof err.code === 'object') {
      return err.code.includes(code);
    }
    return code === err.code;
  });

  if (err !== undefined) {
    let messageReplacement = err.message.replace('#code#', code.toString());
    if (err.codeInfoShown !== undefined && err.codeInfoShown.includes(code)) {
      messageReplacement = `${messageReplacement}  Error code: ${code}`;
    }

    errorDetail = {
      ...err,
      code,
      message: messageReplacement,
    };
  }

  return errorDetail;
}

export function getFileType(fileType?: string): string {
  const defaultType = 'application';

  if (fileType === undefined) {
    return defaultType;
  }

  const [mainType, subType] = fileType.split('/');

  // helper function within getFileType
  const isErrorType = (type: string): boolean =>
    errorList.some(
      (err) => typeof err.code === 'string' && err.code.startsWith(type),
    );

  if (subType.length > 0 && isErrorType(subType)) {
    return subType;
  }

  if (mainType.length > 0 && isErrorType(mainType)) {
    return mainType;
  }

  return defaultType;
}
