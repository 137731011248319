import { type ComponentProps, type ReactElement, type ReactNode } from 'react';

import { CopyButton } from '../CopyButton/CopyButton';
import styles from './CodeBlock.module.scss';

export function CodeBlock({
  language,
  children,
}: {
  language: string;
  children: ReactNode;
}): ReactElement {
  const handleCopy: ComponentProps<typeof CopyButton>['onClick'] = (event) => {
    const parentEl = event.currentTarget.parentElement;

    if (parentEl === null) {
      return;
    }

    const nextElementSibling = parentEl.nextElementSibling;

    if (nextElementSibling === null) {
      return;
    }

    (async () => {
      if (nextElementSibling.textContent !== null) {
        await navigator.clipboard.writeText(nextElementSibling.textContent);
      }
      // eslint-disable-next-line no-console
    })().catch(console.error);
  };

  return (
    <div className={styles['code-block']}>
      <div className={styles['code-block__header']}>
        <span className={styles['code-block__title']}>{language}</span>

        <CopyButton
          onClick={handleCopy}
          className={styles['code-block__copy-button']}
        />
      </div>

      <pre>
        <code>{children}</code>
      </pre>
    </div>
  );
}
