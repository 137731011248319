import cn from 'clsx';
import type { ReactElement } from 'react';

import { type MessageDocumentType } from '../../types/message';
import { DocumentItem } from './DocumentItem/DocumentItem';
import styles from './DocumentList.module.scss';

export function DocumentList({
  documents,
  className,
}: {
  documents: MessageDocumentType[];
  className?: string;
}): ReactElement {
  return (
    <div className={cn(styles['document-list'], className)}>
      {documents.map(({ name, type, size, mimeType, url }, index) => (
        <DocumentItem
          key={index}
          name={name}
          type={type}
          size={size}
          mimeType={mimeType}
          url={url}
        />
      ))}
    </div>
  );
}
