import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import type { ReactElement } from 'react';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActions,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Form,
} from '@/components';

import { ConversationActionButton } from '../ConversationActionButton/ConversationActionButton';

export function ClearConversationButton({
  title,
  onDelete,
}: {
  title: string;
  onDelete: () => void;
}): ReactElement {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <ConversationActionButton icon={faTrash} />
      </AlertDialogTrigger>

      <AlertDialogContent>
        <Form onSubmit={onDelete}>
          <AlertDialogTitle>Delete this conversation?</AlertDialogTitle>

          <AlertDialogDescription>
            This will delete
            <span> &quot;{title}&quot;</span>.
          </AlertDialogDescription>

          <AlertDialogActions>
            <AlertDialogCancel asChild>
              <Button size="large" type="button" variant="secondary">
                Cancel
              </Button>
            </AlertDialogCancel>

            <AlertDialogAction asChild>
              <Button size="large" type="submit" variant="destructive">
                Yes, delete this conversation
              </Button>
            </AlertDialogAction>
          </AlertDialogActions>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
