import cn from 'clsx';
import { type ReactElement, type ReactNode } from 'react';

import styles from './Badge.module.scss';

export function Badge({
  type,
  children,
}: {
  type: 'bestseller' | 'save';
  children: ReactNode;
}): ReactElement {
  return (
    <span
      className={cn(styles.badge, {
        [styles['badge--bestseller']]: type === 'bestseller',
        [styles['badge--save']]: type === 'save',
      })}
    >
      {children}
    </span>
  );
}
