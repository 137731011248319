type Code = number | string;
export interface ErrorDetail {
  code: Code | Code[];
  message: string;
  action?: ErrorActionType;
  options?: string[];
  codeInfoShown?: Code[];
}

export type ErrorActionType =
  | 'retry'
  | 'payment-required'
  | 'new-chat'
  | 'show-options';

export const errorList: ErrorDetail[] = [
  {
    code: [
      1001, 1004, 1006, 1026, 1020, 1501, 1504, 1506, 1508, 1510, 1512, 1514,
      1516, 1518, 1520, 1522, 1524, 1526, 1528, 1530, 1532,
    ],
    message:
      "Slow down, cowboy! You've sent a lot of messages in the last 24 hours. We're currently experiencing high traffic, and all our servers are busy. Please try again soon.",
    codeInfoShown: [1020],
  },
  {
    code: [
      1000, 1003, 1005, 1015, 1013, 1017, 1019, 1025, 1027, 1029, 1501, 1503,
      1505, 1507, 1509, 1511, 1513, 1515, 1517, 1519, 1521, 1523, 1525, 1527,
      1529, 1531,
    ],
    action: 'payment-required',
    message:
      'I would love to answer this, but it seems that you are not subscribed to the pro version, and have no more free chats left. Please upgrade to enjoy unlimited access.',
    codeInfoShown: [
      1013, 1017, 1019, 1025, 1027, 1029, 1501, 1503, 1505, 1507, 1509, 1511,
      1513, 1515, 1517, 1519, 1521, 1523, 1525, 1527, 1529, 1531,
    ],
  },
  {
    code: 1007,
    action: 'payment-required',
    message:
      'I would love to create an image for you, but it seems that you are not subscribed to the pro version, and have no more free image generations left. Please upgrade to enjoy unlimited access.',
  },
  {
    code: 1008,
    message:
      'You have reached your daily image generation limit. Please wait for your limit to reset in 24 hours.',
  },
  {
    code: 1009,
    action: 'payment-required',
    message:
      'I would love to analyze this image for you, but it seems that you are not subscribed to the pro version, and have no more free visions left. Please upgrade to enjoy unlimited access.',
  },
  {
    code: 1010,
    message:
      'You have reached your daily limit for image analysis. Please wait for your limit to reset in 24 hours.',
  },
  {
    code: 1011,
    action: 'payment-required',
    message:
      'I would love to analyze this document for you, but it seems that you are not subscribed to the pro version, and have no more document analysis left. Please upgrade to enjoy unlimited access.',
  },
  {
    code: 1012,
    message:
      'You have reached your document analysis limit. Please wait for your limit to reset in  24 hours. Error code: 1012',
  },
  {
    code: [1014, 1016, 1018, 1028, 1030],
    message:
      'We are currently facing high traffic, and all our servers are busy. Please try accessing our services again soon.',
    codeInfoShown: [1014, 1018, 1028, 1030],
  },
  {
    code: [2001, 2002, 2003, 2004, 2005, 3001, 3099, 4001],
    message:
      'Oops! We are having a technical issue at the moment. Please try again shortly and rest assured that we are actively working on it.',
    codeInfoShown: [2001, 2002, 2003, 2004, 2005, 3001, 3099, 4001],
  },
  {
    code: [1002, 2099, 4002, 5001, 5003],
    message:
      'Something went wrong. If the issue persists, please reach out to our support team with the following error code: #code#. Alternatively, try sending the message again.',
  },
  {
    code: [5002, 7001],
    action: 'retry',
    message:
      'Something went wrong. If the issue persists, please reach out to our support team with the following error code: #code#. Alternatively, try sending the message again.',
  },
  {
    code: [5004, 6004],
    action: 'new-chat',
    message:
      'You have reached the maximum message limit for this chat. You can start a new chat with a fresh history.',
  },
  {
    code: 11001,
    message:
      "Sorry, the uploaded document isn't suitable for analysis. Please consider trying a different one.",
    codeInfoShown: [11001],
  },
  {
    code: 'application-empty-message',
    action: 'show-options',
    message:
      'How can I assist you with this file? Please feel free to ask me any questions you have about the file, or take inspiration from the suggestions below.',
    options: [
      'Provide an overview of the content in this file',
      'Summarize key points',
      'Generate ways to improve',
    ],
  },
  {
    code: 'image-empty-message',
    action: 'show-options',
    message:
      'How can assist you with this image? Please feel free to ask me any questions you have about the file, or take inspiration from the suggestions below.',
    options: [
      'What can you tell me about this image?',
      'Describe the scene in this photo.',
      'Help me understand the context of this image.',
    ],
  },
  {
    code: 'audio-empty-message',
    action: 'show-options',
    message:
      'How can I assist you with this audio? Feel free to ask any questions about the file, or take inspiration from the suggestions below.',
    options: [
      'What do you hear in this audio recording?',
      'What do you know about the source of this sound?',
      'Explain the context of this audio recording.',
    ],
  },
  {
    code: 'video-empty-message',
    action: 'show-options',
    message:
      'How can I assist you with this video? Feel free to ask any questions about the file, or take inspiration from the suggestions below.',
    options: [
      'What do you see in this video?',
      'What is the subject of this video?',
      'Describe the sequence of scenes in this video.',
    ],
  },
  {
    code: 'multiple-empty-message',
    action: 'show-options',
    message:
      'How can I assist you with these files? Feel free to ask any questions about the files or consider the suggestions below.',
    options: [
      'Describe the contents of each file.',
      'Explain the context or purpose of these files.',
      'Highlight any notable details.',
    ],
  },
];
