import type { ReactElement } from 'react';

import { Table } from '@/components';
import { Loader, useGetTransactionListQuery } from '@/features/payment';

import { TransactionStatusTag } from '../../../components/TransactionStatusTag/TransactionStatusTag';
import styles from './BillingHistory.module.scss';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export function BillingHistory(): ReactElement {
  const { data: transactions } = useGetTransactionListQuery({
    statusList: ['completed', 'paid', 'canceled', 'past_due', 'billed'],
  });

  if (transactions === undefined) {
    return <Loader />;
  }

  return (
    <Table>
      <>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th className={styles['table-actions']}>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => {
            const currencyFormatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: transaction.currencyCode ?? 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const formattedTotal = currencyFormatter.format(
              parseFloat(transaction.details.totals.total) / 100,
            );
            return (
              <tr key={index}>
                <td>
                  {dateFormatter.format(new Date(transaction.billedAt ?? ''))}
                </td>
                <td>{formattedTotal}</td>
                <td className={styles['table-actions']}>
                  <TransactionStatusTag status={transaction.status} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    </Table>
  );
}
