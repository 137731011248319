import {
  EmailAuthProvider,
  reauthenticateWithCredential as firebaseReauthenticateWithCredential,
} from 'firebase/auth';
import { useState } from 'react';

import { auth as firebaseAuth } from '@/providers/firebase';

export function useReauthenticateWithCredential(): {
  reauthenticateWithCredential: (currentPassword: string) => Promise<boolean>;
  loading: boolean;
  error: Error | null;
} {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const reauthenticateWithCredential = async (
    currentPassword: string,
  ): Promise<boolean> => {
    if (
      // Disabled because otherwise TypeScript (v5.2.2) doesn't know that
      // firebaseAuth.currentUser is not null.
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      firebaseAuth.currentUser === null ||
      firebaseAuth.currentUser?.email === null
    ) {
      return false;
    }

    setError(null);
    setLoading(true);

    const credential = EmailAuthProvider.credential(
      firebaseAuth.currentUser.email,
      currentPassword,
    );

    try {
      await firebaseReauthenticateWithCredential(
        firebaseAuth.currentUser,
        credential,
      );

      return true;
    } catch (error) {
      setError(error as Error);

      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    reauthenticateWithCredential,
    loading,
    error,
  };
}
