import cn from 'clsx';
import type { ReactElement } from 'react';

import UserAvatarIcon from '@/assets/user-avatar.svg?react';
import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';

import styles from './ProfilePicture.module.scss';

export function ProfilePicture({
  className,
}: {
  className?: string;
}): ReactElement {
  const auth = useAppSelector(selectAuth);

  if (typeof auth.user?.photoUrl === 'string') {
    return (
      <img
        src={auth.user.photoUrl}
        alt="User avatar"
        className={cn(styles['profile-picture'], className)}
        referrerPolicy="no-referrer"
      />
    );
  }

  return (
    <UserAvatarIcon className={cn(styles['profile-picture'], className)} />
  );
}
