import type { ReactElement } from 'react';

import styles from './NewBadge.module.scss';

export function NewBadge(): ReactElement {
  return (
    <div className={styles.container}>
      <p className={styles.text}>New</p>
    </div>
  );
}
