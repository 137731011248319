import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { type FormEvent, type ReactElement, useRef } from 'react';
import { toast } from 'react-toastify';

import { Button, Form, Icon } from '@/components';
import { PlanBadge, usePremium } from '@/features/account';

import { maximumAllowedDocumentSize } from '../../../../types/message';
import styles from './ChooseFile.module.scss';

export function ChooseFile({
  onSelectFile,
  onRequestPaywall,
}: {
  onSelectFile: (file: File) => void;
  onRequestPaywall: () => void;
}): ReactElement {
  const formRef = useRef<HTMLFormElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { data: isPremium } = usePremium();

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    const formData = new FormData(event.currentTarget);

    const documentFile = formData.get('document') as File;

    if (documentFile.size > maximumAllowedDocumentSize) {
      toast.error('File size should be less than 20MB.');
      return;
    }

    if (documentFile.type === 'application/pdf') {
      onSelectFile(documentFile);
    } else {
      toast.error('Only PDF files are supported.');
      return;
    }

    // Reset file input value
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Form
      formRef={formRef}
      onSubmit={(event) => {
        handleSubmit(event);
      }}
      className={styles['choose-file-form']}
    >
      <div className={styles['choose-file-area']}>
        <div className={styles['choose-file-area__content']}>
          <div className={styles['choose-file-area__content-icon']}>
            <Icon icon={faCloudArrowUp} size="2x" />

            {isPremium === false && (
              <PlanBadge
                plan="pro"
                className={styles['choose-file-area__content-pro-badge']}
              />
            )}
          </div>

          <div>
            <p className={styles['choose-file-area__content-title']}>
              Drop your file here, or Browse
            </p>
            <p className={styles['choose-file-area__content-subtitle']}>
              Drag and drop your document here
            </p>
          </div>

          {isPremium === true && (
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              Choose File
            </Button>
          )}

          {isPremium === false && (
            <Button
              variant="warning"
              size="small"
              onClick={() => {
                onRequestPaywall();
              }}
            >
              Upgrade to PRO
            </Button>
          )}
        </div>

        {isPremium === true && (
          <VisuallyHidden>
            <input
              ref={fileInputRef}
              onChange={() => {
                formRef.current?.requestSubmit();
              }}
              name="document"
              type="file"
              aria-label="Upload Document"
              accept="application/pdf"
              required
            />
          </VisuallyHidden>
        )}
      </div>
    </Form>
  );
}
