import cn from 'clsx';
import type { ReactElement, ReactNode } from 'react';

import styles from './AccountPageContainer.module.scss';

export function AccountPageContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): ReactElement {
  return (
    <div className={cn(styles['account-container'], className)}>{children}</div>
  );
}
