import { type ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Logo as CompanyLogo } from '@/components';

export function Logo(): ReactElement {
  return (
    <Link to="/" aria-label="Logo">
      <CompanyLogo size="small" />
    </Link>
  );
}
