import cn from 'clsx';
import { type ReactElement } from 'react';

import styles from './MyPlanBadge.module.scss';

export function MyPlanBadge({
  plan,
}: {
  plan: 'free' | 'pro' | 'past_due';
}): ReactElement {
  const plans = {
    free: {
      text: 'LIMITED USE',
    },
    pro: {
      text: 'Active',
    },
    past_due: {
      text: 'Past Due',
    },
  };

  return (
    <span
      className={cn(
        styles['my-plan-badge'],
        styles[`my-plan-badge--plan-${plan}`],
      )}
    >
      {plans[plan].text}
    </span>
  );
}
