import cn from 'clsx';
import type { ReactElement } from 'react';

import { selectAuth } from '@/features/auth';
import { useAppSelector } from '@/hooks';

import { AccountPageContainer } from '../../components/AccountPageContainer/AccountPageContainer';
import { AccountPageHeader } from '../../components/AccountPageHeader/AccountPageHeader';
import styles from './AccountSettings.module.scss';
import { ChangeDisplayName } from './ChangeDisplayName/ChangeDisplayName';
import { ChangeEmail } from './ChangeEmail/ChangeEmail';
import { ChangePassword } from './ChangePassword/ChangePassword';
import { ChangeProfilePicture } from './ChangeProfilePicture/ChangeProfilePicture';
import { ClearAllConversations } from './ClearAllConversations/ClearAllConversations';
import { DeleteAccount } from './DeleteAccount/DeleteAccount';

export function AccountSettings({
  mode,
}: {
  mode: 'home' | 'settings';
}): ReactElement {
  const auth = useAppSelector(selectAuth);

  const isPasswordProviderAvailable =
    auth.user !== null &&
    auth.user.providers.some((provider) => provider.id === 'password');

  return (
    <AccountPageContainer
      className={cn(
        styles['account-settings'],
        styles[`account-settings--mode-${mode}`],
      )}
    >
      <AccountPageHeader>Account Settings</AccountPageHeader>

      <ChangeProfilePicture />
      <ChangeDisplayName />
      {isPasswordProviderAvailable && <ChangeEmail />}
      {isPasswordProviderAvailable && <ChangePassword />}
      <ClearAllConversations />
      <DeleteAccount />
    </AccountPageContainer>
  );
}
