import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { type MouseEventHandler, type ReactElement } from 'react';

import { Icon } from '@/components';

import styles from './TogglePasswordButton.module.scss';

export function TogglePassword({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}): ReactElement {
  return (
    <button
      type="button"
      className={styles['toggle-password-button']}
      onClick={onClick}
      data-type="password"
    >
      <Icon
        icon={faEye}
        className={styles['toggle-password-button__icon-type-password']}
      />
      <Icon
        icon={faEyeSlash}
        className={styles['toggle-password-button__icon-type-text']}
      />
    </button>
  );
}
