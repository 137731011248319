import Lottie from 'lottie-light-react';
import type { ReactElement } from 'react';

import loadingAnimation from '../../assets/animations/loading.json';

export function LoadingAnimation(): ReactElement {
  return (
    <Lottie
      animationData={loadingAnimation}
      loop
      style={{
        width: 'var(--size-24)',
      }}
    />
  );
}
