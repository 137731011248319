import { type ReactElement, type ReactFragment } from 'react';

import { getGoogleAnalyticsClientId } from '@/features/analytics';
import { selectAuth } from '@/features/auth';
import { useAppSelector, useFirebaseIdToken } from '@/hooks';

interface PaymentFormProps {
  action: 'payment';
  successUrl: string;
  canceledUrl: string;
  returnUrl?: never;
}

interface PortalFormProps {
  action: 'portal';
  returnUrl: string;
  successUrl?: never;
  canceledUrl?: never;
}

type StripeFormProps = {
  children: ReactElement | ReactFragment;
  className?: string;
} & (PaymentFormProps | PortalFormProps);

export function StripeForm({
  action,
  children,
  className,
  returnUrl,
  canceledUrl,
  successUrl,
}: StripeFormProps): ReactElement | null {
  const auth = useAppSelector(selectAuth);
  const clientId = getGoogleAnalyticsClientId();
  const { userToken } = useFirebaseIdToken();

  if (auth.user === null || userToken === null) {
    return null;
  }

  const actions: Record<typeof action, string> = {
    payment: '/api/payment/link',
    portal: '/api/payment/portal-session',
  };

  const originUrl = window.location.origin;

  return (
    <form
      action={import.meta.env.VITE_API_BASE_URL + actions[action]}
      method="POST"
      className={className}
    >
      <input type="hidden" name="user_id" value={auth.user.uid} />
      <input type="hidden" name="token" value={userToken} />
      {clientId !== null && (
        <input type="hidden" name="tracking_id" value={clientId} />
      )}

      {returnUrl !== undefined && (
        <input type="hidden" name="return_url" value={originUrl + returnUrl} />
      )}

      {successUrl !== undefined && (
        <input
          type="hidden"
          name="success_url"
          value={originUrl + successUrl}
        />
      )}

      {canceledUrl !== undefined && (
        <input
          type="hidden"
          name="canceled_url"
          value={originUrl + canceledUrl}
        />
      )}

      {children}
    </form>
  );
}
