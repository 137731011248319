import { Slot } from '@radix-ui/react-slot';
import {
  type ForwardedRef,
  type ReactElement,
  type ReactNode,
  forwardRef,
} from 'react';

import styles from './ActionButton.module.scss';

export function ActionButtonComponent(
  {
    children,
    onClick,
    asChild,
  }: {
    children: ReactNode;
    onClick?: () => Promise<void> | void;
    asChild?: boolean;
  },
  forwardedRef: ForwardedRef<HTMLButtonElement>,
): ReactElement {
  const Comp = asChild === true ? Slot : 'button';

  return (
    <Comp
      ref={forwardedRef}
      onClick={onClick}
      className={styles['action-button']}
    >
      {children}
    </Comp>
  );
}

export const ActionButton = forwardRef(ActionButtonComponent);
