import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import cn from 'clsx';
import {
  type MouseEventHandler,
  type ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Icon } from '@/components';

import styles from './CopyButton.module.scss';

export function CopyButton({
  onClick,
  className,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}): ReactElement {
  const [clicked, setClicked] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setClicked(true);

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setClicked(false);
    }, 3000);

    onClick(event);
  };

  return (
    <button
      className={cn(styles['copy-button'], className)}
      onClick={handleClick}
    >
      {!clicked ? (
        <Icon icon={faCopy} size="lg" />
      ) : (
        <Icon icon={faCheck} size="lg" />
      )}
    </button>
  );
}
