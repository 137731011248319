import { type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { appConfig } from '@/providers/config';

import { AccountHome } from '../../containers/AccountHome/AccountHome';
import { AccountSettings } from '../../containers/AccountSettings/AccountSettings';

export function AccountHomeRoute(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Account Settings - {appConfig.name}</title>
      </Helmet>

      <AccountHome />
      <AccountSettings mode="home" />
    </>
  );
}
