import { type User as FirebaseUser, onAuthStateChanged } from 'firebase/auth';
import { useCallback, useEffect } from 'react';

import {
  logOut,
  mapFirebaseUserToUser,
  selectAuth,
  setUser,
} from '@/features/auth';
import { auth as firebaseAuth } from '@/providers/firebase';

import { useAppDispatch, useAppSelector } from './store';

export const useAuth = (): {
  syncUserState: (user: FirebaseUser | null) => void;
} => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const syncUserState = useCallback(
    (user: FirebaseUser | null): void => {
      if (user !== null && user.emailVerified) {
        const mappedUser = mapFirebaseUserToUser(user);
        dispatch(setUser(mappedUser));
      } else {
        dispatch(logOut());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const handleFocus = (): void => {
      (async () => {
        const emailValidated = firebaseAuth.currentUser?.emailVerified;
        await firebaseAuth.currentUser?.reload();
        if (
          emailValidated === false &&
          firebaseAuth.currentUser?.emailVerified === true
        ) {
          await firebaseAuth.currentUser.getIdToken(true);
          syncUserState(firebaseAuth.currentUser);
        }

        if (firebaseAuth.currentUser === null || auth.user === null) {
          return;
        }

        if (firebaseAuth.currentUser.email !== auth.user.email) {
          syncUserState(firebaseAuth.currentUser);
        }
        // eslint-disable-next-line no-console
      })().catch(console.error);
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [syncUserState, auth.user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, syncUserState);

    return unsubscribe;
  }, [dispatch, syncUserState]);

  return { syncUserState };
};
