import { type ReactElement } from 'react';

import styles from './ChatExamples.module.scss';
import { ExampleItem } from './ExampleItem/ExampleItem';

export function ChatExamples({
  examples,
  onClickExample,
}: {
  examples: string[];
  onClickExample: (example: string) => void;
}): ReactElement {
  return (
    <div className={styles['chat-examples']}>
      {examples.map((example, index) => (
        <ExampleItem
          key={index}
          onClick={() => {
            onClickExample(example);
          }}
        >
          {example}
        </ExampleItem>
      ))}
    </div>
  );
}
