import type { SyntheticEvent } from 'react';

export const handlePromiseEvent = <Type>(
  promise: (event: SyntheticEvent) => Promise<Type>,
) => {
  return (event: SyntheticEvent) => {
    if (promise !== undefined) {
      promise(event).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Unexpected error', error);
      });
    }
  };
};
